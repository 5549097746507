import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";

interface VerticalDividerProps {
  className?: string;
}

const VierticalDivider: FC<VerticalDividerProps> = (props) => (
  <div className={classnames("vertical-divider", props.className)} />
);

export default VierticalDivider;
