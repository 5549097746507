export enum PERMISSION {
  CREATE_COMPANY = "Create company",
  EDIT_COMPANY = "Edit company",
  VIEW_COMPANY = "View company",
  VIEW_COMPANIES = "View companies",

  CREATE_USER = "Create user",
  EDIT_USER = "Edit user",
  VIEW_USERS = "View users",
  VIEW_USER = "View user",

  CREATE_MEMBER = "Create member",
  EDIT_MEMBER = "Edit Member",
  VIEW_MEMBER = "View member",
  VIEW_MEMBERS = "View members",
  ADD_FILE_MEMBER = "Add files to the members details",
  ADD_NOTE_MEMBER = "Add notes to the members details",

  VIEW_SYSTEM_ACTIVITY_LIBRARY = "View System Activity Library",
  VIEW_COMPANY_ACTIVITY_LIBRARY = "View Company Activity Library",
  VIEW_COMPANY_CREATED_ACTIVITY = "View Company Created Activity",
  VIEW_ACTIVITY = "View Activity",
  CREATE_EDIT_ARCHIVE_LIBRARY_ACTIVITY = "Create/Edit/Archive Library Activity",
  CREATE_EDIT_ARCHIVE_COMPANY_ACTIVITY = "Create/Edit/Archive Company Activity",
  IMPORT_LIBRARY_ACTIVITY = "Import Library Activity",

  CREATE_TEAM = "Create team",

  VIEW_SYSTEM_TASK_LIBRARY = "View System Task Library",
  VIEW_COMPANY_TASK_LIBRARY = "View Company Task Library",
  VIEW_COMPANY_CREATED_TASK = "View Company Created Task",
  VIEW_TASK = "View Task",
  CREATE_EDIT_ARCHIVE_LIBRARY_TASK = "Create/Edit/Archive Library Task",
  CREATE_EDIT_ARCHIVE_COMPANY_TASK = "Create/Edit/Archive Company Task",
  IMPORT_LIBRARY_TASK = "Import Library Task",
  ADD_FILE_TASK = "Add the file to the task",

  VIEW_SYSTEM_JSA_LIBRARY = "View System Jsa Library",
  VIEW_COMPANY_JSA_LIBRARY = "View Company Jsa Library",
  VIEW_COMPANY_CREATED_JSA = "View Company Created Jsa",
  VIEW_JSA = "View Jsa",
  CREATE_EDIT_ARCHIVE_LIBRARY_JSA = "Create/Edit/Archive Library Jsa",
  CREATE_EDIT_ARCHIVE_COMPANY_JSA = "Create/Edit/Archive Company Jsa",
  IMPORT_LIBRARY_JSA = "Import Library Jsa",
  ADD_FILE_JSA = "Add the file to the jsa",

  VIEW_COMPANY_ASSIGNMENTS = "View Company Assignments",
  CREATE_EDIT_ARCHIVE_ASSIGNMENT = "Create/Edit/Archive Assignment",
  VIEW_TEAM_MEMBERS_ASSIGNMENTS_TABLE = "View Team members Assignments table",
  ADMIN_ASSIGNMENT_FILTER = "Admin Assignment Filter",

  CREATE_EDIT_ARCHIVE_COMPANY_TRAININGS = "Create/Edit/Archive Trainings",
  CREATE_EDIT_ARCHIVE_COMPANY_CERTIFICATES = "Create/Edit/Archive Certificates",

  VIEW_COMPANY_TRAININGS = "View Company Trainings",
  VIEW_COMPANY_CERTIFICATES = "View Company Certificates",

  VIEW_TEAM_MEMBERS_TRAINING_CERTIFICATE_TABLE = "View Team members Trainings/Certificates table",

  VIEW_COMPANY_DOCUMENTS_AND_LINKS = "View Company Documents and links",
  CREATE_EDIT_ARCHIVE_DOCUMENTS_AND_LINKS = "Create/Edit/Archive Documents and links",

  VIEW_COMPANY_SETTINGS = "View Company Settings",
  EDIT_COMPANY_SETTINGS = "Edit company settings",

  VIEW_COMPANY_ALERTS = "View Company Alerts",
  CREATE_EDIT_ARCHIVE_COMPANY_ALERTS = "Create/Edit/Archive Alerts",

  VIEW_COMPANY_TEAMS = "View Company Teams",
  CREATE_EDIT_ARCHIVE_COMPANY_TEAMS = "Create/Edit/Archive Teams",
  VIEW_COMPANY_PROJECTS = "View Company Projects",
  CREATE_EDIT_ARCHIVE_COMPANY_PROJECTS = "Create/Edit/Archive Projects",
}
