import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { Filter } from "@shared/interfaces";
import { CompanyFormShape, CompanyFormUpdateShape, UpdateStatusShape } from "@containers/Company/interface";

export default {
  getCompanies: (payload: Filter) => request(METHODS.GET, API.COMPANY.GET_COMPANIES)({ params: payload }),
  createCompany: (payload: CompanyFormShape) => request(METHODS.POST, API.COMPANY.CREATE_COMPANY)(payload),
  getCompany: (code: string) => request(METHODS.GET, API.COMPANY.GET_COMPANY(code))(),
  updateCompany: (payload: CompanyFormUpdateShape) => {
    const { code, ...restPayload } = payload;
    return request(METHODS.PUT, API.COMPANY.UPDATE_COMPANY(code))(restPayload);
  },
  updateCompanyStatus: (payload: UpdateStatusShape) => {
    const { code, ...restPayload } = payload;
    return request(METHODS.PUT, API.COMPANY.UPDATE_COMPANY_STATUS(code))(restPayload);
  },
};
