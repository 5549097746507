export enum ActivityActionTypes {
  GET_ACTIVITIES = "@@ACTIVITY/GET_ACTIVITIES",
  GET_ACTIVITIES_SUCCESS = "@@ACTIVITY/GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE = "@@ACTIVITY/GET_ACTIVITIES_FAILURE",

  CLEAR_ACTIVITIES = "@@ACTIVITY/CLEAR_ACTIVITIES",

  CREATE_LIBRARY_ACTIVITY = "@@ACTIVITY/CREATE_LIBRARY_ACTIVITY",
  CREATE_LIBRARY_ACTIVITY_SUCCESS = "@@ACTIVITY/CREATE_LIBRARY_ACTIVITY_SUCCESS",
  CREATE_LIBRARY_ACTIVITY_FAILURE = "@@ACTIVITY/CREATE_LIBRARY_ACTIVITY_FAILURE",

  UPDATE_ACTIVITY = "@@ACTIVITY/UPDATE_ACTIVITY",
  UPDATE_ACTIVITY_SUCCESS = "@@ACTIVITY/UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE = "@@ACTIVITY/UPDATE_ACTIVITY_FAILURE",

  UPDATE_ACTIVITY_STATUS = "@@ACTIVITY/UPDATE_ACTIVITY_STATUS",
  UPDATE_ACTIVITY_STATUS_SUCCESS = "@@ACTIVITY/UPDATE_ACTIVITY_STATUS_SUCCESS",
  UPDATE_ACTIVITY_STATUS_FAILURE = "@@ACTIVITY/UPDATE_ACTIVITY_STATUS_FAILURE",

  GET_ACTIVITY = "@@ACTIVITY/GET_ACTIVITY",
  GET_ACTIVITY_SUCCESS = "@@ACTIVITY/GET_ACTIVITY_SUCCESS",
  GET_ACTIVITY_FAILURE = "@@ACTIVITY/GET_ACTIVITY_FAILURE",

  IMPORT_ACTIVITY = "@@ACTIVITY/IMPORT_ACTIVITY",
  IMPORT_ACTIVITY_SUCCESS = "@@ACTIVITY/IMPORT_ACTIVITY_SUCCESS",
  IMPORT_ACTIVITY_FAILURE = "@@ACTIVITY/IMPORT_ACTIVITY_FAILURE",

  SET_LAST_CREATED_ACTIVITY = "@@ACTIVITY/SET_LAST_CREATED_ACTIVITY",

  SET_FILTER = "@@ACTIVITY/SET_FILTER",
}
