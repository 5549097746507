import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "@shared/constants";

import { UserStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: UserStateType = {
  users: [],
  usersTotal: 0,
  user: null,
  filter: defaultFilter,
};

const reducer = createReducer<UserStateType, Action>(initialState)
  .handleAction(actions.getUsers.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.users = clear ? rows : [...nextState.users, ...rows];
      nextState.usersTotal = count;
    }),
  )
  .handleAction(actions.getUser.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.user = action.payload;
    }),
  )
  .handleAction(actions.createUser.success, (state, action) =>
    produce(state, (nextState) => {
      const { user } = action.payload;
      nextState.users = [user, ...nextState.users];
      nextState.usersTotal = nextState.usersTotal + 1;
    }),
  )
  .handleAction(actions.updateUser.success, (state, action) =>
    produce(state, (nextState) => {
      const { user } = action.payload;
      const index = nextState.users.findIndex((u) => u.id === user.id);
      if (index >= 0) {
        nextState.users[index] = user;
      }
      nextState.user = user;
    }),
  )
  .handleAction(actions.clearUsers, (state) =>
    produce(state, (nextState) => {
      nextState.users = [];
      nextState.usersTotal = 0;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  );

export { reducer as UserReducer };
