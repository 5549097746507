export enum DocumentActionTypes {
  SET_FILTER = "@@DOCUMENT/SET_FILTER",
  SET_PARTIAL_FILTER = "@@DOCUMENT/SET_PARTIAL_FILTER",

  GET_DOCUMENTS = "@@DOCUMENT/GET_DOCUMENTS",
  GET_DOCUMENTS_SUCCESS = "@@DOCUMENT/GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_FAILURE = "@@DOCUMENT/GET_DOCUMENTS_FAILURE",

  GET_DOCUMENT = "@@DOCUMENT/GET_DOCUMENT",
  GET_DOCUMENT_SUCCESS = "@@DOCUMENT/GET_DOCUMENT_SUCCESS",
  GET_DOCUMENT_FAILURE = "@@DOCUMENT/GET_DOCUMENT_FAILURE",

  CREATE_DOCUMENT = "@@DOCUMENT/CREATE_DOCUMENT",
  CREATE_DOCUMENT_SUCCESS = "@@DOCUMENT/CREATE_DOCUMENT_SUCCESS",
  CREATE_DOCUMENT_FAILURE = "@@DOCUMENT/CREATE_DOCUMENT_FAILURE",

  UPDATE_DOCUMENT = "@@DOCUMENT/UPDATE_DOCUMENT",
  UPDATE_DOCUMENT_SUCCESS = "@@DOCUMENT/UPDATE_DOCUMENT_SUCCESS",
  UPDATE_DOCUMENT_FAILURE = "@@DOCUMENT/UPDATE_DOCUMENT_FAILURE",

  DELETE_DOCUMENT = "@@DOCUMENT/DELETE_DOCUMENT",
  DELETE_DOCUMENT_SUCCESS = "@@DOCUMENT/DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILURE = "@@DOCUMENT/DELETE_DOCUMENT_FAILURE",
}
