import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import {
  TrainingAndCertificateFilter,
  TrainingAndCertificateStateType,
  CertificateTemplatesFilter,
} from "../interface";
import * as actions from "./actions";

const DEFAULT_LIMIT = 50;

export const defaultFilter: TrainingAndCertificateFilter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  company_code: null,
  statuses: [],
};

export const defaultCertificteTemplatesFilter: CertificateTemplatesFilter = {
  page: 0,
  limit: DEFAULT_LIMIT,
};

type Action = ActionType<typeof actions>;

export const initialState: TrainingAndCertificateStateType = {
  trainings: [],
  trainingsTotal: 0,
  trainingAndCertificateFilter: defaultFilter,
  currentTraining: null,
  certificates: [],
  certificatesTotal: 0,
  currentCertificate: null,
  currentCertificateFiles: [],
  trainingPass: [],
  certificatesIssuedBy: [],
  certificatesIssuedByTotal: 0,
  certificateTemplatesFilter: defaultCertificteTemplatesFilter,
  certificateTemplatesTotal: 0,
  certificatesTemplates: [],
  certificateTemplatesTotalAdditional: 0,
  certificatesTemplatesAdditional: [],
  clearTrainingForm: false,
};

const reducer = createReducer<TrainingAndCertificateStateType, Action>(initialState)
  .handleAction(actions.getTrainings.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.trainings = clear ? rows : [...nextState.trainings, ...rows];
      nextState.trainingsTotal = count;
    }),
  )
  .handleAction(actions.setTrainingAndCertificateFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.trainingAndCertificateFilter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.getTraining.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentTraining = payload;
    }),
  )
  .handleAction(actions.updateTraining.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentTraining = payload.training;
    }),
  )
  .handleAction(actions.getCertificates.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.certificates = clear ? rows : [...nextState.certificates, ...rows];
      nextState.certificatesTotal = count;
    }),
  )
  .handleAction(actions.getCertificatesTemplates.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear, additional } = action.payload;
      const targetField = additional ? "certificatesTemplatesAdditional" : "certificatesTemplates";
      const targetFieldTotal = additional ? "certificateTemplatesTotalAdditional" : "certificateTemplatesTotal";
      nextState[targetField] = clear ? rows : [...nextState[targetField], ...rows];
      nextState[targetFieldTotal] = count;
    }),
  )
  .handleAction(actions.getCertificatesIssuedBy.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.certificatesIssuedBy = clear ? rows : [...nextState.certificatesIssuedBy, ...rows];
      nextState.certificatesIssuedByTotal = count;
    }),
  )
  .handleAction(actions.getCertificate.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentCertificate = payload;
    }),
  )
  .handleAction(actions.createCertificate.success, (state, action) =>
    produce(state, (nextState) => {
      const { certificate } = action.payload;
      nextState.certificates = [
        {
          ...certificate,
          member_first_name: certificate.member.first_name,
          member_last_name: certificate.member.last_name,
          member_id: certificate.member.id,
        },
        ...nextState.certificates,
      ];
      nextState.certificatesTotal = nextState.certificatesTotal + 1;
    }),
  )
  .handleAction(actions.updateCertificate.success, (state, action) =>
    produce(state, (nextState) => {
      const { certificate } = action.payload;
      const index = nextState.certificates.findIndex((c) => c.id === certificate.id);
      if (index >= 0) {
        nextState.certificates[index] = {
          ...certificate,
          member_first_name: certificate.member.first_name,
          member_last_name: certificate.member.last_name,
          member_id: certificate.member.id,
        };
      }
      nextState.currentCertificate = certificate;
    }),
  )
  .handleAction(actions.getCertificateFiles.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentCertificateFiles = action.payload;
    }),
  )
  .handleAction(actions.addCertificateFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentCertificateFiles = [action.payload.file, ...state.currentCertificateFiles];
    }),
  )
  .handleAction(actions.deleteCertificateFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentCertificateFiles = state.currentCertificateFiles.filter((f) => f.id !== action.payload.file_id);
    }),
  )
  .handleAction(actions.getTrainingPass.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.trainingPass = action.payload;
    }),
  )
  .handleAction(actions.passTraining.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.trainingPass = [...nextState.trainingPass, action.payload.passed_training];
    }),
  )
  .handleAction(actions.confirmTraining.success, (state, action) =>
    produce(state, (nextState) => {
      const updatedPassTraining = action.payload.passed_training;
      const index = nextState.trainingPass.findIndex((p) => p.member_id === updatedPassTraining.member_id);
      if (index >= 0) {
        nextState.trainingPass[index] = updatedPassTraining;
      } else {
        nextState.trainingPass = [...nextState.trainingPass, updatedPassTraining];
      }
    }),
  )
  .handleAction(actions.setClearTrainingForm, (state, action) =>
    produce(state, (nextState) => {
      nextState.clearTrainingForm = action.payload;
    }),
  );

export { reducer as TrainingAndCertificateReducer };
