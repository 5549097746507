export const NamesOfParentRoutes = {
  AUTH: "/auth*",
  AUTHORIZED: "*",
};

export const NameOfChildRoutes = {
  AUTH: {
    LOGIN: "/login",
    FORGOT_PASSWORD: "/restore",
    CHANGE_PASSWORD: "/change",
    REGISTRATION: "/registration",
  },
  USER: {
    LIST: "/",
  },
  COMPANY: {
    LIST: "/",
    SWITCH: "/:code/switch",
    USERS: "/:code/users",
    MEMBER_PROFILE: "/:code/profile-member/:id",
    MEMBERS: "/:code/members",
    MEMBERS_CREATE: "/:code/members/new",
    MEMBERS_EDIT: "/:code/members/:id",
    JSA_LIST: "/:code/jsa",
    ACTIVITIES: "/:code/activities",
    TASKS: "/:code/tasks",
    TASKS_CREATE: "/:code/tasks/new",
    TASKS_EDIT: "/:code/tasks/:id",
    JSA_CREATE: "/:code/jsa/new",
    JSA_EDIT: "/:code/jsa/:id",
    ASSIGNMENTS: "/:code/assignments",
    ASSIGNMENT_MEMBER_VIEW: "/:code/assignments/:id/members/:member_id",
    ASSIGNMENT_EDIT: "/:code/assignments/:id/edit",
    ASSIGNMENT_VIEW: "/:code/assignments/:id",
    TRAININGS_AND_CERTIFICATES: "/:code/trainings-and-certificates",
    TRAINING_CREATE: "/:code/trainings-and-certificates/new",
    TRAINING_EDIT: "/:code/trainings-and-certificates/:training_id/edit",
    TRAINING_VIEW: "/:code/trainings-and-certificates/:training_id",
    DOCUMENTS: "/:code/documents",
    SETTINGS: "/:code/settings",
    ALERTS: "/:code/alerts",
    PROJECTS: "/:code/projects",
    TEAMS: "/:code/teams",
  },
  ACTIVITY: {
    LIST: "/",
  },
  TASKS: {
    LIST: "/",
    TASKS_CREATE: "/new",
    TASKS_EDIT: "/:id",
  },
  JSA: {
    LIST: "/",
    JSA_CREATE: "/new",
    JSA_EDIT: "/:id",
  },
  ASSIGNMENT: {
    LIST: "/",
    ASSIGNMENT_CREATE: "/new",
    ASSIGNMENT_EDIT: "/:id/edit",
    ASSIGNMENT_MEMBER_VIEW: "/:id/members/:member_id",
    ASSIGNMENT_VIEW: "/:id",
    ASSIGNMENT_JSA: "/:id/jsa_pdf",
    CALENDAR: "/calendar",
  },
  MEMBER: {
    LIST: "/",
    CREATE: "/new",
    JSA_PDF: "/jsa_pdf/:passed_jsa_id",
    EDIT: "/:id",
    TRAINING: "/:id/training/:training_id",
  },
  TRAINING_AND_CERTIFICATE: {
    LIST: "/",
    CREATE: "/new",
    VIEW: "/:training_id",
    EDIT: "/:training_id/edit",
  },
  DOCUMENTS: {
    LIST: "/",
  },
  ALERT: {
    LIST: "/",
  },
  PROJECT: {
    LIST: "/",
  },
  TEAM: {
    LIST: "/",
  },
  DEFAULT_REDIRECT: "/*",
};

export enum NameOfRoutes {
  APP = "/",
  AUTH = "/auth/",
  AUTH_LOGIN = "/auth/login",
  AUTH_FORGOT_PASSWORD = "/auth/restore",
  AUTH_CHANGE_PASSWORD = "/auth/change",

  USERS = "/users",
  COMPANIES = "/companies",
  JSA_LIBRARY = "/jsa",
  TASKS = "/tasks",
  ACTIVITIES_LIBRARY = "/activities",

  SETTINGS = "/settings",
  MEMBERS = "/members",
  ASSIGNMENTS = "/assignments",
  TRAININGS_AND_CERTIFICATES = "/trainings-and-certificates",
  documents = "/documents",
}
