import React from "react";
import { ErrorMessage, ImageMultiSelect } from "@shared/components";
import { FieldItemType } from "@shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface ImageMultiSelectFieldItem extends RenderField {
  type: FieldItemType.IMAGE_MULTI_SELECT;
  label: string;
  disabled?: boolean;
  inputClass?: string;
  // eslint-disable-next-line
  onChangeField?: (field: string, value: any, values: any, setFieldValue?: (field: string, value: any) => void) => void;
  // eslint-disable-next-line
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const GenerateImageMultiSelect: GenerateRenderField<ImageMultiSelectFieldItem> = (props) => {
  const {
    name,
    inputClass,
    label,
    onChangeField,
    disabled,
    formikProps: { values, touched, errors, setFieldValue, setFieldTouched },
  } = props;

  return (
    <div key={name} id={name}>
      <ImageMultiSelect
        label={label}
        className={inputClass}
        value={values[name]}
        disabled={disabled}
        name={name}
        onChange={(data) => {
          setFieldValue(name, data);
          setFieldTouched(name, true);
          onChangeField && onChangeField(name, data, setFieldValue);
        }}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
