import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";

export interface ArchiveButtonProps {
  onClick?: () => void;
  className?: string;
  text?: string;
}

const ArchiveButton: FC<ArchiveButtonProps> = (props) => {
  const { onClick, className, text } = props;

  return (
    <div className={classnames("form-header-delete archive-button", className)} onClick={onClick}>
      {text || "Archive"}
    </div>
  );
};

export default ArchiveButton;
