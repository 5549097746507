import { BaseEntity } from "@shared/interfaces";

import { Location, CertificateTemplate } from "./";

export enum ProjectStatus {
  Active = "Active",
  Archived = "Archived",
}

export interface Project {
  id: number;
  name: string;
  client: BaseEntity;
  number: string;
  location: Location;
  created_by?: string;
  created_at?: string;
  status?: ProjectStatus;
  certificate_templates?: CertificateTemplate[];
}
