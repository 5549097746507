import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyNumeric } from "@shared/utils";
import {
  getUsersResponseSchema,
  createUserRequestSchema,
  updateUserRequestSchema,
  updateUserStatusRequestSchema,
  commonUserResponseSchema,
  filterSchema,
  checkUserExistsRequestSchema,
  checkUserExistsResponseSchema,
  getUserResponseSchems,
} from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.USER> = {
  [API_ENUM.GET_USERS]: {
    request: {
      name: `${API.USER.GET_USERS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${API.USER.GET_USERS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getUsersResponseSchema,
    },
  },
  [API_ENUM.GET_USER]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.USER.GET_USER("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.USER.GET_USER("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getUserResponseSchems,
    },
  },
  [API_ENUM.CREATE_USER]: {
    request: {
      name: `${API.USER.CREATE_USER}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createUserRequestSchema,
    },
    response: {
      name: `${API.USER.CREATE_USER}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: commonUserResponseSchema,
    },
  },
  [API_ENUM.UPDATE_USER]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.USER.UPDATE_USER("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: updateUserRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.USER.UPDATE_USER("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: commonUserResponseSchema,
    },
  },
  [API_ENUM.UPDATE_USER_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.USER.UPDATE_USER_STATUS("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: updateUserStatusRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.USER.UPDATE_USER_STATUS("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: commonUserResponseSchema,
    },
  },
  [API_ENUM.CHECK_USER_EXISTS]: {
    request: {
      name: `${API.USER.CHECK_USER_EXISTS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: checkUserExistsRequestSchema,
    },
    response: {
      name: `${API.USER.CHECK_USER_EXISTS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: checkUserExistsResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
