import { getFirstDayOfMonth, getLastDayOfMonth } from "@shared/utils";
import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { AssignmentFilter, AssignmentStateType, LAST_ASSIGNMENT_VIEW } from "../interface";
import * as actions from "./actions";

const DEFAULT_LIMIT = 50;

export const defaultFilter: AssignmentFilter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  start_date: getFirstDayOfMonth(new Date()),
  end_date: getLastDayOfMonth(new Date()),
  company_code: null,
};

type Action = ActionType<typeof actions>;

export const initialState: AssignmentStateType = {
  assignments: [],
  assignmentsTotal: 0,
  membersAssignments: [],
  membersAssignmentsTotal: 0,
  filter: defaultFilter,
  currentAssignment: null,
  currentAssignmentCheckInOut: [],
  currentAssignmentPassedJsa: [],
  currentAssignmentJsa: [],
  lastAssignmentView: LAST_ASSIGNMENT_VIEW.list,
  clickedCalendarDay: null,
  clearAssignmentForm: false,
};

const reducer = createReducer<AssignmentStateType, Action>(initialState)
  .handleAction(actions.getAssignmentCheckInOut.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentAssignmentCheckInOut = action.payload;
    }),
  )
  .handleAction(actions.getAssignmentPassedJsa.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentAssignmentPassedJsa = action.payload;
    }),
  )
  .handleAction(actions.getAssignmentJsa.success, (state, action) =>
    produce(state, (nextState) => {
      const { jsa, passed_jsa } = action.payload;

      if (passed_jsa) {
        nextState.currentAssignmentJsa = passed_jsa.map((pJsa) => {
          return {
            name: pJsa.name,
            jsa_id: pJsa.jsa_id,
            signature_url: pJsa.signature_url,
            signature_date: pJsa.signature_date,
            member_first_name: pJsa.member_first_name,
            member_last_name: pJsa.member_last_name,

            tasks: pJsa.passed_tasks.map((jTask) => jTask.task),

            location_address: null,
            project_number: null,
            project_name: null,
            assignment_date: null,
          };
        });
      } else if (jsa) {
        nextState.currentAssignmentJsa = jsa.map((jsa) => {
          return {
            jsa_id: jsa.jsa_id,
            name: jsa.name,
            tasks: jsa.tasks,

            signature_url: null,
            signature_date: null,
            location_address: null,
            project_name: null,
            project_number: null,
            assignment_date: null,
          };
        });
      }
    }),
  )
  .handleAction(actions.getAssignments.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.assignments = clear ? rows : [...nextState.assignments, ...rows];
      nextState.assignmentsTotal = count;
    }),
  )
  .handleAction(actions.getMembersAssignments.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.membersAssignments = clear ? rows : [...nextState.membersAssignments, ...rows];
      nextState.membersAssignmentsTotal = count;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.setPartialFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = {
        ...state.filter,
        ...action.payload,
      };
    }),
  )
  .handleAction(actions.createAssignmentCheckIn.success, (state, action) =>
    produce(state, (nextState) => {
      const { assignment_check_in_out } = action.payload;
      nextState.currentAssignmentCheckInOut = [assignment_check_in_out, ...nextState.currentAssignmentCheckInOut];
    }),
  )
  .handleAction(actions.createAssignmentCheckOut.success, (state, action) =>
    produce(state, (nextState) => {
      const { assignment_check_in_out } = action.payload;
      nextState.currentAssignmentCheckInOut = [
        assignment_check_in_out,
        ...nextState.currentAssignmentCheckInOut.filter((checkInOut) => checkInOut.id !== assignment_check_in_out.id),
      ];
    }),
  )
  .handleAction(actions.getAssignment.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentAssignment = payload;
    }),
  )
  .handleAction(actions.updateAssignment.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentAssignment = payload.assignment;
    }),
  )
  .handleAction(actions.setLastAssignmentView, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.lastAssignmentView = payload;
    }),
  )
  .handleAction(actions.setClickedCalendarDay, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.clickedCalendarDay = payload;
    }),
  )
  .handleAction(actions.setClearAssignmentForm, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.clearAssignmentForm = payload;
    }),
  );

export { reducer as AssignmentReducer };
