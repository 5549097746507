import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions as sharedActions } from "@shared/store";

import { CertificateCreateEditContainer } from "../containers/CertificateCreateEditContainer";
import { CertificateViewContainer } from "../containers/CertificateViewContainer";

export interface IUseShowCertificateViewHook {
  memberId?: string | number;
  companyCode?: string | null;
}

export default function useShowCertificateViewHook({ memberId, companyCode }: IUseShowCertificateViewHook) {
  const dispatch = useDispatch();

  const onEditCertificate = useCallback(
    (certificateId: number) => {
      if (!companyCode) return;

      dispatch(
        sharedActions.showOverlay({
          body: (
            <CertificateCreateEditContainer
              memberId={memberId ? Number(memberId) : undefined}
              companyCode={companyCode}
              certificateId={certificateId}
            />
          ),
        }),
      );
    },
    [dispatch, companyCode, memberId],
  );

  const showViewCertificateOverlay = useCallback(
    (certificateId: number) => {
      if (!companyCode) return;

      dispatch(
        sharedActions.showOverlay({
          body: (
            <CertificateViewContainer
              memberId={memberId}
              companyCode={companyCode}
              certificateId={certificateId}
              onEdit={() => onEditCertificate(certificateId)}
            />
          ),
        }),
      );
    },
    [dispatch, memberId, onEditCertificate, companyCode],
  );

  return { showViewCertificateOverlay };
}
