import { Job } from "./Job";
import { Industry } from "./Industry";
import { File } from "./File";

export enum CompanyStatus {
  Inactive = "Inactive",
  Active = "Active",
  Deactivated = "Deactivated",
}

interface BaseCompany {
  name: string;
  code: string;
  contact_first_name: string;
  contact_last_name: string;
  created_at: string;
  status: CompanyStatus;
}

export interface Company extends BaseCompany {
  phone: string | null;
  address1: string;
  address2: string | null;
  state: string | null;
  zip: string;
  country: string;
  contact_email: string;
  contact_phone: string | null;
  contact_job: Job;
  industry: Industry;
  users_count?: number;
  logo: Partial<File> | null;
  owner_user_id?: number | null;
}

export interface CompanyListItem extends BaseCompany {
  users_count: number;
}
