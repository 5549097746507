import { permissionSchema } from "./permission.schema";
import { getRequestSchema } from "./shared.schema";

export const roleSchema = {
  type: "object",
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    sequence: { type: "number" },
    name: { type: "string" },
    is_app_admin: { type: "boolean" },
    code: { type: ["string", "null"] },
    permissions: {
      type: "array",
      items: [permissionSchema],
    },
  },
  additionalProperties: false,
};

export const getRolesRequestSchema = getRequestSchema({ is_app_admin: { type: "boolean" } });

export const getRolesResponseSchema = {
  type: "array",
  additionalProperties: false,
  items: [roleSchema],
};
