import React, { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  to: string;
};

const RedirectLink: FC<Props> = ({ children, to }) => (
  <div className="custom-link">
    <Link to={to}>{children}</Link>
  </div>
);

export default RedirectLink;
