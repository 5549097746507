import { userRoleSchema } from "./user_role.schema";
import { getRequestSchema, getPaginatedResponseSchema } from "./shared.schema";

const commonUserProperties = {
  id: { type: "number" },
  first_name: { type: "string" },
  last_name: { type: "string" },
  phone: { type: ["string", "null"] },
  email: { type: "string" },
  user_roles: {
    type: "array",
    items: [userRoleSchema],
  },
};
const fullUserProperties = {
  ...commonUserProperties,
  created_at: { type: "string" },
  last_login_date: { type: ["string", "null"] },
  creator: {
    type: ["object", "null"],
    additionalProperties: false,
    required: ["id", "first_name", "last_name"],
    properties: {
      id: { type: "number" },
      first_name: { type: "string" },
      last_name: { type: "string" },
    },
  },
};

export const getUserDetailsResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "first_name", "last_name", "email"],
  properties: {
    ...commonUserProperties,
    user_settings: {
      type: "array",
      items: [
        {
          type: ["object"],
          additionalProperties: false,
          required: ["id", "is_notifications_disabled"],
          properties: {
            id: { type: "number" },
            is_notifications_disabled: { type: "boolean" },
            company: {
              type: ["object"],
              additionalProperties: false,
              required: ["name", "code"],
              properties: {
                name: { type: "string" },
                code: { type: "string" },
              },
            },
          },
        },
      ],
    },
    members: {
      type: "array",
      items: [
        {
          type: ["object"],
          additionalProperties: false,
          required: ["id", "first_name", "last_name"],
          properties: {
            id: { type: "number" },
            first_name: { type: "string" },
            last_name: { type: "string" },
            company: {
              type: ["object"],
              additionalProperties: false,
              required: ["name", "code"],
              properties: {
                name: { type: "string" },
                code: { type: "string" },
              },
            },
            avatar: {
              type: ["object", "null"],
              additionalProperties: false,
              required: ["id", "url"],
              properties: {
                id: { type: "number" },
                url: { type: "string" },
              },
            },
          },
        },
      ],
    },
  },
};

const userFromListSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "first_name", "last_name", "last_login_date", "created_at"],
  properties: {
    ...fullUserProperties,
  },
};

export const getUsersResponseSchema = getPaginatedResponseSchema(userFromListSchema);

export const getUserResponseSchems = {
  type: "object",
  additionalProperties: false,
  properties: {
    ...fullUserProperties,
  },
};

export const createUserRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["first_name", "last_name", "email", "role_id"],
  properties: {
    first_name: { type: "string" },
    last_name: { type: "string" },
    email: { type: "string" },
    role_id: { type: "number" },
    company_code: { type: ["string", "null"] },
  },
};

export const updateUserRequestSchema = {
  type: "object",
  required: ["first_name", "last_name", "role_id"],
  properties: {
    first_name: { type: "string" },
    last_name: { type: "string" },
    role_id: { type: "number" },
    company_code: { type: ["string", "null"] },
  },
};

export const updateUserStatusRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["status"],
  properties: {
    status: { type: "string" },
    company_code: { type: ["string", "null"] },
  },
};

export const commonUserResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "user"],
  properties: {
    message: { type: "string" },
    user: commonUserProperties,
  },
};

export const checkUserExistsRequestSchema = getRequestSchema({
  email: { type: "string" },
  company_code: { type: ["string", "null"] },
});

export const checkUserExistsResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["exists"],
  properties: {
    exists: { type: "boolean" },
  },
};
