import React, { FC } from "react";
import { MemberWithCertificateInfoListItem } from "@shared/models";
import { Table } from "@shared/components";
import { SortTable } from "@shared/interfaces";

import { memberTableProperties } from "./tableHelpers";

export interface MembersCertificateTableProps extends SortTable {
  members: MemberWithCertificateInfoListItem[];
  onLoadNextPage: () => void;
  onClickCertificate?: (member: MemberWithCertificateInfoListItem) => void;
}

const MemberCertificateTable: FC<MembersCertificateTableProps> = (props) => {
  const { members, onLoadNextPage, onClickCertificate, sortBy, sortOrder, onChangeSorting } = props;

  return (
    <div className="members-certificate-table">
      <Table<MemberWithCertificateInfoListItem>
        onRowClick={onClickCertificate}
        onFinishScroll={onLoadNextPage}
        items={members}
        properties={memberTableProperties}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onChangeSorting={onChangeSorting}
      />
    </div>
  );
};

export default MemberCertificateTable;
