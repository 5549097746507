import { signatureProperties } from "./signature.schema";
import { shortUserSchema } from "./shared.schema";

import { getRequestSchema, getPaginatedResponseSchema, commonTaskProperties, formTaskProperties } from ".";

const memberTeamsProperties = {
  type: "array",
  items: [
    {
      type: "object",
      additionalProperties: false,
      required: ["id", "name"],
      properties: {
        id: { type: "number" },
        name: { type: "string" },
      },
    },
  ],
};

const memberJobProperties = {
  type: ["object", "null"],
  additionalProperties: false,
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
  },
};

const commonMemberProperties = {
  id: { type: "number" },
  first_name: { type: "string" },
  last_name: { type: "string" },
  email: { type: "string" },
  status: { type: "string" },
  job: memberJobProperties,
  teams: memberTeamsProperties,
  created_at: { type: "string" },
};

const memberProperties = {
  ...commonMemberProperties,
  phone: { type: ["string", "null"] },
  supervisor: {
    type: ["object", "null"],
    additionalProperties: false,
    required: ["id", "first_name", "last_name"],
    properties: {
      id: { type: "number" },
      first_name: { type: "string" },
      last_name: { type: "string" },
    },
  },
  avatar: {
    type: ["object", "null"],
    additionalProperties: false,
    required: ["id", "url"],
    properties: {
      id: { type: "number" },
      url: { type: "string" },
    },
  },
};

const memberAssignmentsProperties = {
  ...commonMemberProperties,
  assignments_count: { type: "string" },
  last_assignment_start_date: { type: ["string", "null"] },
};

const certificateTemplatesMember = {
  type: ["array", "null"],
  items: [
    {
      type: "object",
      properties: {
        id: { type: "number" },
      },
    },
  ],
};

export const memberForListSchema = {
  type: "object",
  required: ["first_name", "last_name", "email", "status", "job", "supervisor", "teams", "created_at"],
  properties: {
    ...memberProperties,
    certificate_templates: certificateTemplatesMember,
  },
  additionalProperties: false,
};

const memberAssignmentsForListSchema = {
  type: "object",
  required: ["id", "first_name", "last_name", "email", "status", "job", "teams", "created_at", "assignments_count"],
  properties: {
    ...memberAssignmentsProperties,
  },
  additionalProperties: false,
};

const memberTrainingForListSchema = {
  type: "object",
  additionalProperties: false,
  required: ["first_name", "last_name", "assigned_by", "training_id", "training_name"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    assigned_by: { type: "string" },
    training_id: { type: "number" },
    training_name: { type: "string" },
    training_is_auto_create_certificate: { type: "boolean" },
    passed_training_created_at: { type: ["string", "null"] },
    training_repeat_every: { type: ["number", "null"] },
    training_repeat_every_period: { type: ["string", "null"] },
    training_expiration_date: { type: ["string", "null"] },
    training_scheduled_start_date: { type: ["string", "null"] },
    training_scheduled_end_date: { type: ["string", "null"] },
    status: { type: ["string", "null"] },
  },
};

export const fullMemberSchema = {
  type: "object",
  required: ["first_name", "last_name", "email", "status", "job", "teams", "created_at"],
  properties: {
    ...memberProperties,
    is_supervisor: { type: "boolean" },
    user_id: { type: ["number", "null"] },
    trainings_count: {
      type: "object",
      required: ["missed", "passed", "scheduled"],
      properties: {
        missed: { type: ["number"] },
        passed: { type: ["number"] },
        scheduled: { type: ["number"] },
      },
    },
    certificates_count: {
      type: "object",
      required: ["valid", "expired"],
      properties: {
        valid: { type: ["number"] },
        expired: { type: ["number"] },
      },
    },
    signature: {
      type: ["object", "null"],
      additionalProperties: false,
      properties: {
        ...signatureProperties,
      },
    },
  },
  additionalProperties: true,
};

export const getMembersResponseSchema = getPaginatedResponseSchema(memberForListSchema);

export const getMembersAssignmentsResponseSchema = getPaginatedResponseSchema(memberAssignmentsForListSchema);

const memberTrainingsForListSchema = {
  type: "object",
  required: ["first_name", "last_name", "assigned_by", "training_id", "training_name"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    assigned_by: { type: "string" },
    training_id: { type: "number" },
    training_name: { type: "string" },
    training_scheduled_start_date: { type: ["string", "null"] },
    training_scheduled_end_date: { type: ["string", "null"] },
    training_repeat_every: { type: ["string", "null", "number"] },
    training_repeat_every_period: { type: ["string", "null"] },
    training_expiration_date: { type: ["string", "null"] },
    status: { type: ["string", "null"] },
    passed_training_created_at: { type: ["string", "null"] },
    training_is_auto_create_certificate: { type: "boolean" },
  },
  additionalProperties: false,
};
export const getMembersTrainingsResponseSchema = getPaginatedResponseSchema(memberTrainingsForListSchema);

const memberCertificatesForListSchema = {
  type: "object",
  required: ["first_name", "last_name", "assigned_by", "certificate_id", "certificate_name", "certificate_expire_date"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    assigned_by: { type: "string" },
    certificate_id: { type: "number" },
    certificate_name: { type: "string" },
    certificate_expire_date: { type: "string" },

    certificate_issued_by: { type: ["string", "null"] },
    certificate_issued_on: { type: ["string", "object", "null"] },
    certificate_valid: { type: ["number", "null"] },
    certificate_valid_period: { type: ["string", "null"] },

    status: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

const memberJsaForListSchema = {
  type: "object",
  required: ["name", "passed_jsa_id", "jsa_id"],
  properties: {
    passed_jsa_id: { type: "number" },
    jsa_id: { type: "number" },
    member_id: { type: "number" },
    name: { type: "string" },
    assignment_date: { type: ["string", "null"] },
    assignment_id: { type: ["number", "null"] },
    sign_date: { type: "string" },
    project_name: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const getMembersCertificatesResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["rows", "count"],
  properties: {
    rows: { type: "array", items: [memberCertificatesForListSchema] },
    count: { type: "number" },
  },
};

export const getMembersJsaResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["rows", "count"],
  properties: {
    rows: { type: "array", items: [memberJsaForListSchema] },
    count: { type: "number" },
  },
};

const memberFormCommonProperties = {
  first_name: { type: "string" },
  last_name: { type: "string" },
  phone: { type: ["string", "null"] },
  email: { type: "string" },
  job_id: { type: ["number", "null"] },
  supervisor_id: { type: ["number", "null"] },
  teams: { type: "array" },
  is_create_user: { type: "boolean" },
  avatar_base64: { type: ["string", "null"] },
  is_supervisor: { type: "boolean" },
  is_connect_user: { type: ["boolean", "null"] },
};

const memberFormRequiredProperties = [
  "first_name",
  "last_name",
  "phone",
  "email",
  "job_id",
  "supervisor_id",
  "teams",
  "is_create_user",
];

export const createMemberRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: memberFormRequiredProperties,
  properties: {
    ...memberFormCommonProperties,
  },
};

export const updateMemberRequestSchema = {
  type: "object",
  anyOf: [
    {
      additionalProperties: false,
      required: ["is_delete_avatar", ...memberFormRequiredProperties],
      properties: {
        ...memberFormCommonProperties,
        is_delete_avatar: { type: ["boolean", "null"] },
      },
    },
    {
      additionalProperties: true,
      required: [],
      properties: {
        is_delete_avatar: { type: ["boolean", "null"] },
        avatar_base64: { type: ["string", "null"] },
      },
    },
  ],
};

export const updateMemberStatusRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["status"],
  properties: {
    status: { type: "string" },
  },
};

export const updateRequestRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["is_delete_avatar", ...memberFormRequiredProperties],
  properties: {
    ...memberFormCommonProperties,
  },
};

export const createMemberResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "member"],
  properties: {
    message: { type: "string" },
    member: fullMemberSchema,
  },
};

export const membersAssignmentsFilterSchema = {
  type: "object",
  additionalProperties: true,
  properties: {
    params: {
      type: "object",
      properties: {
        page: { type: "number" },
        limit: { type: "number" },
        project_id: { type: ["number", "string", "null"] },
        location_id: { type: "number" },
        activities: { type: "array" },
        members: { type: "array" },
        teams: { type: "array" },
        status: { type: ["string", "null"] },
        code: { type: "string" },
      },
    },
  },
};

export const memberTrainingsFilterSchema = getRequestSchema({
  page: { type: "number" },
  limit: { type: "number" },
  activities: { type: "array" },
  status: { type: ["string", "null"] },
  member_id: { type: ["number", "null"] },
});

export const getMemberTrainingsResponseSchema = getPaginatedResponseSchema(memberTrainingForListSchema);
export const membersTrainingAndCertificateFilterSchema = {
  type: "object",
  additionalProperties: true,
  properties: {
    params: {
      type: "object",
      properties: {
        page: { type: "number" },
        limit: { type: "number" },
        teams: { type: "array" },
        start_date: { type: ["string", "null", "object"] },
        end_date: { type: ["string", "null", "object"] },
        search: { type: ["string", "null"] },
        activities: { type: "array" },
        status: { type: ["string", "null"] },
        code: { type: "string" },
      },
    },
  },
};

export const membersForAssignmentItemSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    is_deleted: { type: ["boolean", "null"] },
    job: memberJobProperties,
    teams: memberTeamsProperties,
    certificate_templates: certificateTemplatesMember,
  },
};

export const setMemberSignatureRequestSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    base64: { type: "string" },
  },
};

export const setMemberSignatureResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    signature: {
      type: "object",
      additionalProperties: false,
      properties: {
        ...signatureProperties,
      },
    },
  },
};

export const deleteMemberSignatureResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message"],
  properties: {
    message: { type: "string" },
  },
};

export const getMemberJsaResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    name: { type: "string" },
    member_first_name: { type: "string" },
    member_last_name: { type: "string" },
    jsa_id: { type: "number" },
    jsa_old_version_id: { type: "number" },
    signature_url: { type: ["string", "null"] },
    signature_date: { type: ["string", "null"] },
    assignment_date: { type: ["string", "null"] },
    project_name: { type: ["string", "null"] },
    project_number: { type: ["string", "null"] },
    location_address: { type: ["string", "null"] },
    tasks: {
      type: "array",
      items: {
        type: "object",
        properties: {
          ...commonTaskProperties,
          ...formTaskProperties,
        },
      },
    },
  },
};

export const getMemberJsaListResponseSchema = {
  type: "array",
  items: [getMemberJsaResponseSchema],
};

export const memberNoteSchema = {
  type: "object",
  required: ["id", "description", "created_at", "creator"],
  properties: {
    id: { type: "number" },
    description: { type: "string" },
    created_at: { type: "string" },
    creator: shortUserSchema,
  },
};

export const memberNoteListSchema = {
  type: "array",
  items: [memberNoteSchema],
};

export const createMemberNoteRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["description"],
  properties: {
    description: { type: "string" },
  },
};

export const createMemberNoteResponseSchema = {
  type: "object",
  additionalProeprties: false,
  required: ["message", "note"],
  properties: {
    message: { type: "string" },
    note: memberNoteSchema,
  },
};
