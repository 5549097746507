import React, { FC, useCallback } from "react";
import { useShowModal, useHideModal } from "@shared/hooks";
import { ExtraKeyType } from "@shared/interfaces";
import { CertificateTemplate, MemberListItem } from "@shared/models";
import classnames from "classnames";

import { TeamMemberModal } from "./TeamMemberModal";
import { TeamMemberLabel } from "./TeamMemberLabel";

import "./index.scss";

export interface TeamMemberSelectorProps {
  className?: string;
  companyCode?: string | null;
  selectedMembers: MemberListItem[];
  onChange: (members: MemberListItem[]) => void;
  disabled?: boolean;
  disabledMembersObj?: ExtraKeyType<boolean>;
  getDisabledRejectedText?: (item: MemberListItem) => string;
  disabledDeleteModalTitle?: string;
  onDeleteDisabledMember?: (item: MemberListItem) => void;
  allowDeleteOnDisabled?: boolean;
  certificateTemplates?: (CertificateTemplate | null)[];
  certificateExpireDate?: Date | null;
  forbidAdd?: boolean;
}

const TeamMemberSelector: FC<TeamMemberSelectorProps> = (props) => {
  const {
    companyCode,
    selectedMembers,
    onChange,
    disabled,
    disabledMembersObj,
    getDisabledRejectedText,
    disabledDeleteModalTitle,
    className,
    allowDeleteOnDisabled,
    certificateTemplates,
    certificateExpireDate,
    forbidAdd,
  } = props;
  const showModal = useShowModal();
  const { handleOnClose } = useHideModal();

  const onAddClick = useCallback(() => {
    showModal({
      modalWidth: "540px",
      wrapperClass: "team-members-modal",
      closeOnOutside: true,
      body: (
        <TeamMemberModal
          companyCode={companyCode}
          selectedMembers={selectedMembers}
          onCancel={handleOnClose}
          onAssign={(members) => {
            onChange(selectedMembers.concat(members));
            handleOnClose();
          }}
          certificateTemplates={certificateTemplates}
          certificateExpireDate={certificateExpireDate}
        />
      ),
    });
  }, [showModal, companyCode, handleOnClose, selectedMembers, onChange, certificateTemplates, certificateExpireDate]);

  const onDeleteDisabledMember = useCallback(
    (item: MemberListItem) => {
      showModal({
        closeOnOutside: true,
        body: getDisabledRejectedText ? getDisabledRejectedText(item) : "You can't delete this team member",
        confirmText: "Got It",
        onConfirm: handleOnClose,
        hideCancelButton: true,
        title: disabledDeleteModalTitle || "",
      });
    },
    [getDisabledRejectedText, showModal, handleOnClose, disabledDeleteModalTitle],
  );

  const onDelete = useCallback(
    (item: MemberListItem) => {
      if (disabledMembersObj && disabledMembersObj[item.id]) {
        onDeleteDisabledMember?.(item);
        return;
      }
      const index = selectedMembers.findIndex((m) => m.id === item.id);
      if (index >= 0) {
        const tempArray = [...selectedMembers];
        tempArray.splice(index, 1);
        onChange(tempArray);
      }
    },
    [onChange, selectedMembers, disabledMembersObj, onDeleteDisabledMember],
  );

  const onClear = useCallback(() => {
    let membersForClear: MemberListItem[] = [];

    if (disabledMembersObj) {
      membersForClear = selectedMembers.filter((m) => Boolean(disabledMembersObj[m.id]));
    }

    onChange(membersForClear);
  }, [onChange, selectedMembers, disabledMembersObj]);

  const localOnDelete = useCallback(
    (item: MemberListItem) => {
      if (disabled && !allowDeleteOnDisabled) {
        return undefined;
      } else if (disabledMembersObj && disabledMembersObj[item.id]) {
        return () => onDeleteDisabledMember(item);
      } else {
        return () => onDelete(item);
      }
    },
    [onDeleteDisabledMember, onDelete, disabled, disabledMembersObj, allowDeleteOnDisabled],
  );

  return (
    <div className={classnames("team-member-selector-wrapper", className)}>
      <div className="form-subtitle-wrapper">
        <div className="form-subtitle-title">Team Members</div>
        <div className="form-subtitle-right-wrapper">
          {!disabled && (
            <>
              {Boolean(selectedMembers.length) && (
                <div className="form-subtitle-clear" onClick={onClear}>
                  Clear
                </div>
              )}
              {!forbidAdd && (
                <div className="form-subtitle-add" onClick={onAddClick}>
                  + Add Team Member
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="team-member-selector-values">
        {selectedMembers.map((item) => (
          <TeamMemberLabel
            certificateTemplates={certificateTemplates}
            member={item}
            key={`assignment_members_${item.id}`}
            onDelete={localOnDelete(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamMemberSelector;
