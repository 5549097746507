import { BaseEntity, BaseModel } from "@shared/interfaces";

import { ShortMember } from "./Member";
import { BaseFile } from "./File";

export enum CertificateStatus {
  Archived = "Archived",
  Draft = "Draft",
  Active = "Active",
}

export enum ValidPeriod {
  Year = "Year",
  Month = "Month",
  Week = "Week",
}
interface BaseCertificate extends BaseModel {
  issued_on: string;
  issued_by: string | null;
  name: string;
  valid: number;
  valid_period: ValidPeriod;
}

interface CertificateMember extends ShortMember {
  avatar: BaseFile | null;
}

export interface CertificateListItem extends BaseCertificate {
  member_id: number;
  member_first_name: string;
  member_last_name: string;
}

export interface Certificate extends BaseCertificate {
  status: CertificateStatus;
  name: string;
  activities: BaseEntity[];
  projects: BaseEntity[];
  member: CertificateMember;
  expire_date: string;
}

export interface CertificateTrainingPassItem extends BaseCertificate {
  expire_date: string;
}

export interface CertificateIssuedByItem {
  issued_by: string;
}
