import React, { FC } from "react";
import classnames from "classnames";
import { InputProps } from "@shared/interfaces";

import "./index.scss";

export const CheckboxInput: FC<InputProps> = (props: InputProps) => {
  const { className, disabled, handleClick, ...inputProps } = props;
  return (
    <div className={classnames("checkbox-input", className, { checked: !!props.value, disabled: disabled })}>
      <label className="no-select" onClick={handleClick}>
        <span className={classnames("checkmark", { disabled: disabled })} />
        <span className="heading">{props.label}</span>
        <input
          type="checkbox"
          {...inputProps}
          disabled={disabled}
          onChange={inputProps.onChange}
          checked={!!props.value}
        />
      </label>
    </div>
  );
};
