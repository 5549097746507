import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHideOverlay, useShowOverlay } from "@shared/hooks";
import { ContainerWithCompanyCode } from "@shared/interfaces";
import { selectors } from "@containers/UserNotification/store";

import { UserNotificationContainer } from "..";

const UserNotificationOverlay: FC<Required<ContainerWithCompanyCode>> = ({ companyCode }) => {
  const { handleOnClose } = useHideOverlay();

  return <UserNotificationContainer companyCode={companyCode} onCancel={handleOnClose} />;
};

export function useUserNotification(companyCode?: string | null) {
  const unreadNotificationsCount = useSelector(selectors.getUserNotificationsUnreadCount());
  const [isOpenUserNotificationOverlay, setIsOpenUserNotificationOverlay] = useState(false);
  const showOverlay = useShowOverlay();

  const showUserNotificationOverlay = useCallback(() => {
    if (!companyCode || isOpenUserNotificationOverlay) return;

    showOverlay({
      body: <UserNotificationOverlay companyCode={companyCode} />,
      onLeave: () => setIsOpenUserNotificationOverlay(false),
    });

    setIsOpenUserNotificationOverlay(true);
  }, [showOverlay, companyCode, isOpenUserNotificationOverlay]);

  return {
    showUserNotificationOverlay,
    isOpenUserNotificationOverlay,
    unreadNotificationsCount,
  };
}

export default useUserNotification;
