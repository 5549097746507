import { Document } from "@shared/models";
import {
  DocumentFilter,
  DocumentFormShapeToCreateRequest,
  DocumentFormShapeToUpdateRequest,
  DocumentUpdateResponse,
  DocumentDeleteResponse,
} from "@containers/Documents/interface";
import { PaginatedResponse, ActionWithPayload, CodeWithId } from "@shared/interfaces";
import { hideOverlay, startLoading, stopLoading } from "@shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import { createDocument, deleteDocument, getDocument, getDocuments, updateDocument } from "./actions";

function* getDocumentsSaga({ payload }: ActionWithPayload<DocumentFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Document> = yield call(api.getDocuments, payload);
    yield put(stopLoading());
    yield put(getDocuments.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getDocuments.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getDocumentSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: Document = yield call(api.getDocument, payload);
    yield put(stopLoading());
    yield put(getDocument.success(response));
  } catch (error) {
    yield put(getDocument.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createDocumentSaga({ payload }: ActionWithPayload<DocumentFormShapeToCreateRequest>) {
  try {
    yield put(startLoading());
    const response: DocumentUpdateResponse = yield call(api.createDocument, payload);
    yield put(stopLoading());
    yield put(createDocument.success({ ...response }));
    yield put(hideOverlay());
  } catch (error) {
    yield put(createDocument.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateDocumentSaga({ payload }: ActionWithPayload<DocumentFormShapeToUpdateRequest>) {
  try {
    yield put(startLoading());
    const response: DocumentUpdateResponse = yield call(api.updateDocument, payload);
    yield put(stopLoading());
    yield put(updateDocument.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(updateDocument.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteDocumentSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: DocumentDeleteResponse = yield call(api.deleteDocument, payload);
    yield put(stopLoading());
    yield put(deleteDocument.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(deleteDocument.failure(error as Error));
    yield put(stopLoading());
  }
}

function* documentSagas() {
  yield takeLatest(getDocuments.request, getDocumentsSaga);
  yield takeLatest(getDocument.request, getDocumentSaga);
  yield takeLatest(createDocument.request, createDocumentSaga);
  yield takeLatest(updateDocument.request, updateDocumentSaga);
  yield takeLatest(deleteDocument.request, deleteDocumentSaga);
}

export default documentSagas;
