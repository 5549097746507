import { NameOfChildRoutes, NameOfRoutes, PERMISSION } from "@shared/constants";

export const APP_ROUTES_TO_PERMISSIONS: { [key: string]: string } = {
  [PERMISSION.VIEW_USERS]: NameOfRoutes.USERS,
  [PERMISSION.VIEW_COMPANIES as string]: NameOfRoutes.COMPANIES,
};

export const COMPANY_ROUTES_TO_PERMISSIONS: { [key: string]: string } = {
  [PERMISSION.VIEW_USERS]: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.USERS}`,
  [PERMISSION.VIEW_MEMBERS]: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.MEMBERS}`,
  [PERMISSION.VIEW_COMPANY_CREATED_ACTIVITY]: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ACTIVITIES}`,
  [PERMISSION.VIEW_COMPANY_CREATED_JSA]: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.JSA_LIST}`,
};

export const APP_LEVEL_PERMISSIONS: string[] = [PERMISSION.VIEW_USERS, PERMISSION.VIEW_COMPANIES];
export const COMPANY_LEVEL_PERMISSIONS: string[] = [
  PERMISSION.VIEW_USERS,
  PERMISSION.VIEW_MEMBERS,
  PERMISSION.VIEW_COMPANY_CREATED_ACTIVITY,
  PERMISSION.VIEW_COMPANY_CREATED_JSA,
];
