import { roleSchema } from "./role.schema";

export const userRoleSchema = {
  type: "object",
  required: ["role_id", "role", "status", "id"],
  properties: {
    id: { type: "number" },
    role_id: { type: "number" },
    status: { type: "string" },
    company: {
      type: ["object", "null"],
      required: ["code", "name"],
      properties: {
        code: { type: "string" },
        name: { type: "string" },
      },
    },
    role: roleSchema,
  },
  additionalProperties: false,
};
