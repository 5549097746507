import React from "react";
import { Note } from "@shared/models";

import { NoteItem } from "../NoteItem";

export interface NoteListProps {
  isDisabled: boolean;
  notes: Note[];
  onDelete: (note: Note) => void;
  permission?: string;
  companyCode?: string | null;
}

const NoteList = (props: NoteListProps) => {
  const { notes, onDelete, permission, companyCode, isDisabled } = props;
  return (
    <>
      {notes.map((note) => (
        <NoteItem
          note={note}
          key={note.id}
          onDelete={onDelete}
          permission={permission}
          companyCode={companyCode}
          isDisabled={isDisabled}
        />
      ))}
    </>
  );
};

export default NoteList;
