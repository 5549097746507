import { createAsyncAction, createAction } from "typesafe-actions";
import { CompanyListItem, Company } from "@shared/models";
import { PaginatedResponse, Filter } from "@shared/interfaces";
import {
  CompanyFormShape,
  CreateCompanySuccess,
  CompanyFormUpdateShape,
  UpdateStatusShape,
} from "@containers/Company/interface";

import { CompanyActionTypes } from "./constants";

export const getCompanies = createAsyncAction(
  CompanyActionTypes.GET_COMPANIES,
  CompanyActionTypes.GET_COMPANIES_SUCCESS,
  CompanyActionTypes.GET_COMPANIES_FAILURE,
)<Filter, PaginatedResponse<CompanyListItem>, Error>();

export const getCompany = createAsyncAction(
  CompanyActionTypes.GET_COMPANY,
  CompanyActionTypes.GET_COMPANY_SUCCESS,
  CompanyActionTypes.GET_COMPANY_FAILURE,
)<string, Company | null, Error>();

export const getCompanyToEdit = createAsyncAction(
  CompanyActionTypes.GET_COMPANY_TO_EDIT,
  CompanyActionTypes.GET_COMPANY_TO_EDIT_SUCCESS,
  CompanyActionTypes.GET_COMPANY_TO_EDIT_FAILURE,
)<string, Company | null, Error>();

export const createCompany = createAsyncAction(
  CompanyActionTypes.CREATE_COMPANY,
  CompanyActionTypes.CREATE_COMPANY_SUCCESS,
  CompanyActionTypes.CREATE_COMPANY_FAILURE,
)<CompanyFormShape, CreateCompanySuccess, Error>();

export const updateCompany = createAsyncAction(
  CompanyActionTypes.UPDATE_COMPANY,
  CompanyActionTypes.UPDATE_COMPANY_SUCCESS,
  CompanyActionTypes.UPDATE_COMPANY_FAILURE,
)<CompanyFormUpdateShape, CreateCompanySuccess, Error>();

export const updateCompanyStatus = createAsyncAction(
  CompanyActionTypes.UPDATE_COMPANY_STATUS,
  CompanyActionTypes.UPDATE_COMPANY_STATUS_SUCCESS,
  CompanyActionTypes.UPDATE_COMPANY_STATUS_FAILURE,
)<UpdateStatusShape, CreateCompanySuccess, Error>();

export const setFilter = createAction(CompanyActionTypes.SET_FILTER)<Filter | null>();
