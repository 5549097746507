export enum TrainingAndCertificateActionTypes {
  GET_TRAININGS = "@@TRAINING_AND_CERTIFICATE/GET_TRAININGS",
  GET_TRAININGS_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_TRAININGS_SUCCESS",
  GET_TRAININGS_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_TRAININGS_FAILURE",

  CLEAR_TRAININGS = "@@TRAINING_AND_CERTIFICATE/CLEAR_TRAININGS",
  SET_TRAINING_AND_CERTIFICATE_FILTER = "@@TRAINING_AND_CERTIFICATE/SET_TRAINING_AND_CERTIFICATE_FILTER",

  GET_TRAINING = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING",
  GET_TRAINING_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING_SUCCESS",
  GET_TRAINING_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING_FAILURE",

  CREATE_TRAINING = "@@TRAINING_AND_CERTIFICATE/CREATE_TRAINING",
  CREATE_TRAINING_SUCCESS = "@@TRAINING_AND_CERTIFICATE/CREATE_TRAINING_SUCCESS",
  CREATE_TRAINING_FAILURE = "@@TRAINING_AND_CERTIFICATE/CREATE_TRAINING_FAILURE",

  UPDATE_TRAINING = "@@TRAINING_AND_CERTIFICATE/UPDATE_TRAINING",
  UPDATE_TRAINING_SUCCESS = "@@TRAINING_AND_CERTIFICATE/UPDATE_TRAINING_SUCCESS",
  UPDATE_TRAINING_FAILURE = "@@TRAINING_AND_CERTIFICATE/UPDATE_TRAINING_FAILURE",

  UPDATE_TRAINING_STATUS = "@@TRAINING_AND_CERTIFICATE/UPDATE_TRAINING_STATUS",
  UPDATE_TRAINING_STATUS_SUCCESS = "@@TRAINING_AND_CERTIFICATE/UPDATE_TRAINING_STATUS_SUCCESS",
  UPDATE_TRAINING_STATUS_FAILURE = "@@TRAINING_AND_CERTIFICATE/UPDATE_TRAINING_STATUS_FAILURE",

  GET_CERTIFICATES = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES",
  GET_CERTIFICATES_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_SUCCESS",
  GET_CERTIFICATES_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_FAILURE",

  GET_CERTIFICATES_TEMPLATES = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_TEMPLATES",
  GET_CERTIFICATES_TEMPLATES_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_TEMPLATES_SUCCESS",
  GET_CERTIFICATES_TEMPLATES_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_TEMPLATES_FAILURE",

  GET_CERTIFICATES_ISSUED_BY = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_ISSUED_BY",
  GET_CERTIFICATES_ISSUED_BY_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_ISSUED_BY_SUCCESS",
  GET_CERTIFICATES_ISSUED_BY_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATES_ISSUED_BY_FAILURE",

  GET_CERTIFICATE = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATE",
  GET_CERTIFICATE_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATE_SUCCESS",
  GET_CERTIFICATE_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING_FAILURE",

  CREATE_CERTIFICATE = "@@TRAINING_AND_CERTIFICATE/CREATE_CERTIFICATE",
  CREATE_CERTIFICATE_SUCCESS = "@@TRAINING_AND_CERTIFICATE/CREATE_CERTIFICATE_SUCCESS",
  CREATE_CERTIFICATE_FAILURE = "@@TRAINING_AND_CERTIFICATE/CREATE_CERTIFICATE_FAILURE",

  UPDATE_CERTIFICATE = "@@TRAINING_AND_CERTIFICATE/UPDATE_CERTIFICATE",
  UPDATE_CERTIFICATE_SUCCESS = "@@TRAINING_AND_CERTIFICATE/UPDATE_CERTIFICATE_SUCCESS",
  UPDATE_CERTIFICATE_FAILURE = "@@TRAINING_AND_CERTIFICATE/UPDATE_CERTIFICATE_FAILURE",

  UPDATE_CERTIFICATE_STATUS = "@@TRAINING_AND_CERTIFICATE/UPDATE_CERTIFICATE_STATUS",
  UPDATE_CERTIFICATE_STATUS_SUCCESS = "@@TRAINING_AND_CERTIFICATE/UPDATE_CERTIFICATE_STATUS_SUCCESS",
  UPDATE_CERTIFICATE_STATUS_FAILURE = "@@TRAINING_AND_CERTIFICATE/UPDATE_CERTIFICATE_STATUS_FAILURE",

  GET_CERTIFICATE_FILES = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATE_FILES",
  GET_CERTIFICATE_FILES_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATE_FILES_SUCCESS",
  GET_CERTIFICATE_FILES_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_CERTIFICATE_FILES_FAILURE",

  ADD_CERTIFICATE_FILE = "@@TRAINING_AND_CERTIFICATE/ADD_CERTIFICATE_FILE",
  ADD_CERTIFICATE_FILE_SUCCESS = "@@TRAINING_AND_CERTIFICATE/ADD_CERTIFICATE_FILE_SUCCESS",
  ADD_CERTIFICATE_FILE_FAILURE = "@@TRAINING_AND_CERTIFICATE/ADD_CERTIFICATE_FILE_FAILURE",

  DELETE_CERTIFICATE_FILE = "@@TRAINING_AND_CERTIFICATE/DELETE_CERTIFICATE_FILE",
  DELETE_CERTIFICATE_FILE_SUCCESS = "@@TRAINING_AND_CERTIFICATE/DELETE_CERTIFICATE_FILE_SUCCESS",
  DELETE_CERTIFICATE_FILE_FAILURE = "@@TRAINING_AND_CERTIFICATE/DELETE_CERTIFICATE_FILE_FAILURE",

  CLEAR_CERTIFICATES = "@@TRAINING_AND_CERTIFICATE/CLEAR_CERTIFICATES",

  GET_TRAINING_PASS = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING_PASS",
  GET_TRAINING_PASS_SUCCESS = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING_PASS_SUCCESS",
  GET_TRAINING_PASS_FAILURE = "@@TRAINING_AND_CERTIFICATE/GET_TRAINING_PASS_FAILURE",

  PASS_TRAINING = "@@TRAINING_AND_CERTIFICATE/PASS_TRAINING",
  PASS_TRAINING_SUCCESS = "@@TRAINING_AND_CERTIFICATE/PASS_TRAINING_SUCCESS",
  PASS_TRAINING_FAILURE = "@@TRAINING_AND_CERTIFICATE/PASS_TRAINING_FAILURE",

  CONFIRM_TRAINING = "@@TRAINING_AND_CERTIFICATE/CONFIRM_TRAINING",
  CONFIRM_TRAINING_SUCCESS = "@@TRAINING_AND_CERTIFICATE/CONFIRM_TRAINING_SUCCESS",
  CONFIRM_TRAINING_FAILURE = "@@TRAINING_AND_CERTIFICATE/CONFIRM_TRAINING_FAILURE",

  SET_CLEAR_TRAINING_FORM = "@@TRAINING_AND_CERTIFICATE/SET_CLEAR_TRAINING_FORM",
}
