import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "@shared/constants";

import { JsaStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: JsaStateType = {
  jsa: [],
  jsaTotal: 0,
  currentJsa: null,
  currentJsaFiles: [],
  filter: defaultFilter,
  clearJsaForm: false,
};

const reducer = createReducer<JsaStateType, Action>(initialState)
  .handleAction(actions.getJsa.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.jsa = clear ? rows : [...nextState.jsa, ...rows];
      nextState.jsaTotal = count;
    }),
  )
  .handleAction(actions.getJsaItem.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentJsa = payload;
    }),
  )
  .handleAction(actions.updateJsa.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentJsa = payload.jsa;
    }),
  )
  .handleAction(actions.getJsaFiles.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentJsaFiles = action.payload;
    }),
  )
  .handleAction(actions.addJsaFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentJsaFiles = [action.payload.file, ...state.currentJsaFiles];
    }),
  )
  .handleAction(actions.deleteJsaFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentJsaFiles = state.currentJsaFiles.filter((f) => f.id !== action.payload.file_id);
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.setClearJsaForm, (state, action) =>
    produce(state, (nextState) => {
      nextState.clearJsaForm = action.payload;
    }),
  );

export { reducer as JsaReducer };
