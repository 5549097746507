import React from "react";
import * as Yup from "yup";
import { FieldItem } from "@shared/components/FormGenerator";
import { FieldItemType, RenderTypes } from "@shared/interfaces";
import { Assignment, AssignmentStatus, Project, ProjectStatus } from "@shared/models";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { actions as projectActions, selectors as projectSelectors } from "@containers/Project/store";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { AssignmentFormShape, AssignmentFormShapeToRequest } from "@containers/Assignment/interface";
import { getDuration, convertDateToEstTz, getDateWithTimezone } from "@shared/utils";
import { CustomMenuList } from "@shared/components/Common/CustomMenuList";
import moment from "moment";

interface GetFieldsOptions {
  isDisabledFields?: boolean;
  onChangeStartTime: (newValue: Date | null, isDate: boolean) => void;
  onChangeEndTime: (newValue: Date | null, isDate: boolean) => void;
  onChangeAddress: (data: { label: string; value: string }) => void;
  onChangeAddressInput: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProject: (value: any, originObject: any) => void;
  onAddNewProject: () => void;
  currentAssignment: Assignment | null;
}

const commonValidationSchema = {
  assignment_activities: Yup.array()
    .test("activities-unique", "23455", function (activities) {
      if (activities) {
        for (const activity of activities) {
          if (activity && activities.filter((item) => item && item.activity.id === activity.activity.id).length > 1) {
            return false;
          }
        }
      }
      return true;
    })
    .test("activities-min", "At least 1 activity required", function (activities) {
      return Boolean(activities && activities.filter((item) => item).length);
    }),
  needRedirect: Yup.boolean().optional(),
  status: Yup.string().optional(),
  project_id: Yup.number().nullable(),
  description: Yup.string().max(500).nullable(),
  location: Yup.object()
    .test("location", FORM_ERROR_MESSAGES.REQUIRED, function (location) {
      return (location && location.value && location.label) || this.parent.location_place_id;
    })
    .nullable(),
  start_date: Yup.date()
    .typeError(FORM_ERROR_MESSAGES.REQUIRED)
    .test("end_date-min", "Start date cannot be after the end date", function (end_date) {
      if (!this.parent.start_date || !this.parent.start_time || !end_date || !this.parent.end_time) return false;

      return (
        moment(combineTimeAndDate(this.parent.start_date, this.parent.start_time)) <
        moment(combineTimeAndDate(this.parent.end_date, this.parent.end_time))
      );
    }),
  start_time: Yup.date().typeError(FORM_ERROR_MESSAGES.REQUIRED),
  end_date: Yup.date().typeError(FORM_ERROR_MESSAGES.REQUIRED),
  end_time: Yup.date().typeError(FORM_ERROR_MESSAGES.REQUIRED),
  certificate_templates: Yup.array(),
};

const prepareProjectOptionFunction = (item: Project) => {
  return { label: `${item.name} (${item.location?.address || ""})`, value: item.id || null };
};

export const publishValidationSchema = Yup.object().shape({
  ...commonValidationSchema,
  members: Yup.array().min(1, FORM_ERROR_MESSAGES.REQUIRED),
});

export const draftValidationSchema = Yup.object().shape({
  ...commonValidationSchema,
});

export const getFields = (options: GetFieldsOptions) => {
  const {
    isDisabledFields,
    onChangeStartTime,
    onChangeEndTime,
    onChangeAddress,
    onChangeProject,
    onAddNewProject,
    onChangeAddressInput,
    currentAssignment,
  } = options;

  const fields: FieldItem[] = [
    {
      type: FieldItemType.AUTOCOMPLETE_SELECT,
      name: "project_id",
      label: "Project",
      wrapperClass: "input-line project-options",
      disabled: isDisabledFields,
      onFieldChange: onChangeProject,
      initialValue: currentAssignment?.project
        ? { value: currentAssignment.project.id, label: currentAssignment.project.name }
        : undefined,
      options: [],
      menuWrapper: (props) => (
        <CustomMenuList
          onMenuScrollToBottom={props.selectProps.onMenuScrollToBottom}
          baseProps={props}
          onAddClick={onAddNewProject}
          addText="+ Create New Project"
        />
      ),
      isClearable: true,
    },
    {
      type: FieldItemType.AUTOCOMPLETE_INPUT,
      loadingAction: sharedActions.getAddress,
      name: "location",
      wrapperClass: "input-line",
      label: "Address *",
      disabled: isDisabledFields,
      onValueSelect: onChangeAddress,
      noMatchesLabel: "No matches found",
      loadOnFocus: true,
      onChangeField: onChangeAddressInput,
    },
    {
      type: FieldItemType.RENDER,
      renderType: RenderTypes.FIELD_LIST_LINE,
      wrapperClass: "form-row dates-row",
      name: "form-line1",
      fields: [
        {
          type: FieldItemType.DATEPICKER,
          name: "start_date",
          label: "Start Date *",
          disabled: isDisabledFields,
          disablePast: !currentAssignment,
          placeholder: "mm/dd/yyyy",
          onChange: (date) => onChangeStartTime(date, true),
        },
        {
          type: FieldItemType.TIMEPICKER,
          name: "start_time",
          label: "Start Time (EST) *",
          disabled: isDisabledFields,
          onChange: (date) => onChangeStartTime(date, false),
          format: "hh:mm aa",
        },
        {
          type: FieldItemType.DATEPICKER,
          name: "end_date",
          label: "End Date *",
          disabled: isDisabledFields,
          disablePast: !currentAssignment,
          onChange: (date) => onChangeEndTime(date, true),
          placeholder: "mm/dd/yyyy",
        },
        {
          type: FieldItemType.TIMEPICKER,
          name: "end_time",
          label: "End Time (EST) *",
          disabled: isDisabledFields,
          onChange: (date) => onChangeEndTime(date, false),
          format: "hh:mm aa",
        },
        {
          type: FieldItemType.BLOCK,
          name: "duration",
          content: "Duration",
          placeholder: "00:00h",
        },
      ],
    },
    {
      type: FieldItemType.TEXTAREA,
      name: "description",
      label: "Description",
      disabled: isDisabledFields,
      placeholder: "",
      wrapperClass: "input-line",
      rows: 10,
    },
  ];
  return fields;
};

export const getInitValues = (assignment: Assignment | null): AssignmentFormShape => ({
  status: AssignmentStatus.Scheduled,
  project_id: assignment?.project?.id || null,
  description: assignment?.description || "",
  location: assignment?.location.address || "",
  members: assignment ? assignment.members : [],
  start_date: assignment ? convertDateToEstTz(assignment.start_date) : null,
  start_time: assignment ? convertDateToEstTz(assignment.start_date) : null,
  end_date: assignment ? convertDateToEstTz(assignment.end_date) : null,
  end_time: assignment ? convertDateToEstTz(assignment.end_date) : null,
  duration: assignment ? getDuration(assignment.start_date, assignment.end_date) : null,
  assignment_activities: assignment ? assignment.assignment_activities : [null],
  location_place_id: assignment?.location.place_id || "",
  certificate_templates: assignment?.certificate_templates || [null],
});

export const handlers = (companyCode?: string | null) => ({
  project_id: {
    getData: (search: string, page?: number) =>
      projectActions.getProjects.request({
        page: page || 0,
        limit: 10,
        search,
        company_code: companyCode,
        status: ProjectStatus.Active,
        extend: true,
      }),
    selectData: projectSelectors.getProjects,
    selectTotalCount: projectSelectors.getProjectsTotal,
    prepareOptionFunction: prepareProjectOptionFunction,
  },
  location: {
    getData: sharedActions.getAddress.request,
    clearData: sharedActions.getAddress.success,
    selectData: sharedSelectors.getAddress,
    prepareOptionFunction: (item: { address: string; place_id: string }) => {
      return { label: item.address, value: item.place_id };
    },
  },
});

export const combineTimeAndDate = (date: Date | null, time: Date | null) => {
  const startDateString = moment(date).format("YYYY-MM-DD");
  const startTimeString = moment(time).format("HH:mm");
  return moment(`${startDateString} ${startTimeString}`).toDate();
};

export const convertFormData = (data: AssignmentFormShape, companyCode?: string): AssignmentFormShapeToRequest => {
  const startDateString = moment(data.start_date).format("YYYY-MM-DD");
  const startTimeString = moment(data.start_time).format("HH:mm");
  const endDateString = moment(data.end_date).format("YYYY-MM-DD");
  const endTimeString = moment(data.end_time).format("HH:mm");

  return {
    activities: data.assignment_activities
      .filter((item) => item)
      .map((item) => ({
        activity_id: item?.activity.id || 0,
        jsa: item?.jsa.map((jsaItem) => Number(jsaItem?.id)).filter((j) => !!j) || [],
      }))
      .filter((a) => a.activity_id && a.jsa.length),
    company_code: companyCode,
    start_date: getDateWithTimezone(`${startDateString} ${startTimeString}`),
    end_date: getDateWithTimezone(`${endDateString} ${endTimeString}`),
    location_place_id: data.location_place_id,
    members: data.members.map((m) => m.id),
    description: data.description,
    project_id: data.project_id || null,
    status: data.status,
    certificate_templates: data.certificate_templates.filter((c) => c).map((c) => c?.id || 0),
  };
};
