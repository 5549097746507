import { baseEntitySchema, getPaginatedResponseSchema } from "./shared.schema";

export const companyForUserListSchema = {
  type: "object",
  required: ["code", "name"],
  properties: {
    code: { type: "number" },
    name: { type: "string" },
  },
  additionalProperties: false,
};

const commonCompanyProperties = {
  code: { type: "string" },
  name: { type: "string" },
  created_at: { type: "string" },
  status: { type: "string" },
  contact_first_name: { type: "string" },
  contact_last_name: { type: "string" },
};

const companyForCompanyListSchema = {
  type: "object",
  required: ["name", "users_count", "created_at", "status", "contact_first_name", "contact_last_name"],
  properties: {
    ...commonCompanyProperties,
    users_count: { type: "number" },
  },
  additionalProperties: false,
};

export const fullCompanySchema = {
  type: "object",
  required: [
    "code",
    "name",
    "created_at",
    "status",
    "contact_first_name",
    "contact_last_name",
    "contact_email",
    "industry",
  ],
  properties: {
    ...commonCompanyProperties,
    address1: { type: ["string", "null"] },
    address2: { type: ["string", "null"] },
    state: { type: ["string", "null"] },
    zip: { type: ["string", "null"] },
    country: { type: ["string", "null"] },
    phone: { type: ["string", "null"] },
    contact_email: { type: "string" },
    contact_phone: { type: ["string", "null"] },
    contact_job: {
      ...baseEntitySchema,
      type: ["object", "null"],
    },
    industry: baseEntitySchema,
    logo: {
      type: ["object", "null"],
      properties: {
        id: { type: "number" },
        name: { type: "string" },
        created_at: { type: "string" },
        url: { type: "string" },
      },
    },
    owner_user_id: { type: ["number", "null"] },
  },
  additionalProperties: false,
};

const baseUpdateCompany = {
  name: { type: "string" },
  contact_first_name: { type: "string" },
  contact_last_name: { type: "string" },
  address1: { type: "string" },
  address2: { type: "string" },
  state: { type: "string" },
  zip: { type: "string" },
  country: { type: "string" },
  phone: { type: "string" },
  contact_email: { type: "string" },
  contact_phone: { type: "string" },
  contact_job_id: { type: "number" },
  industry_id: { type: "number" },
};

export const getCompaniesResponseSchema = getPaginatedResponseSchema(companyForCompanyListSchema);

export const createCompanyRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: [
    "name",
    "contact_first_name",
    "contact_last_name",
    "contact_email",
    "address1",
    "zip",
    "country",
    "contact_job_id",
    "industry_id",
  ],
  properties: {
    ...baseUpdateCompany,
    is_sent_invitation: { type: "boolean" },
  },
};

export const updateCompanyRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: [
    "name",
    "contact_first_name",
    "contact_last_name",
    "contact_email",
    "address1",
    "zip",
    "country",
    "contact_job_id",
    "industry_id",
  ],
  properties: {
    ...baseUpdateCompany,
  },
};

export const updateCompanyStatusRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["status"],
  properties: {
    status: { type: "string" },
  },
};

export const updateCompanySettingsRequestSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    logo_base64: { type: ["string", "null"] },
    is_delete_logo: { type: ["boolean", "null"] },
    ...baseUpdateCompany,
  },
};

export const commonCompanyResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "company"],
  properties: {
    message: { type: "string" },
    company: fullCompanySchema,
  },
};
