export enum UserActionTypes {
  GET_USERS = "@@USER/GET_USERS",
  GET_USERS_SUCCESS = "@@USER/GET_USERS_SUCCESS",
  GET_USERS_FAILURE = "@@USER/GET_USERS_FAILURE",

  GET_USER = "@@USER/GET_USER",
  GET_USER_SUCCESS = "@@USER/GET_USER_SUCCESS",
  GET_USER_FAILURE = "@@USER/GET_USER_FAILURE",

  CREATE_USER = "@@USER/CREATE_USER",
  CREATE_USER_SUCCESS = "@@USER/CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE = "@@USER/CREATE_USER_FAILURE",

  UPDATE_USER = "@@USER/UPDATE_USER",
  UPDATE_USER_SUCCESS = "@@USER/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "@@USER/UPDATE_USER_FAILURE",

  UPDATE_USER_STATUS = "@@USER/UPDATE_USER_STATUS",
  UPDATE_USER_STATUS_SUCCESS = "@@USER/UPDATE_USER_STATUS_SUCCESS",
  UPDATE_USER_STATUS_FAILURE = "@@USER/UPDATE_USER_STATUS_FAILURE",

  CHECK_USER_EXISTS = "@@USER/CHECK_USER_EXISTS",
  CHECK_USER_EXISTS_SUCCESS = "@@USER/CHECK_USER_EXISTS_SUCCESS",
  CHECK_USER_EXISTS_FAILURE = "@@USER/CHECK_USER_EXISTS_FAILURE",

  CLEAR_USERS = "@@USER/CLEAR_USERS",

  SET_FILTER = "@@USER/SET_FILTER",
}
