import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";

export interface FormCardProps {
  children: React.ReactNode;
  className?: string;
}

const FormCard: FC<FormCardProps> = (props) => {
  const { children, className } = props;

  return <div className={classnames("form-card", className)}>{children}</div>;
};

export default FormCard;
