import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "@shared/constants";
import { ORDER_TYPE } from "@shared/interfaces";
import { TeamStatus } from "@shared/models";

import * as actions from "./actions";
import { TeamStateType } from "../interface";

type Action = ActionType<typeof actions>;

export const initialState: TeamStateType = {
  teams: [],
  teamsTotal: 0,
  currentTeam: null,
  filter: {
    ...defaultFilter,
    sort_by: ["name"],
    sort_order: ORDER_TYPE.ASC,
  },
};

const reducer = createReducer<TeamStateType, Action>(initialState)
  .handleAction(actions.getTeams.success, (state, action) =>
    produce(state, (nextState) => {
      const { count, rows, clear } = action.payload;
      nextState.teams = clear ? rows : [...nextState.teams, ...rows];
      nextState.teamsTotal = count;
    }),
  )
  .handleAction(actions.getTeam.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentTeam = action.payload;
    }),
  )
  .handleAction(actions.createTeam.success, (state, action) =>
    produce(state, (nextState) => {
      const { team } = action.payload;
      nextState.teams = [team, ...nextState.teams];
      nextState.teamsTotal = nextState.teamsTotal + 1;
    }),
  )
  .handleAction(actions.updateTeam.success, (state, action) =>
    produce(state, (nextState) => {
      const { team } = action.payload;
      const index = nextState.teams.findIndex((t) => t.id === team.id);
      if (index >= 0) {
        nextState.teams[index] = team;
      }
      if (state.currentTeam && state.currentTeam.id === team.id) {
        nextState.currentTeam = team;
      }
    }),
  )
  .handleAction(actions.deleteTeam.success, (state, action) =>
    produce(state, (nextState) => {
      const { team_id } = action.payload;
      const index = nextState.teams.findIndex((t) => t.id === team_id);
      if (index >= 0) {
        nextState.teams[index] = {
          ...nextState.teams[index],
          status: TeamStatus.Archived,
        };
      }
      if (nextState.currentTeam && nextState.currentTeam.id === team_id) {
        nextState.currentTeam = {
          ...nextState.currentTeam,
          status: TeamStatus.Archived,
        };
      }
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  );

export { reducer as TeamReducer };
