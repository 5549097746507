import { optionToValue, request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CodeWithId, CreateFileShape, BaseFilter } from "@shared/interfaces";

import {
  TrainingAndCertificateFilter,
  TrainingFormShapeToRequest,
  UpdateTrainingStatusShape,
  TrainingPassFilter,
  TrainingPassPayload,
  CertificateFormShapeToRequest,
  UpdateCertificateStatusShape,
  DeleteCertificateFileShape,
  CertificateTemplatesFilter,
} from "../interface";

export default {
  getTrainings: (payload: TrainingAndCertificateFilter) => {
    const { company_code, ...restPayload } = payload;

    const params = {
      ...restPayload,
      activities: restPayload.activities?.map(optionToValue),
    };

    return request(METHODS.GET, API.TRAINING.GET_TRAININGS(company_code ?? ""))({ params });
  },
  getTraining: (payload: Required<CodeWithId>) => {
    return request(METHODS.GET, API.TRAINING.GET_TRAINING(payload.code, payload.id))();
  },
  createTraining: (payload: TrainingFormShapeToRequest) => {
    const { company_code, ...trainingPayload } = payload;
    return request(METHODS.POST, API.TRAINING.CREATE_TRAINING(company_code || ""))(trainingPayload);
  },
  updateTraining: (payload: Required<CodeWithId<TrainingFormShapeToRequest>>) => {
    return request(METHODS.PUT, API.TRAINING.UPDATE_TRAINING(payload.code, payload.id))(payload.payload);
  },
  updateTrainingStatus: ({ code, id, payload }: Required<CodeWithId<UpdateTrainingStatusShape>>) => {
    return request(METHODS.PUT, API.TRAINING.UPDATE_TRAINING_STATUS(code, id))(payload);
  },
  getCertificates: (payload: TrainingAndCertificateFilter) => {
    const { company_code, ...restPayload } = payload;

    const params = {
      ...restPayload,
      activities: restPayload.activities?.map(optionToValue),
    };

    return request(METHODS.GET, API.CERTIFICATE.GET_CERTIFICATES(company_code ?? ""))({ params });
  },
  getCertificatesTemplates: (payload: CertificateTemplatesFilter) => {
    const { company_code, ...restPayload } = payload;

    return request(METHODS.GET, API.CERTIFICATE.GET_CERTIFICATE_TEMPLATES(company_code ?? ""))({ params: restPayload });
  },
  getCertificatesIssuedBy: (payload: BaseFilter) => {
    const { company_code, ...restPayload } = payload;
    return request(
      METHODS.GET,
      API.CERTIFICATE.GET_CERTIFICATES_ISSUED_BY(company_code ?? ""),
    )({ params: restPayload });
  },
  getCertificate: ({ code, id }: Required<CodeWithId>) => {
    return request(METHODS.GET, API.CERTIFICATE.GET_CERTIFICATE(code, id))();
  },
  createCertificate: ({ code, payload }: CodeWithId<CertificateFormShapeToRequest>) => {
    return request(METHODS.POST, API.CERTIFICATE.CREATE_CERTIFICATE(code || ""))(payload);
  },
  updateCertificate: ({ code, id, payload }: Required<CodeWithId<CertificateFormShapeToRequest>>) => {
    return request(METHODS.PUT, API.CERTIFICATE.UPDATE_CERTIFICATE(code, id))(payload);
  },
  getCertificateFiles: ({ code, id }: Required<CodeWithId>) => {
    return request(METHODS.GET, API.CERTIFICATE.GET_CERTIFICATE_FILES(code, id))();
  },
  addCertificateFile: ({ code, id, payload }: Required<CodeWithId<CreateFileShape>>) => {
    return request(METHODS.POST, API.CERTIFICATE.ADD_CERTIFICATE_FILE(code, id))(payload);
  },
  deleteCertificateFile: ({ code, id: file_id, certificate_id }: DeleteCertificateFileShape) => {
    return request(METHODS.DELETE, API.CERTIFICATE.DELETE_CERTIFICATE_FILE(code, certificate_id, file_id))();
  },
  updateCertificateStatus: ({ code, id, payload }: Required<CodeWithId<UpdateCertificateStatusShape>>) => {
    return request(METHODS.PUT, API.CERTIFICATE.UPDATE_CERTIFICATE_STATUS(code, id))(payload);
  },
  getTrainingPass: (payload: Required<CodeWithId<TrainingPassFilter>>) => {
    return request(METHODS.GET, API.TRAINING.GET_TRAINING_PASS(payload.code, payload.id))({ params: payload.payload });
  },
  passTraining: (payload: Required<CodeWithId<TrainingPassPayload>>) => {
    return request(METHODS.POST, API.TRAINING.PASS_TRAINING(payload.code, payload.id))(payload.payload);
  },
  confirmTraining: (payload: Required<CodeWithId<TrainingPassPayload>>) => {
    return request(METHODS.POST, API.TRAINING.CONFIRM_TRAINING(payload.code, payload.id))(payload.payload);
  },
};
