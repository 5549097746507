import { User, File } from ".";

export enum DocumentType {
  FILE = "File",
  LINK = "Link",
}

export enum DocumentStatus {
  Active = "Active",
  Archived = "Archived",
}
export interface Document {
  id: number;
  name: string;
  link_url?: string;
  file?: File;
  creator?: Partial<User>;
  created_at: Date;
  status?: DocumentStatus;
  type?: string;
}
