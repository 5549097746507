import React from "react";
import classnames from "classnames";
import { useLayer, useHover, Arrow } from "react-laag";
import "./index.scss";

interface TooltipProps {
  children: React.ReactNode;
  content?: string;
  forSidebar?: boolean;
}

function Tooltip({ children, content, forSidebar }: TooltipProps) {
  const [isOver, hoverProps] = useHover();

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    auto: true,
    placement: forSidebar ? "right-end" : "top-center",
  });

  return (
    <>
      <span {...triggerProps} {...hoverProps} className="tooltip-top-level">
        {children}
      </span>
      {isOver &&
        renderLayer(
          <div className={classnames("tooltip", { "sidebar-tooltip": forSidebar })} {...layerProps}>
            {content}
            {!forSidebar && <Arrow {...arrowProps} backgroundColor="#292929e3" />}
          </div>,
        )}
    </>
  );
}

export default Tooltip;
