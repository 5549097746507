import React from "react";
import "./index.scss";
import classNames from "classnames";

export interface TabBarNav {
  label: string;
  className?: string;
  onChangeActiveTab: (label: string) => void;
}

const TabBarNav: React.FC<TabBarNav> = (props) => {
  const { label, className, onChangeActiveTab } = props;
  const classes = classNames("tab-nav-item", className);

  return (
    <button className={classes} onClick={() => onChangeActiveTab(label)}>
      {label}
    </button>
  );
};

export default TabBarNav;
