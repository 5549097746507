import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CreateFileShape, Filter, PayloadWithId } from "@shared/interfaces";
import {
  DeleteTaskFileShape,
  TaskFormShapeToRequest,
  UpdateTaskStatusShape,
  TaskImportShape,
} from "@containers/Task/interface";

export default {
  getTasks: (tasksFilter: Filter) => {
    return request(METHODS.GET, API.TASK.GET_TASKS)({ params: tasksFilter });
  },
  getTask: (payload: Required<{ id: string | number }>) => {
    return request(METHODS.GET, API.TASK.GET_TASK(payload.id))();
  },
  createTask: (payload: TaskFormShapeToRequest) => request(METHODS.POST, API.TASK.CREATE_TASK)(payload),
  updateTask: (payload: PayloadWithId<TaskFormShapeToRequest>) => {
    return request(METHODS.PUT, API.TASK.UPDATE_TASK(payload.id))(payload.payload);
  },
  updateTaskStatus: (payload: Required<PayloadWithId<UpdateTaskStatusShape>>) => {
    return request(METHODS.PUT, API.TASK.UPDATE_TASK_STATUS(payload.id))(payload.payload);
  },
  getTaskFiles: (payload: Required<{ id: string | number }>) => {
    return request(METHODS.GET, API.TASK.GET_TASK_FILES(payload.id))();
  },
  addTaskFile: (payload: Required<PayloadWithId<CreateFileShape>>) => {
    return request(METHODS.POST, API.TASK.ADD_TASK_FILE(payload.id))(payload.payload);
  },
  deleteTaskFile: (payload: DeleteTaskFileShape) => {
    return request(METHODS.DELETE, API.TASK.DELETE_TASK_FILE(payload.task_id, payload.id))();
  },
  importTask: (payload: TaskImportShape) => request(METHODS.POST, API.TASK.IMPORT_TASK)(payload),
};
