export enum MemberActionTypes {
  GET_MEMBERS = "@@MEMBER/GET_MEMBERS",
  GET_MEMBERS_SUCCESS = "@@MEMBER/GET_MEMBERS_SUCCESS",
  GET_MEMBERS_FAILURE = "@@MEMBER/GET_MEMBERS_FAILURE",

  GET_MEMBER_CERTIFICATES = "@@MEMBER/GET_MEMBER_CERTIFICATES",
  GET_MEMBER_CERTIFICATES_SUCCESS = "@@MEMBER/GET_MEMBER_CERTIFICATES_SUCCESS",
  GET_MEMBER_CERTIFICATES_FAILURE = "@@MEMBER/GET_MEMBER_CERTIFICATES_FAILURE",

  GET_MEMBER_JSA = "@@MEMBER/GET_MEMBER_JSA",
  GET_MEMBER_JSA_SUCCESS = "@@MEMBER/GET_MEMBER_JSA_SUCCESS",
  GET_MEMBER_JSA_FAILURE = "@@MEMBER/GET_MEMBER_JSA_FAILURE",

  SET_TRAINING_AND_CERTIFICATE_MEMBER_FILTER = "@@MEMBER/SET_TRAINING_AND_CERTIFICATE_MEMBER_FILTER",

  GET_MEMBER = "@@MEMBER/GET_MEMBER",
  GET_MEMBER_SUCCESS = "@@MEMBER/GET_MEMBER_SUCCESS",
  GET_MEMBER_FAILURE = "@@MEMBER/GET_MEMBER_FAILURE",

  CREATE_MEMBER = "@@MEMBER/CREATE_MEMBER",
  CREATE_MEMBER_SUCCESS = "@@MEMBER/CREATE_MEMBER_SUCCESS",
  CREATE_MEMBER_FAILURE = "@@MEMBER/CREATE_MEMBER_FAILURE",

  UPDATE_MEMBER = "@@MEMBER/UPDATE_MEMBER",
  UPDATE_MEMBER_SUCCESS = "@@MEMBER/UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILURE = "@@MEMBER/UPDATE_MEMBER_FAILURE",

  UPDATE_MEMBER_STATUS = "@@MEMBER/UPDATE_MEMBER_STATUS",
  UPDATE_MEMBER_STATUS_SUCCESS = "@@MEMBER/UPDATE_MEMBER_STATUS_SUCCESS",
  UPDATE_MEMBER_STATUS_FAILURE = "@@MEMBER/UPDATE_MEMBER_STATUS_FAILURE",

  GET_MEMBER_FILES = "@@MEMBER/GET_MEMBER_FILES",
  GET_MEMBER_FILES_SUCCESS = "@@MEMBER/GET_MEMBER_FILES_SUCCESS",
  GET_MEMBER_FILES_FAILURE = "@@MEMBER/GET_MEMBER_FILES_FAILURE",

  ADD_MEMBER_FILE = "@@MEMBER/ADD_MEMBER_FILE",
  ADD_MEMBER_FILE_SUCCESS = "@@MEMBER/ADD_MEMBER_FILE_SUCCESS",
  ADD_MEMBER_FILE_FAILURE = "@@MEMBER/ADD_MEMBER_FILE_FAILURE",

  DELETE_MEMBER_FILE = "@@MEMBER/DELETE_MEMBER_FILE",
  DELETE_MEMBER_FILE_SUCCESS = "@@MEMBER/DELETE_MEMBER_FILE_SUCCESS",
  DELETE_MEMBER_FILE_FAILURE = "@@MEMBER/DELETE_MEMBER_FILE_FAILURE",

  GET_MEMBER_NOTES = "@@MEMBER/GET_MEMBER_NOTES",
  GET_MEMBER_NOTES_SUCCESS = "@@MEMBER/GET_MEMBER_NOTES_SUCCESS",
  GET_MEMBER_NOTES_FAILURE = "@@MEMBER/GET_MEMBER_NOTES_FAILURE",

  ADD_MEMBER_NOTE = "@@MEMBER/ADD_MEMBER_NOTE",
  ADD_MEMBER_NOTE_SUCCESS = "@@MEMBER/ADD_MEMBER_NOTE_SUCCESS",
  ADD_MEMBER_NOTE_FAILURE = "@@MEMBER/ADD_MEMBER_NOTE_FAILURE",

  DELETE_MEMBER_NOTE = "@@MEMBER/DELETE_MEMBER_NOTE",
  DELETE_MEMBER_NOTE_SUCCESS = "@@MEMBER/DELETE_MEMBER_NOTE_SUCCESS",
  DELETE_MEMBER_NOTE_FAILURE = "@@MEMBER/DELETE_MEMBER_NOTE_FAILURE",

  CLEAR_MEMBERS = "@@MEMBER/CLEAR_MEMBERS",

  SET_FILTER = "@@MEMBER/SET_FILTER",

  GET_MEMBER_TRAININGS = "@@MEMBER/GET_MEMBER_TRAININGS",
  GET_MEMBER_TRAININGS_SUCCESS = "@@MEMBER/GET_MEMBER_TRAININGS_SUCCESS",
  GET_MEMBER_TRAININGS_FAILURE = "@@MEMBER/GET_MEMBER_TRAININGS_FAILURE",

  SET_MEMBER_SIGNATURE = "@@MEMBER/SET_MEMBER_SIGNATURE",
  SET_MEMBER_SIGNATURE_SUCCESS = "@@MEMBER/SET_MEMBER_SIGNATURE_SUCCESS",
  SET_MEMBER_SIGNATURE_FAILURE = "@@MEMBER/SET_MEMBER_SIGNATURE_FAILURE",

  DELETE_MEMBER_SIGNATURE = "@@MEMBER/DELETE_MEMBER_SIGNATURE",
  DELETE_MEMBER_SIGNATURE_SUCCESS = "@@MEMBER/DELETE_MEMBER_SIGNATURE_SUCCESS",
  DELETE_MEMBER_SIGNATURE_FAILURE = "@@MEMBER/DELETE_MEMBER_SIGNATURE_FAILURE",

  ADD_CERTIFICATE = "@@MEMBER/ADD_CERTIFICATE",
  UPDATE_CERTIFICATE = "@@MEMBER/UPDATE_CERTIFICATE",

  GET_MEMBER_PASSED_JSA = "@@MEMBER/GET_MEMBER_PASSED_JSA",
  GET_MEMBER_PASSED_JSA_SUCCESS = "@@MEMBER/GET_MEMBER_PASSED_JSA_SUCCESS",
  GET_MEMBER_PASSED_JSA_FAILURE = "@@MEMBER/GET_MEMBER_PASSED_JSA_FAILURE",
}
