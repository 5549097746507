import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CodeWithId, Filter, PayloadWithCode } from "@shared/interfaces";

import { AlertCreateUpdateShapeToRequest } from "../interface";

export default {
  getAlerts: ({ company_code, ...params }: Filter) => {
    return request(METHODS.GET, API.ALERT.GET_ALERTS(company_code ?? ""))({ params });
  },
  getAlert: ({ code, id }: Required<CodeWithId>) => request(METHODS.GET, API.ALERT.GET_ALERT(code, id))(),
  createAlert: ({ code, payload }: PayloadWithCode<AlertCreateUpdateShapeToRequest>) =>
    request(METHODS.POST, API.ALERT.CREATE_ALERT(code))(payload),
  updateAlert: ({ code, id, payload }: Required<CodeWithId<AlertCreateUpdateShapeToRequest>>) =>
    request(METHODS.PUT, API.ALERT.UPDATE_ALERT(code, id))(payload),
  deleteAlert: ({ code, id }: Required<CodeWithId>) => request(METHODS.DELETE, API.ALERT.DELETE_ALERT(code, id))(),
};
