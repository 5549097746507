import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, InputFile } from "@shared/components";
import { MAX_AVATAR_SIZE } from "@shared/constants";
import { actions } from "@shared/store";
import classnames from "classnames";

import { ImageFileProps } from "../ImageMultiSelect";

import "./index.scss";

interface ImageModule {
  default: string;
}

function importAll(r: __WebpackModuleApi.RequireContext) {
  return r
    .keys()
    .map(r)
    .map((m) => (m as ImageModule).default);
}

let images: string[] = [];

if (process.env.REACT_APP_ENV !== "test") {
  images = importAll(require.context("@assets/files/images/templates", false, /\.(png|jpe?g|svg)$/));
}

export interface ImageMultiSelectModalProps {
  onSave: (image: ImageFileProps) => void;
  onClose: () => void;
}

const ImageMultiSelectModal: FC<ImageMultiSelectModalProps> = (props: ImageMultiSelectModalProps) => {
  const dispatch = useDispatch();
  const { onClose, onSave } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedIndexImage, setSelectedIndexImage] = useState<number | null>(null);

  const handleAddCustomFile = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        actions.showNotification({
          message,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  const localOnSave = useCallback(
    (file: ImageFileProps) => {
      onSave(file);
      onClose();
    },
    [onSave, onClose],
  );

  const onImageClick = useCallback((index: number) => {
    setSelectedIndexImage(index);
  }, []);

  const renderImagesTemplates = useMemo(() => {
    return images.map((image, index) => {
      return (
        <div
          key={index}
          className={classnames("image-select-template", { selected: index === selectedIndexImage })}
          onClick={onImageClick.bind(null, index)}>
          <img src={image as string} id={`template-image-${index}`} />
        </div>
      );
    });
  }, [onImageClick, selectedIndexImage]);

  const onSaveTemplateImage = useCallback(() => {
    const imageTag = document.getElementById(`template-image-${selectedIndexImage}`) as CanvasImageSource;
    const canvas = document.createElement("canvas");
    canvas.width = 35;
    canvas.height = 35;
    const ctx = canvas.getContext("2d");
    if (imageTag) {
      ctx?.drawImage(imageTag, 0, 0, 35, 35);
    }
    localOnSave({ base64: canvas.toDataURL(), name: "File Name" });
  }, [selectedIndexImage, localOnSave]);

  return (
    <div className="image-select-modal-wrapper">
      <div className="image-select-modal-header">
        <div className="image-select-modal-header-title">Add Image</div>
        <div className="image-select-modal-header-close" onClick={onClose}>
          <Icon type="clear" />
        </div>
      </div>
      <div className="image-select-modal-content">
        {renderImagesTemplates}
        <InputFile
          maxFileSize={MAX_AVATAR_SIZE}
          onReject={displayFileRejectMessage}
          accept="image/png,image/jpg,image/jpeg"
          onChange={localOnSave}
          inputRef={inputRef}
        />
      </div>
      <div className="image-select-modal-footer">
        <div className={"image-select-upoad"} onClick={handleAddCustomFile}>
          <Icon type="upload" />
          <div>Upload image from Computer</div>
        </div>
        <Button
          type="button"
          className="image-select-modal-save"
          disabled={selectedIndexImage === null}
          onClick={onSaveTemplateImage}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ImageMultiSelectModal;
