export enum AlertActionTypes {
  GET_ALERTS = "@@ALERT/GET_ALERTS",
  GET_ALERTS_SUCCESS = "@@ALERT/GET_ALERTS_SUCCESS",
  GET_ALERTS_FAILURE = "@@ALERT/GET_ALERTS_FAILURE",

  CLEAR_ALERTS = "@@ALERT/CLEAR_ALERTS",

  GET_ALERT = "@@ALERT/GET_ALERT",
  GET_ALERT_SUCCESS = "@@ALERT/GET_ALERT_SUCCESS",
  GET_ALERT_FAILURE = "@@ALERT/GET_ALERT_FAILURE",

  CREATE_ALERT = "@@ALERT/CREATE_ALERT",
  CREATE_ALERT_SUCCESS = "@@ALERT/CREATE_ALERT_SUCCESS",
  CREATE_ALERT_FAILURE = "@@ALERT/CREATE_ALERT_FAILURE",

  UPDATE_ALERT = "@@ALERT/UPDATE_ALERT",
  UPDATE_ALERT_SUCCESS = "@@ALERT/UPDATE_ALERT_SUCCESS",
  UPDATE_ALERT_FAILURE = "@@ALERT/UPDATE_ALERT_FAILURE",

  DELETE_ALERT = "@@ALERT/DELETE_ALERT",
  DELETE_ALERT_SUCCESS = "@@ALERT/DELETE_ALERT_SUCCESS",
  DELETE_ALERT_FAILURE = "@@ALERT/DELETE_ALERT_FAILURE",

  SET_FILTER = "@@ALERT/SET_FILTER",
}
