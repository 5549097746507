import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { DEFAULT_LIMIT } from "@shared/constants";
import { ProjectStatus } from "@shared/models";

import * as actions from "./actions";
import { ProjectFilter, ProjectStateType } from "../interface";

type Action = ActionType<typeof actions>;

export const defaultFilter: ProjectFilter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  company_code: null,
};

export const initialState: ProjectStateType = {
  projects: [],
  projectsTotal: 0,
  currentProject: null,
  filter: defaultFilter,
};

const reducer = createReducer<ProjectStateType, Action>(initialState)
  .handleAction(actions.createProject.success, (state, action) =>
    produce(state, (nextState) => {
      const { project } = action.payload;
      nextState.projects = [project, ...nextState.projects];
      nextState.projectsTotal = nextState.projectsTotal + 1;
      nextState.currentProject = project;
    }),
  )
  .handleAction(actions.updateProject.success, (state, action) =>
    produce(state, (nextState) => {
      const { project } = action.payload;

      const index = nextState.projects.findIndex((u) => u.id === project.id);
      if (index >= 0) {
        nextState.projects[index] = project;
      }
      if (state.currentProject && state.currentProject.id === project.id) {
        nextState.currentProject = project;
      }
    }),
  )
  .handleAction(actions.deleteProject.success, (state, action) =>
    produce(state, (nextState) => {
      const { project_id } = action.payload;
      const index = nextState.projects.findIndex((u) => u.id === project_id);
      if (index >= 0) {
        nextState.projects[index] = {
          ...nextState.projects[index],
          status: ProjectStatus.Archived,
        };
      }
      if (nextState.currentProject && nextState.currentProject.id === project_id) {
        nextState.currentProject = {
          ...nextState.currentProject,
          status: ProjectStatus.Archived,
        };
      }
    }),
  )
  .handleAction(actions.getProjects.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.projects = clear ? rows : [...nextState.projects, ...rows];
      nextState.projectsTotal = count;
    }),
  )
  .handleAction(actions.getProject.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentProject = action.payload;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.clearCurrentProject, (state) =>
    produce(state, (nexState) => {
      nexState.currentProject = null;
    }),
  );

export { reducer as ProjectReducer };
