import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { ErrorMessage } from "@shared/components/Common/";
import { FileButton } from "@shared/components";
import { CreateFileShape, FieldItemType } from "@shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface FileButtonItem extends RenderField {
  type: FieldItemType.FILE_BUTTON;
  baseFileFieldName?: string;
  disabled?: boolean;
  onChange?: (file: File, baseFile: CreateFileShape) => void;
  onReject?: (errorMessage: string) => void;
}

export const GenerateFileButton: GenerateRenderField<FileButtonItem> = (props) => {
  const {
    name,
    baseFileFieldName,
    disabled,
    onChange,
    onReject,
    formikProps: { setFieldValue, values, touched, errors },
    wrapperClass,
  } = props;

  const dispatch = useDispatch();

  const onChangeFile = useCallback(
    (file, baseFile) => {
      if (baseFileFieldName) {
        setFieldValue(baseFileFieldName, baseFile);
      }
      setFieldValue(name, file);
      onChange?.(file, baseFile);
    },
    [setFieldValue, onChange, name, baseFileFieldName],
  );

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        sharedActions.showNotification({
          message,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  return (
    <div key={name} id={name} className="input-line file-button-field">
      <FileButton
        onCreateFile={onChangeFile}
        onDeleteFile={() => onChangeFile(null, null)}
        onReject={onReject || displayFileRejectMessage}
        disabled={disabled}
        file={values[name]}
        className={wrapperClass}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
