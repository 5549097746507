import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { useNavigate } from "react-router";
import { AuthGuard } from "@shared/guards";
import { NamesOfParentRoutes } from "@shared/constants";
import { Toast, Notification, OverlayWrapper, ModalWrapper } from "@shared/components/";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { ToastProvider } from "react-toast-notifications";

import ApplicationContainer from "./ApplicationContainer";
import { AuthContainer } from "../Auth";

import "./index.scss";

const routes = [
  {
    path: `${NamesOfParentRoutes.AUTH}`,
    element: <AuthContainer />,
  },
  {
    path: `${NamesOfParentRoutes.AUTHORIZED}`,
    element: (
      <AuthGuard>
        <ApplicationContainer />
      </AuthGuard>
    ),
  },
];

const App: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  const content = useRoutes(routes);
  return (
    <div className="App">
      <ToastProvider components={{ Toast }}>
        <Notification notification={notification} />
        <OverlayWrapper />
        <ModalWrapper />
        {content}
      </ToastProvider>
    </div>
  );
};

export default App;
