import { fileProperties } from "./";

export const signatureProperties = {
  id: { type: "number" },
  expiry_date: { type: "string" },
  file: {
    type: "object",
    additionalProperties: false,
    properties: {
      ...fileProperties,
    },
  },
};
