import { createAsyncAction, createAction } from "typesafe-actions";
import { UserNotification } from "@shared/models";
import { PaginatedResponse, Filter, PayloadWithCode, CodeWithId } from "@shared/interfaces";

import { UserNotificationActionTypes } from "./constants";
import {
  ReadUserNotificationResponse,
  ReadUserNotificationsResponse,
  UserNotificationUnreadCountResponse,
} from "../interface";

export const getUserNotifications = createAsyncAction(
  UserNotificationActionTypes.GET_USER_NOTIFICATIONS,
  UserNotificationActionTypes.GET_USER_NOTIFICATIONS_SUCCESS,
  UserNotificationActionTypes.GET_USER_NOTIFICATIONS_FAILURE,
)<Filter, PaginatedResponse<UserNotification>, Error>();

export const getUserNotificationsUnreadCount = createAsyncAction(
  UserNotificationActionTypes.GET_USER_NOTIFICATIONS_UNREAD_COUNT,
  UserNotificationActionTypes.GET_USER_NOTIFICATIONS_UNREAD_COUNT_SUCCESS,
  UserNotificationActionTypes.GET_USER_NOTIFICATIONS_UNREAD_COUNT_FAILURE,
)<PayloadWithCode, UserNotificationUnreadCountResponse, Error>();

export const readUserNotifications = createAsyncAction(
  UserNotificationActionTypes.READ_USER_NOTIFICATIONS,
  UserNotificationActionTypes.READ_USER_NOTIFICATIONS_SUCCESS,
  UserNotificationActionTypes.READ_USER_NOTIFICATIONS_FAILURE,
)<PayloadWithCode, ReadUserNotificationsResponse, Error>();

export const readUserNotification = createAsyncAction(
  UserNotificationActionTypes.READ_USER_NOTIFICATION,
  UserNotificationActionTypes.READ_USER_NOTIFICATION_SUCCESS,
  UserNotificationActionTypes.READ_USER_NOTIFICATION_FAILURE,
)<Required<CodeWithId>, ReadUserNotificationResponse, Error>();

export const addUserNotification = createAction(UserNotificationActionTypes.ADD_USER_NOTIFICATION)<UserNotification>();
export const clearUserNotifications = createAction(UserNotificationActionTypes.CLEAR_USER_NOTIFICATIONS)();

export const setFilter = createAction(UserNotificationActionTypes.SET_FILTER)<Filter | null>();
