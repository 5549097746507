import React from "react";
import classnames from "classnames";

export type IconType =
  | "notification-error"
  | "notification-success"
  | "back"
  | "find"
  | "clear"
  | "edit"
  | "back-gray"
  | "close"
  | "edit-white"
  | "edit-orange"
  | "delete"
  | "delete-white"
  | "back-white"
  | "chevron-orange"
  | "front-orange"
  | "lock"
  | "filter"
  | "calendar"
  | "calendar-orange"
  | "menu"
  | "option-selected-green"
  | "menu-orange"
  | "chevron-top-blue"
  | "chevron-bottom-gray"
  | "document"
  | "marker"
  | "check-orange"
  | "check-green"
  | "double-check-green"
  | "check-white"
  | "double-check-white"
  | "clock"
  | "warning"
  | "refresh"
  | "letter"
  | "logout"
  | "sort"
  | "sort-asc"
  | "sort-dsc"
  | "activities"
  | "alerts"
  | "assignments"
  | "documents"
  | "header-icon"
  | "jsa"
  | "members"
  | "menu-toggle"
  | "notifications"
  | "projects"
  | "settings"
  | "tasks"
  | "teams"
  | "trainings-and-certifcates"
  | "user"
  | "upload"
  | "users";
export interface IconProps {
  type: IconType;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
}

export default function Icon(props: IconProps) {
  const { type, className, onClick, title } = props;
  return <div onClick={onClick} title={title} className={classnames("icon", className, type)} />;
}
