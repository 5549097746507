import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import { useShowModal, useHideModal } from "@shared/hooks";
import { Icon } from "@shared/components";

import { ImageMultiSelectModal } from "./ImageMultiSelectModal";

import "./index.scss";

export interface ImageFileProps {
  base64: string;
  name: string;
}

export interface ImageMultiSelectProps {
  className?: string;
  onChange: (files: ImageFileProps[]) => void;
  value?: ImageFileProps[];
  label?: string;
  disabled?: boolean;
  name: string;
}

export default function ImageMultiSelect(props: ImageMultiSelectProps) {
  const { className, onChange, value = [], label, name } = props;
  const showModal = useShowModal();
  const { handleOnClose } = useHideModal();

  const onSaveFile = useCallback(
    (newFile: ImageFileProps) => {
      onChange([...value, { ...newFile, name }]);
    },
    [value, onChange, name],
  );

  const onAddFile = useCallback(() => {
    showModal({
      modalWidth: "470px",
      wrapperClass: "image-select-modal",
      closeOnOutside: true,
      body: <ImageMultiSelectModal onClose={handleOnClose} onSave={onSaveFile} />,
    });
  }, [handleOnClose, onSaveFile, showModal]);

  const onDelete = useCallback(
    (index: number) => {
      const copy = [...value];
      copy.splice(index, 1);
      onChange(copy);
    },
    [onChange, value],
  );

  const renderDefaultBlock = useMemo(() => {
    return (
      <div className="image-add-block" onClick={onAddFile}>
        <div className="image-add-label">+</div>
      </div>
    );
  }, [onAddFile]);

  const renderImages = useMemo(() => {
    return value.map((v, index) => (
      <div key={index} className="image-wrapper">
        <img src={v.base64} alt="" />
        <div className="image-controls">
          <div className="image-delete" onClick={onDelete.bind(null, index)}>
            <Icon type="clear" />
          </div>
        </div>
      </div>
    ));
  }, [value, onDelete]);

  return (
    <div className={classnames("image-multi-select-wrapper", className)}>
      {label && <div className="image-multi-select-label">{label}</div>}
      <div className="image-multi-select-images">
        {renderImages}
        {renderDefaultBlock}
      </div>
    </div>
  );
}
