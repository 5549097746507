import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";

import "./index.scss";

export enum SwitchState {
  ON = "on",
  OFF = "off",
}

export interface SwitcherProps {
  onChange?: (value: SwitchState) => void;
  switchOnText?: string;
  switchOffText?: string;
  value?: SwitchState | null;
}

const Switcher: React.FC<SwitcherProps> = (props) => {
  const { switchOnText = "On", switchOffText = "Off", value, onChange } = props;
  const [switchState, setSwitchState] = useState(value || SwitchState.ON);

  useEffect(() => {
    setSwitchState(value || SwitchState.ON);
  }, [value]);

  const handleSwitch = useCallback(() => {
    const getNewSwitchState = (currentSwitchState: SwitchState): SwitchState => {
      return currentSwitchState === SwitchState.ON ? SwitchState.OFF : SwitchState.ON;
    };

    if (onChange) {
      onChange(getNewSwitchState(switchState));
    } else {
      setSwitchState(getNewSwitchState);
    }
  }, [switchState, onChange]);

  return (
    <div className={`switcher switcher_position-${switchState}`} onClick={handleSwitch}>
      <div
        className={classnames("switcher-value", {
          "switcher-value_active": switchState === SwitchState.ON,
        })}>
        {switchOnText}
      </div>
      <div
        className={classnames("switcher-value", {
          "switcher-value_active": switchState === SwitchState.OFF,
        })}>
        {switchOffText}
      </div>
    </div>
  );
};

export default Switcher;
