import { SchemasMap, StandardEnumValue } from "../interfaces";

export function mapToSchema(schemaShapes: SchemasMap<StandardEnumValue>) {
  const schemasMap: { [key: string]: Record<string, unknown> } = {};
  Object.entries(schemaShapes).forEach(([, schemaShape]) => {
    if (schemaShape.request.name) {
      schemasMap[schemaShape.request.name] = schemaShape.request.schema;
    }
    if (schemaShape.response.name) {
      schemasMap[schemaShape.response.name] = schemaShape.response.schema;
    }
  });

  return schemasMap;
}

export function generateDynamicSchemaKeyNumeric(url: string) {
  return url.replace(/\$id/g, "[0-9]{1,10}").split("/").join(`\\/`);
}

export function generateDynamicSchemaKeyString(url: string) {
  return url.replace(/_id/g, "[0-9]{1,10}").replace(/\$id/g, `[a-zA-Z0-9]+`).split("/").join(`\\/`);
}
