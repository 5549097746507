import React, { useEffect, useState } from "react";
import { checkPermission } from "@shared/utils/";
import { selectors as companySelectors } from "@containers/Company/store";
import { useSelector } from "react-redux";
import { CompanyStatus } from "@shared/models";

export interface AccessControlProps {
  permission: string;
  children?: React.ReactNode;
  option?: { companyCode?: string | null; onlyAppAdmin?: boolean };
  companyCode?: string;
}

const AccessControl = (props: AccessControlProps) => {
  const { permission, children, option } = props;
  const company = useSelector(companySelectors.getCurrentCompany());

  const [permitted, setPermitted] = useState(false);

  useEffect(() => {
    if (company && company.status === CompanyStatus.Deactivated) {
      setPermitted(false);
    } else {
      setPermitted(checkPermission(permission, option));
    }
  }, [permission, option, company]);

  return permitted ? <>{children}</> : null;
};

export default AccessControl;
