import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";

const selectActivity = (state: AppState) => state.activity;

export const getActivities = () => createSelector(selectActivity, (state) => state.activities);
export const getActivitiesTotal = () => createSelector(selectActivity, (state) => state.activitiesTotal);
export const getCurrentActivity = () => createSelector(selectActivity, (state) => state.currentActivity);
export const getLastCreatedActivity = () => createSelector(selectActivity, (state) => state.lastCreatedActivity);
export const getFilter = () => createSelector(selectActivity, (state) => state.filter);
