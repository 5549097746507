import { shortUserSchema } from "./shared.schema";

export const fileSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "url", "created_at", "creator"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    url: { type: "string" },
    created_at: { type: "string" },
    creator: shortUserSchema,
  },
};

export const filesListSchema = {
  type: "array",
  items: fileSchema,
};

export const createFileRequest = {
  type: "object",
  additionalProperties: false,
  required: ["base64", "name"],
  properties: {
    base64: { type: "string" },
    name: { type: "string" },
  },
};

export const createFileResponse = {
  type: "object",
  required: ["file", "message"],
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    file: fileSchema,
  },
};
