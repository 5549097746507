import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyNumeric } from "@shared/utils";
import { filterSchema } from "@shared/validators";
import {
  getActivitiesResponseSchema,
  createActivityRequestSchema,
  createActivityResponseSchema,
  updateActivityStatusRequestSchema,
  activitySchema,
  importActivityRequestSchema,
  importActivityResponseSchema,
  getActivityRequestSchema,
} from "@shared/validators/activity.schema";

const schemaShapes: SchemasMap<keyof typeof API.ACTIVITIES> = {
  [API_ENUM.GET_ACTIVITIES]: {
    request: {
      name: `${API.ACTIVITIES.GET_ACTIVITIES}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${API.ACTIVITIES.GET_ACTIVITIES}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getActivitiesResponseSchema,
    },
  },
  [API_ENUM.CREATE_ACTIVITY]: {
    request: {
      name: `${API.ACTIVITIES.CREATE_ACTIVITY}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createActivityRequestSchema,
    },
    response: {
      name: `${API.ACTIVITIES.CREATE_ACTIVITY}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createActivityResponseSchema,
    },
  },
  [API_ENUM.UPDATE_ACTIVITY]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.ACTIVITIES.UPDATE_ACTIVITY("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: createActivityRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.ACTIVITIES.UPDATE_ACTIVITY("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: createActivityResponseSchema,
    },
  },
  [API_ENUM.UPDATE_ACTIVITY_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.ACTIVITIES.UPDATE_ACTIVITY_STATUS("$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: updateActivityStatusRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.ACTIVITIES.UPDATE_ACTIVITY_STATUS("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: createActivityResponseSchema,
    },
  },
  [API_ENUM.GET_ACTIVITY]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.ACTIVITIES.GET_ACTIVITY("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getActivityRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.ACTIVITIES.GET_ACTIVITY("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: activitySchema,
    },
  },
  [API_ENUM.IMPORT_ACTIVITY]: {
    request: {
      name: `${API.ACTIVITIES.IMPORT_ACTIVITY}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: importActivityRequestSchema,
    },
    response: {
      name: `${API.ACTIVITIES.IMPORT_ACTIVITY}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: importActivityResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
