import { all, fork } from "redux-saga/effects";
import { saga as authSaga } from "@containers/Auth/store";
import { saga as userSaga } from "@containers/User/store";
import { saga as companySaga } from "@containers/Company/store";
import { saga as memberSaga } from "@containers/Member/store";
import { saga as activitySaga } from "@containers/Activity/store";
import { saga as taskSaga } from "@containers/Task/store";
import { saga as jsaSaga } from "@containers/Jsa/store";
import { saga as assignmentSaga } from "@containers/Assignment/store";
import { saga as trainingsSaga } from "@containers/TrainingAndCertificate/store";
import { saga as documentSaga } from "@containers/Documents/store";
import { saga as alertSaga } from "@containers/Alert/store";
import { saga as userNotificationSaga } from "@containers/UserNotification/store";
import { saga as contactUsSaga } from "@containers/ContactUs/store";
import { saga as projectSaga } from "@containers/Project/store";
import { saga as teamSaga } from "@containers/Team/store";
import { saga as sharedSaga } from "@shared/store";

const allSagas = [
  authSaga,
  sharedSaga,
  userSaga,
  companySaga,
  memberSaga,
  activitySaga,
  taskSaga,
  jsaSaga,
  assignmentSaga,
  trainingsSaga,
  documentSaga,
  alertSaga,
  userNotificationSaga,
  contactUsSaga,
  projectSaga,
  teamSaga,
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
