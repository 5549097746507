import React, { FC, useMemo, useRef, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps, FormikErrors, FormikTouched } from "formik";
import { checkPermission, getFullUserName } from "@shared/utils";
import { PERMISSION } from "@shared/constants";
import { FormGenerator, FormHeader, FormCard, TeamMemberSelector, ErrorMessage, FormDivider } from "@shared/components";
import { CertificateTemplate, MemberListItem, Training, TrainingPass, TrainingStatus } from "@shared/models";
import { TypeTraining } from "@containers/TrainingAndCertificate/containers/TrainingCreateEditContainer/TrainingCreateEditContainer";
import {
  TrainingLeftFormShape,
  TrainingRightFormShape,
  TrainingFormShapeToRequest,
} from "@containers/TrainingAndCertificate/interface";
import { ExtraKeyType, FormProps } from "@shared/interfaces";
import { useCertificateTemplateSelector } from "@containers/TrainingAndCertificate/hooks";
import { actions, selectors } from "@containers/TrainingAndCertificate/store";

import {
  getInitValues,
  getFields,
  handlers,
  convertFormData,
  publishValidationSchema,
  draftValidationSchema,
  getRightFormValidationSchema,
} from "./formHelpers";
import { RightComplexFormHeader } from "../";

import "./index.scss";

export interface TrainingFormProps {
  training: Training | null;
  trainingPass: TrainingPass[];
  onCancel: () => void;
  onChangeForm?: (values: TrainingLeftFormShape & TrainingRightFormShape) => void;
  onUpdateStatus?: (status: TrainingStatus) => void;
  company_code?: string | null;
  setTypeTraining: (type: TypeTraining) => void;
}

function getDisabledRejectedTeamMemberText(item: MemberListItem) {
  return `${getFullUserName(item)} passed the training. You can not remove team member after the training was passed.`;
}

const TrainingForm: FC<FormProps<TrainingFormShapeToRequest> & TrainingFormProps> = (props) => {
  const {
    training,
    onCancel,
    onChangeForm,
    onUpdateStatus,
    company_code,
    setTypeTraining,
    trainingPass,
    submitHandler,
  } = props;

  const dispatch = useDispatch();
  const clearTrainingForm = useSelector(selectors.getClearTrainingForm());
  const formikLeftRef = useRef<FormikProps<TrainingLeftFormShape>>(null);
  const formikRightRef = useRef<FormikProps<TrainingRightFormShape>>(null);

  const [formikErrors, setFormikErrors] = useState<FormikErrors<TrainingLeftFormShape>>({});
  const [formikTouched, setFormikTouched] = useState<FormikTouched<TrainingLeftFormShape>>({});

  const [isExpirationOrScheduledDate, setIsExpirationOrScheduledDate] = useState(false);
  const [isRecurringTraining, setIsRecurringTraining] = useState(false);
  const [isAutoCreateCertificate, setIsAutoCreateCertificate] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [disabledMembersObj, setDisabledMembersObj] = useState<ExtraKeyType<boolean>>({});

  const [initValuesForLeftPartForm, initValuesForRightPartForm] = useMemo(() => {
    return getInitValues(training);
  }, [training]);

  const [trainingMembers, setTrainingMembers] = useState<MemberListItem[]>([]);

  const title = useMemo(() => {
    if (training) {
      return training.status === TrainingStatus.Archived ? "Archived Training" : "Edit Training";
    }

    return "New Training";
  }, [training]);

  useEffect(() => {
    if (trainingPass) {
      setDisabledMembersObj(
        trainingPass.reduce((acc: ExtraKeyType<boolean>, item) => {
          acc[item.member_id] = Boolean(item.creator_user);
          return acc;
        }, {}),
      );
    }
  }, [trainingPass]);

  useEffect(() => {
    setIsExpirationOrScheduledDate(Boolean(initValuesForLeftPartForm.is_expiration_or_scheduled_date));
    setIsRecurringTraining(Boolean(initValuesForLeftPartForm.is_recurring_training));
    setIsAutoCreateCertificate(Boolean(initValuesForLeftPartForm.is_auto_create_certificate));
  }, [initValuesForLeftPartForm]);

  useEffect(() => {
    setTrainingMembers(initValuesForLeftPartForm.members);
  }, [initValuesForLeftPartForm]);

  useEffect(() => {
    dispatch(actions.setClearTrainingForm(false));
  }, [dispatch]);

  const clearFormValues = useCallback(() => {
    if (formikLeftRef.current) {
      formikLeftRef.current.resetForm();
    }
    if (formikRightRef.current) {
      formikRightRef.current.resetForm();
    }
    setTrainingMembers([]);
  }, []);

  useEffect(() => {
    if (clearTrainingForm) {
      clearFormValues();
      dispatch(actions.setClearTrainingForm(false));
    }
  }, [clearFormValues, dispatch, clearTrainingForm]);

  const localOnSubmit = useCallback((params: { isDraft?: boolean } = {}) => {
    const { isDraft = false } = params;
    setIsDraft(isDraft);
    setTimeout(() => {
      if (formikLeftRef.current) {
        formikLeftRef.current.submitForm();
      }
    }, 0);
  }, []);

  const selectCertificateTemplateCb = useCallback((template: CertificateTemplate) => {
    if (formikLeftRef.current) {
      formikLeftRef.current.setFieldValue("certificate_name", template.name);
      formikLeftRef.current.setFieldValue("certificate_issued_by", template.issued_by);
      setTimeout(() => {
        formikLeftRef?.current?.validateField("certificate_name");
      }, 0);
    }
  }, []);

  const { selectedCertificateTemplate, onChangeCertificateTemplateName, onChangeCertificateTemplateIssuedBy } =
    useCertificateTemplateSelector({
      companyCode: company_code,
      selectCertificateTemplateCb,
      getFilterData: () => {
        if (formikLeftRef.current) {
          const { certificate_name, certificate_issued_by } = formikLeftRef.current.values;

          return {
            name: certificate_name || undefined,
            issued_by: certificate_issued_by || undefined,
          };
        }
      },
    });

  const matchedCertificateTemplatesActivities = useMemo(() => {
    if (formikRightRef.current && selectedCertificateTemplate) {
      const { activities: certificateTemplateActivities } = selectedCertificateTemplate;
      const { activities: formActivities } = formikRightRef.current.values;
      const matchedActivities: number[] = [];

      certificateTemplateActivities.forEach((certTplActivity) => {
        if (formActivities?.some((formActivity) => formActivity.value === certTplActivity.id)) {
          matchedActivities.push(certTplActivity.id);
        }
      });

      return matchedActivities;
    }
    return [];
  }, [selectedCertificateTemplate]);

  const matchedCertificateTemplatesProjects = useMemo(() => {
    if (formikRightRef.current && selectedCertificateTemplate) {
      const { projects: certificateTemplateProjects } = selectedCertificateTemplate;
      const { projects: formProjects } = formikRightRef.current.values;
      const matchedProjects: number[] = [];

      certificateTemplateProjects.forEach((certTplProject) => {
        if (formProjects?.some((formProject) => formProject.value === certTplProject.id)) {
          matchedProjects.push(certTplProject.id);
        }
      });

      return matchedProjects;
    }
    return [];
  }, [selectedCertificateTemplate]);

  useEffect(() => {
    setTimeout(() => {
      formikRightRef?.current?.validateForm();
    }, 0);
  }, [selectedCertificateTemplate]);

  const onChangeAddress = useCallback((data) => {
    if (formikLeftRef.current) {
      formikLeftRef.current.setFieldValue("location_place_id", data.value);
    }
  }, []);

  const onChangeMember = useCallback((members: MemberListItem[]) => {
    if (formikLeftRef.current) {
      formikLeftRef.current.setFieldValue("members", members);
      setTrainingMembers(members);
    }
  }, []);

  const onChangeFormikState = useCallback(() => {
    setTimeout(() => {
      if (formikLeftRef.current) {
        setFormikErrors(formikLeftRef.current.errors);
        setFormikTouched(formikLeftRef.current.touched);
      }
    }, 0);
  }, []);

  const validateDateFields = useCallback(() => {
    setTimeout(() => {
      if (formikLeftRef.current) {
        [
          "scheduled_start_date",
          "scheduled_start_time",
          "scheduled_end_date",
          "scheduled_end_time",
          "expiration_date",
        ].map((m) => formikLeftRef.current && formikLeftRef.current.validateField(m));
      }
    }, 0);
  }, []);

  const onChangeExpirationDate = useCallback(() => {
    if (formikLeftRef.current) {
      formikLeftRef.current.setFieldValue("scheduled_start_date", null);
      formikLeftRef.current.setFieldValue("scheduled_start_time", null);
      formikLeftRef.current.setFieldValue("scheduled_end_date", null);
      formikLeftRef.current.setFieldValue("scheduled_end_time", null);
      validateDateFields();
    }
  }, [validateDateFields]);

  const onChangeScheduledDate = useCallback(
    (value, isStartDate: boolean) => {
      if (formikLeftRef.current) {
        const scheduledEndDate = formikLeftRef.current.values.scheduled_end_date;
        if (isStartDate && !scheduledEndDate) {
          formikLeftRef.current.setFieldValue("scheduled_end_date", value);
        }
        formikLeftRef.current.setFieldValue("expiration_date", null);
        validateDateFields();
      }
    },
    [validateDateFields],
  );

  const canEditTraining = useMemo(() => {
    return checkPermission(PERMISSION.CREATE_EDIT_ARCHIVE_COMPANY_TRAININGS, { companyCode: company_code });
  }, [company_code]);

  const isDisabled = useMemo(() => {
    return Boolean(training && training.status === TrainingStatus.Archived) || !canEditTraining;
  }, [training, canEditTraining]);

  const submitText = useMemo(() => {
    return !isDisabled ? "Publish" : null;
  }, [isDisabled]);

  const [fieldsForLeftPartForm, fieldsForRightPartForm] = useMemo(() => {
    return getFields({
      isPassedTraining: Boolean(trainingPass && trainingPass.length),
      training,
      isDisabledFields: isDisabled,
      onChangeAddress,
      isExpirationOrScheduledDate,
      isRecurringTraining,
      isAutoCreateCertificate,
      setIsAutoCreateCertificate,
      setIsExpirationOrScheduledDate,
      setIsRecurringTraining,
      onChangeExpirationDate,
      onChangeScheduledDate,
      onChangeCertificateTemplateIssuedBy,
      onChangeCertificateTemplateName,
      matchedCertificateTemplatesActivities,
      matchedCertificateTemplatesProjects,
      hasCertificateTemplate: Boolean(selectedCertificateTemplate),
    });
  }, [
    trainingPass,
    onChangeAddress,
    isDisabled,
    isRecurringTraining,
    isExpirationOrScheduledDate,
    isAutoCreateCertificate,
    setIsAutoCreateCertificate,
    setIsExpirationOrScheduledDate,
    setIsRecurringTraining,
    onChangeExpirationDate,
    onChangeScheduledDate,
    training,
    matchedCertificateTemplatesActivities,
    matchedCertificateTemplatesProjects,
    onChangeCertificateTemplateIssuedBy,
    onChangeCertificateTemplateName,
    selectedCertificateTemplate,
  ]);

  const memoizedHandlers = useMemo(() => {
    return handlers(company_code);
  }, [company_code]);

  const membersSelector = useMemo(() => {
    return (
      <TeamMemberSelector
        companyCode={company_code}
        selectedMembers={trainingMembers}
        onChange={onChangeMember}
        disabled={isDisabled}
        disabledMembersObj={disabledMembersObj}
        getDisabledRejectedText={getDisabledRejectedTeamMemberText}
        disabledDeleteModalTitle="Training is passed"
      />
    );
  }, [company_code, isDisabled, trainingMembers, onChangeMember, disabledMembersObj]);

  const membersError = useMemo(() => {
    return formikErrors.members ? (
      <ErrorMessage isTouched={Boolean(formikTouched.members)} error={formikErrors.members as string} />
    ) : null;
  }, [formikErrors.members, formikTouched.members]);

  const onDeleteTraining = useCallback(() => {
    if (!training || !onUpdateStatus || training.status === TrainingStatus.Archived) return null;

    onUpdateStatus(TrainingStatus.Archived);
  }, [training, onUpdateStatus]);

  const handleSubmitForm = useCallback(
    (_, { setSubmitting }) => {
      if (formikRightRef.current && formikLeftRef.current && company_code) {
        const leftFormValues = formikLeftRef.current.values;
        const rightFormValues = formikRightRef.current.values;
        submitHandler?.(
          convertFormData(
            {
              ...leftFormValues,
              ...rightFormValues,
            },
            company_code,
          ),
        );
        setSubmitting(false);
      }
    },
    [submitHandler, company_code],
  );

  const handleValidateForm = useCallback(
    (values) => {
      onChangeForm?.(values);
      onChangeFormikState();
    },
    [onChangeForm, onChangeFormikState],
  );

  const rightFormValidationSchema = useMemo(
    () =>
      getRightFormValidationSchema(
        Boolean(selectedCertificateTemplate),
        matchedCertificateTemplatesActivities,
        matchedCertificateTemplatesProjects,
      ),
    [selectedCertificateTemplate, matchedCertificateTemplatesActivities, matchedCertificateTemplatesProjects],
  );

  return (
    <>
      <div className="form-header-wrapper">
        <FormHeader
          companyCode={company_code}
          title={title}
          onBack={onCancel}
          onSubmit={localOnSubmit}
          onDelete={!isDisabled && training ? onDeleteTraining : undefined}
          rightComplexFormHeader={
            <RightComplexFormHeader
              training={training}
              setTypeTraining={setTypeTraining}
              submitText={submitText}
              onSubmit={localOnSubmit}
            />
          }
        />
      </div>
      <div className="training-form-wrapper">
        <FormCard className="training-form-card-general">
          <Formik
            innerRef={formikLeftRef}
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={true}
            initialValues={initValuesForLeftPartForm}
            onSubmit={handleSubmitForm}
            validate={handleValidateForm}
            validationSchema={isDraft ? draftValidationSchema : publishValidationSchema}>
            {(formikProps) => (
              <>
                <FormGenerator fields={fieldsForLeftPartForm} handlers={memoizedHandlers} formikProps={formikProps} />
                <FormDivider className="training-general-form-divider" />
              </>
            )}
          </Formik>
          {membersSelector}
          {membersError}
        </FormCard>
        <FormCard className="training-form-card-activities-and-projects">
          <Formik
            innerRef={formikRightRef}
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            initialValues={initValuesForRightPartForm}
            onSubmit={handleSubmitForm}
            validate={handleValidateForm}
            validationSchema={rightFormValidationSchema}>
            {(formikProps) => (
              <FormGenerator fields={fieldsForRightPartForm} handlers={memoizedHandlers} formikProps={formikProps} />
            )}
          </Formik>
        </FormCard>
      </div>
    </>
  );
};
export default TrainingForm;
