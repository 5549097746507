import { createAction, createAsyncAction } from "typesafe-actions";
import { PaginatedResponse, Filter, PayloadWithId, BaseResponse, WithCallback } from "@shared/interfaces";
import { ActivityActionTypes } from "@containers/Activity/store/constants";
import { ActivityListItem, Activity } from "@shared/models/Activity";
import {
  ActivityFormShapeToRequest,
  CreateActivitySuccess,
  UpdateStatusShape,
  ActivityImportShape,
} from "@containers/Activity/interface";

export const getActivities = createAsyncAction(
  ActivityActionTypes.GET_ACTIVITIES,
  ActivityActionTypes.GET_ACTIVITIES_SUCCESS,
  ActivityActionTypes.GET_ACTIVITIES_FAILURE,
)<Filter, PaginatedResponse<ActivityListItem>, Error>();

export const clearActivities = createAction(ActivityActionTypes.CLEAR_ACTIVITIES)();

export const createActivity = createAsyncAction(
  ActivityActionTypes.CREATE_LIBRARY_ACTIVITY,
  ActivityActionTypes.CREATE_LIBRARY_ACTIVITY_SUCCESS,
  ActivityActionTypes.CREATE_LIBRARY_ACTIVITY_FAILURE,
)<WithCallback<ActivityFormShapeToRequest>, CreateActivitySuccess, Error>();

export const updateActivity = createAsyncAction(
  ActivityActionTypes.UPDATE_ACTIVITY,
  ActivityActionTypes.UPDATE_ACTIVITY_SUCCESS,
  ActivityActionTypes.UPDATE_ACTIVITY_FAILURE,
)<PayloadWithId<ActivityFormShapeToRequest>, CreateActivitySuccess, Error>();

export const updateActivityStatus = createAsyncAction(
  ActivityActionTypes.UPDATE_ACTIVITY_STATUS,
  ActivityActionTypes.UPDATE_ACTIVITY_STATUS_SUCCESS,
  ActivityActionTypes.UPDATE_ACTIVITY_STATUS_FAILURE,
)<PayloadWithId<UpdateStatusShape>, CreateActivitySuccess, Error>();

export const getActivity = createAsyncAction(
  ActivityActionTypes.GET_ACTIVITY,
  ActivityActionTypes.GET_ACTIVITY_SUCCESS,
  ActivityActionTypes.GET_ACTIVITY_FAILURE,
)<{ id: number; company_code?: string }, Activity | null, Error>();

export const importActivity = createAsyncAction(
  ActivityActionTypes.IMPORT_ACTIVITY,
  ActivityActionTypes.IMPORT_ACTIVITY_SUCCESS,
  ActivityActionTypes.IMPORT_ACTIVITY_FAILURE,
)<ActivityImportShape, BaseResponse, Error>();

export const setLastCreatedActivity = createAction(ActivityActionTypes.SET_LAST_CREATED_ACTIVITY)<Activity | null>();

export const setFilter = createAction(ActivityActionTypes.SET_FILTER)<Filter | null>();
