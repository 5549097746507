export enum CompanyActionTypes {
  GET_COMPANIES = "@@COMPANY/GET_COMPANIES",
  GET_COMPANIES_SUCCESS = "@@COMPANY/GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE = "@@COMPANY/GET_COMPANIES_FAILURE",

  GET_COMPANY = "@@COMPANY/GET_COMPANY",
  GET_COMPANY_SUCCESS = "@@COMPANY/GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE = "@@COMPANY/GET_COMPANY_FAILURE",

  GET_COMPANY_TO_EDIT = "@@COMPANY/GET_COMPANY_TO_EDIT",
  GET_COMPANY_TO_EDIT_SUCCESS = "@@COMPANY/GET_COMPANY_TO_EDIT_SUCCESS",
  GET_COMPANY_TO_EDIT_FAILURE = "@@COMPANY/GET_COMPANY_TO_EDIT_FAILURE",

  CREATE_COMPANY = "@@COMPANY/CREATE_COMPANY",
  CREATE_COMPANY_SUCCESS = "@@COMPANY/CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE = "@@COMPANY/CREATE_COMPANY_FAILURE",

  UPDATE_COMPANY = "@@COMPANY/UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCESS = "@@COMPANY/UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAILURE = "@@COMPANY/UPDATE_COMPANY_FAILURE",

  UPDATE_COMPANY_STATUS = "@@COMPANY/UPDATE_COMPANY_STATUS",
  UPDATE_COMPANY_STATUS_SUCCESS = "@@COMPANY/UPDATE_COMPANY_STATUS_SUCCESS",
  UPDATE_COMPANY_STATUS_FAILURE = "@@COMPANY/UPDATE_COMPANY_STATUS_FAILURE",

  SET_FILTER = "@@COMPANY/SET_FILTER",
}
