import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { FieldItem } from "@shared/components/FormGenerator";
import { FieldItemType, Option, ORDER_TYPE, RenderTypes } from "@shared/interfaces";
import {
  Activity,
  CertificateTemplate,
  ProjectStatus,
  Training,
  TrainingStatus,
  Project,
  ActivityStatus,
} from "@shared/models";
import { API, FORM_ERROR_MESSAGES, PERIOD_OPTIONS } from "@shared/constants";
import { IS_ONLY_DIGITS } from "@shared/constants/regExMatches";
import {
  TrainingLeftFormShape,
  TrainingRightFormShape,
  TrainingFormShapeToRequest,
} from "@containers/TrainingAndCertificate/interface";
import { actions as activityActions, selectors as activitySelectors } from "@containers/Activity/store";
import { actions as projectActions, selectors as projectSelectors } from "@containers/Project/store";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import {
  actions as certificateActions,
  selectors as certificateSelectors,
} from "@containers/TrainingAndCertificate/store";
import { FormDivider } from "@shared/components";
import { convertDateToEstTz, getDateWithTimezone, prepareOptionFunction } from "@shared/utils";
import { combineTimeAndDate } from "@containers/Assignment/components/AssignmentCreateEditContainer/AssignmentForm/formHelpers";

import {
  prepareCertificateIssuedByFunction,
  prepareCertificateNameFunction,
} from "../../CertificateCreateEditContainer/CertificateForm/formHelpers";

interface GetFieldsOptions {
  training: Training | null;
  isPassedTraining: boolean;
  isDisabledFields?: boolean;
  onChangeAddress: (data: { label: string; value?: string }) => void;
  onChangeScheduledDate: (newValue: Date | null, isStartDate: boolean) => void;
  onChangeExpirationDate: (newValue: Date | null) => void;
  isExpirationOrScheduledDate: boolean;
  isRecurringTraining: boolean;
  isAutoCreateCertificate: boolean;
  setIsExpirationOrScheduledDate: (value: boolean) => void;
  setIsRecurringTraining: (value: boolean) => void;
  setIsAutoCreateCertificate: (value: boolean) => void;
  onChangeCertificateTemplateName?: (fileld: string, certificateName: string) => void;
  onChangeCertificateTemplateIssuedBy?: () => void;
  matchedCertificateTemplatesActivities?: number[];
  matchedCertificateTemplatesProjects?: number[];
  hasCertificateTemplate: boolean;
}

const prepareProjectOptionFunction = (item: Project) => {
  return { label: `${item.name} (${item.location?.address || ""})`, value: item.id || null };
};

const commonValidationSchema = {
  needRedirect: Yup.boolean().optional(),
  status: Yup.string().optional(),
  name: Yup.string().max(100, `Name ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  held_by: Yup.string().max(100, `Held by ${FORM_ERROR_MESSAGES.LONG}`).optional(),
  description: Yup.string(),
  location: Yup.object().optional().nullable(),
  expiration_date: Yup.date()
    .test("expiration_date", FORM_ERROR_MESSAGES.REQUIRED, function (expirationDate) {
      return !(
        !this.parent.is_expiration_or_scheduled_date &&
        !expirationDate &&
        !this.parent.scheduled_start_date &&
        !this.parent.scheduled_end_date &&
        !this.parent.scheduled_start_time &&
        !this.parent.scheduled_end_time
      );
    })
    .nullable(),
  scheduled_start_date: Yup.date()
    .test("scheduled_start_date-min", "Start date cannot be after the end date", function (scheduledStartDate) {
      if (
        this.parent.is_expiration_or_scheduled_date ||
        !scheduledStartDate ||
        !this.parent.scheduled_start_time ||
        !this.parent.scheduled_end_date ||
        !this.parent.scheduled_end_time
      )
        return true;

      return (
        moment(combineTimeAndDate(scheduledStartDate, this.parent.scheduled_start_time)) <
        moment(combineTimeAndDate(this.parent.scheduled_end_date, this.parent.scheduled_end_time))
      );
    })
    .test("scheduled_start_date", FORM_ERROR_MESSAGES.REQUIRED, function (scheduledStartDate) {
      return !(
        !this.parent.is_expiration_or_scheduled_date &&
        !this.parent.expiration_date &&
        !scheduledStartDate &&
        (this.parent.scheduled_end_date || this.parent.scheduled_start_time || this.parent.scheduled_end_time)
      );
    })
    .nullable(),
  scheduled_end_date: Yup.date()
    .test("scheduled_end_date", FORM_ERROR_MESSAGES.REQUIRED, function (scheduledEndDate) {
      return !(
        !this.parent.is_expiration_or_scheduled_date &&
        !this.parent.expiration_date &&
        !scheduledEndDate &&
        (this.parent.scheduled_end_time || this.parent.scheduled_start_date || this.parent.scheduled_start_time)
      );
    })
    .nullable(),
  scheduled_start_time: Yup.date()
    .test("scheduled_start_time", FORM_ERROR_MESSAGES.REQUIRED, function (scheduledStartTime) {
      return !(
        !this.parent.is_expiration_or_scheduled_date &&
        !this.parent.expiration_date &&
        !scheduledStartTime &&
        (this.parent.scheduled_start_date || this.parent.scheduled_end_date || this.parent.scheduled_end_time)
      );
    })
    .nullable(),
  scheduled_end_time: Yup.date()
    .test("scheduled_end_time", FORM_ERROR_MESSAGES.REQUIRED, function (scheduledEndTime) {
      return !(
        !this.parent.is_expiration_or_scheduled_date &&
        !this.parent.expiration_date &&
        !scheduledEndTime &&
        (this.parent.scheduled_end_date || this.parent.scheduled_start_date || this.parent.scheduled_start_time)
      );
    })
    .nullable(),

  repeat_every: Yup.number()
    .test("repeat_every", "Min value 1", function (repeatEvery) {
      return this.parent.is_recurring_training ? Number(repeatEvery) > 0 : true;
    })
    .test("repeat_every", "Invalid decimal", function (repeatEvery) {
      return this.parent.is_recurring_training ? IS_ONLY_DIGITS.test(String(repeatEvery)) : true;
    }),
  repeat_every_period: Yup.string()
    .test("repeat_every_period", FORM_ERROR_MESSAGES.REQUIRED, function (repeatEveryPeriod) {
      return this.parent.is_recurring_training ? Boolean(repeatEveryPeriod) : true;
    })
    .nullable(),
  certificate_name: Yup.string()
    .test("certificate_name_max", `Certificate name ${FORM_ERROR_MESSAGES.LONG}`, function (certificateName) {
      return this.parent.is_auto_create_certificate ? !(String(certificateName).length > 150) : true;
    })
    .test("certificate_name", FORM_ERROR_MESSAGES.REQUIRED, function (certificateName) {
      return this.parent.is_auto_create_certificate ? Boolean(certificateName) : true;
    })
    .nullable(),
  certificate_issued_by: Yup.string()
    .test("certificate_name_max", `Issued by ${FORM_ERROR_MESSAGES.LONG}`, function (certificateIssuedBy) {
      return this.parent.is_auto_create_certificate ? !(String(certificateIssuedBy).length > 150) : true;
    })
    .test("certificate_issued_by", FORM_ERROR_MESSAGES.REQUIRED, function (certificateIssuedBy) {
      return this.parent.is_auto_create_certificate ? Boolean(certificateIssuedBy) : true;
    })
    .nullable(),
  certificate_valid: Yup.number()
    .test("certificate_valid_min", "Min value 1", function (certificateValid) {
      return this.parent.is_auto_create_certificate ? Number(certificateValid) > 0 : true;
    })
    .test("certificate_valid_decimal", "Invalid decimal", function (repeatEvery) {
      return this.parent.is_auto_create_certificate ? IS_ONLY_DIGITS.test(String(repeatEvery)) : true;
    }),
  certificate_valid_period: Yup.string()
    .test("certificate_valid_period", FORM_ERROR_MESSAGES.REQUIRED, function (certificateValidPeriod) {
      return this.parent.is_auto_create_certificate ? Boolean(certificateValidPeriod) : true;
    })
    .nullable(),
};

export const publishValidationSchema = Yup.object().shape({
  ...commonValidationSchema,
});

export const draftValidationSchema = Yup.object().shape({
  ...commonValidationSchema,
});

export const matchedCertificateTemplatesActivities = (
  formActivities: Option[],
  selectedCertificateTemplate: CertificateTemplate,
) => {
  const { activities: certificateTemplateActivities } = selectedCertificateTemplate;
  const matchedActivities: number[] = [];

  certificateTemplateActivities.forEach((certTplActivity) => {
    if (formActivities?.some((formActivity) => formActivity.value === certTplActivity.id)) {
      matchedActivities.push(certTplActivity.id);
    }
  });

  return matchedActivities;
};

export const getRightFormValidationSchema = (
  hasCertificateTemplate: boolean,
  matchedCertificateTemplatesActivities: number[],
  matchedCertificateTemplatesProjects: number[],
) =>
  Yup.object().shape({
    activities: Yup.array().test(
      "activities",
      `Certificate activities do not match with the training activities`,
      function (activities) {
        if (hasCertificateTemplate) {
          if (
            activities &&
            activities.length === matchedCertificateTemplatesActivities.length &&
            activities.every((option) => matchedCertificateTemplatesActivities.includes(Number(option.value)))
          ) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      },
    ),
    projects: Yup.array().test(
      "projects",
      `Certificate projects do not match with the training projects`,
      function (projects) {
        if (hasCertificateTemplate) {
          if (
            projects &&
            projects.length === matchedCertificateTemplatesActivities.length &&
            projects.every((option) => matchedCertificateTemplatesProjects.includes(Number(option.value)))
          ) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      },
    ),
  });

export const getFields = (options: GetFieldsOptions) => {
  const {
    isPassedTraining,
    isDisabledFields,
    onChangeAddress,
    isExpirationOrScheduledDate,
    isRecurringTraining,
    isAutoCreateCertificate,
    setIsExpirationOrScheduledDate,
    setIsRecurringTraining,
    setIsAutoCreateCertificate,
    onChangeScheduledDate,
    onChangeExpirationDate,
    training,
    onChangeCertificateTemplateName,
    onChangeCertificateTemplateIssuedBy,
    matchedCertificateTemplatesActivities,
    matchedCertificateTemplatesProjects,
    hasCertificateTemplate,
  } = options;

  const fieldsForLeftPartForm: FieldItem[] = [
    {
      type: FieldItemType.RENDER,
      renderType: RenderTypes.FIELD_LIST_LINE,
      wrapperClass: "form-row dates-row",
      name: "form-line11",
      fields: [
        {
          type: FieldItemType.TEXT,
          name: "name",
          label: "Name *",
          wrapperClass: "input-line",
          disabled: isPassedTraining || isDisabledFields,
        },
        {
          type: FieldItemType.TEXT,
          name: "held_by",
          label: "Held by",
          wrapperClass: "input-line",
          disabled: isPassedTraining || isDisabledFields,
        },
      ],
    },
    {
      type: FieldItemType.RICH_TEXT,
      name: "description",
      label: "Description",
      wrapperClass: "input-line",
      pathToUploadFile: API.SHARED.UPLOAD_FILE,
      disabled: isDisabledFields || isPassedTraining,
    },
    {
      type: FieldItemType.BLOCK,
      name: "devider-first",
      wrapperClass: "devider-first",
      content: <FormDivider />,
    },
    {
      type: FieldItemType.AUTOCOMPLETE_INPUT,
      loadingAction: sharedActions.getAddress,
      name: "location",
      wrapperClass: "input-line location-wrapper",
      label: "Address",
      onValueSelect: onChangeAddress,
      noMatchesLabel: "No matches found",
      onChangeField: (field, value) => {
        if (!value) {
          onChangeAddress({ value: undefined, label: "" });
        }
      },
      loadOnFocus: false,
      disabled: isPassedTraining || isDisabledFields,
    },
    {
      type: FieldItemType.RENDER,
      renderType: RenderTypes.FIELD_LIST_LINE,
      wrapperClass: "form-row expiration-date-row",
      name: "form-line21",
      fields: [
        {
          type: FieldItemType.DATEPICKER,
          name: "expiration_date",
          label: "Expiration Date",
          wrapperClass: "column-date",
          disabled: isPassedTraining || isDisabledFields || isExpirationOrScheduledDate,
          placeholder: "mm/dd/yyyy",
          onChange: (date) => onChangeExpirationDate(date),
          disablePast: !training,
        },
        {
          type: FieldItemType.BLOCK,
          wrapperClass: "column-or-label",
          name: "or",
          content: "or",
        },
        {
          type: FieldItemType.DATEPICKER,
          name: "scheduled_start_date",
          wrapperClass: "column-date",
          label: "Start Date",
          disabled: isPassedTraining || isDisabledFields || isExpirationOrScheduledDate,
          placeholder: "mm/dd/yyyy",
          onChange: (date) => onChangeScheduledDate(date, true),
          disablePast: !training,
        },
        {
          type: FieldItemType.TIMEPICKER,
          name: "scheduled_start_time",
          wrapperClass: "column-time",
          label: "Start Time",
          disabled: isPassedTraining || isDisabledFields || isExpirationOrScheduledDate,
          format: "hh:mm aa",
          onChange: (date) => onChangeScheduledDate(date, false),
        },
        {
          type: FieldItemType.DATEPICKER,
          name: "scheduled_end_date",
          label: "End Date",
          wrapperClass: "column-date",
          disabled: isPassedTraining || isDisabledFields || isExpirationOrScheduledDate,
          placeholder: "mm/dd/yyyy",
          onChange: (date) => onChangeScheduledDate(date, false),
          disablePast: !training,
        },
        {
          type: FieldItemType.TIMEPICKER,
          name: "scheduled_end_time",
          label: "End Time",
          wrapperClass: "column-time",
          disabled: isPassedTraining || isDisabledFields || isExpirationOrScheduledDate,
          format: "hh:mm aa",
          onChange: (date) => onChangeScheduledDate(date, false),
        },
      ],
    },
    {
      type: FieldItemType.CHECKBOX,
      name: "is_expiration_or_scheduled_date",
      label: "None",
      inputClass: "form-check-box-row",
      disabled: isPassedTraining || isDisabledFields,
      onChange: setIsExpirationOrScheduledDate,
    },
    {
      type: FieldItemType.RENDER,
      renderType: RenderTypes.FIELD_LIST_LINE,
      wrapperClass: "form-row expiration-recurring-row",
      name: "form-line31",
      fields: [
        {
          type: FieldItemType.CHECKBOX,
          name: "is_recurring_training",
          label: "Recurring Training",
          inputClass: "form-check-box-row",
          wrapperClass: "expiration-recurring-checkbox-wrapper",
          disabled: isPassedTraining || isDisabledFields,
          onChange: setIsRecurringTraining,
        },
        {
          type: FieldItemType.BLOCK,
          wrapperClass: "column-expiration-recurring-label",
          name: "expiration-recurring-label",
          content: "Repeat every",
        },
        {
          type: FieldItemType.NUMBER,
          name: "repeat_every",
          label: "",
          min: 1,
          wrapperClass: "repeat-every-number-wrapper",
          disabled: isPassedTraining || isDisabledFields || !isRecurringTraining,
        },
        {
          type: FieldItemType.SELECT,
          name: "repeat_every_period",
          label: "",
          wrapperClass: "repeat-every-period-select-wrapper",
          options: PERIOD_OPTIONS,
          placeholder: " ",
          disabled: isPassedTraining || isDisabledFields || !isRecurringTraining,
        },
      ],
    },
    {
      type: FieldItemType.CHECKBOX,
      name: "is_auto_create_certificate",
      label: "Auto-create a certificate if passed",
      inputClass: "form-check-box-row",
      disabled: isPassedTraining || isDisabledFields,
      onChange: setIsAutoCreateCertificate,
    },
    ...((isAutoCreateCertificate
      ? [
          {
            type: FieldItemType.AUTOCOMPLETE_INPUT,
            name: "certificate_name",
            label: "Certificate",
            wrapperClass: "input-line certificate-name-wrapper",
            disabled: isPassedTraining || isDisabledFields || !isAutoCreateCertificate,
            onChangeField: onChangeCertificateTemplateName,
          },
          {
            type: FieldItemType.RENDER,
            renderType: RenderTypes.FIELD_LIST_LINE,
            wrapperClass: "form-row cerificate-row",
            name: "form-line41",
            fields: [
              {
                type: FieldItemType.TEXT,
                name: "certificate_issued_by",
                label: "Issued by",
                wrapperClass: "input-line",
                disabled: isPassedTraining || isDisabledFields || !isAutoCreateCertificate,
                onChangeField: onChangeCertificateTemplateIssuedBy,
              },
              {
                type: FieldItemType.NUMBER,
                name: "certificate_valid",
                label: "Valid",
                min: 1,
                wrapperClass: "certificate-valid-wrapper",
                disabled: isPassedTraining || isDisabledFields || !isAutoCreateCertificate,
              },
              {
                type: FieldItemType.SELECT,
                name: "certificate_valid_period",
                label: "",
                wrapperClass: "certificate-valid-period-select-wrapper",
                options: PERIOD_OPTIONS,
                placeholder: " ",
                disabled: isPassedTraining || isDisabledFields || !isAutoCreateCertificate,
              },
            ],
          },
        ]
      : []) as FieldItem[]),
    {
      type: FieldItemType.RENDER,
      renderType: RenderTypes.FIELD_LIST_LINE,
      wrapperClass: "visibility-block",
      name: "name-line1",
      fields: [
        {
          type: FieldItemType.BLOCK,
          name: "visibility_header",
          wrapperClass: "visibility-header",
          content: "Visibility",
        },
        {
          type: FieldItemType.CHECKBOX,
          name: "is_public",
          label: "Visible to everyone",
          inputClass: "form-check-box-row",
          disabled: isDisabledFields,
        },
      ],
    },
  ];

  const fieldsForRightPartForm: FieldItem[] = [
    {
      type: FieldItemType.AUTOCOMPLETE_SELECT_MULTI,
      name: "activities",
      label: "Activities",
      wrapperClass: "input-line",
      options: [],
      placeholder: "Select Activity",
      disabled: isPassedTraining || isDisabledFields,
      highlightValues: matchedCertificateTemplatesActivities,
      invertHighlight: hasCertificateTemplate,
    },
    {
      type: FieldItemType.AUTOCOMPLETE_SELECT_MULTI,
      name: "projects",
      label: "Projects",
      wrapperClass: "input-line",
      options: [],
      placeholder: "Select Project",
      disabled: isPassedTraining || isDisabledFields,
      highlightValues: matchedCertificateTemplatesProjects,
      invertHighlight: hasCertificateTemplate,
    },
  ];

  return [fieldsForLeftPartForm, fieldsForRightPartForm];
};

export const getInitValues = (training: Training | null): [TrainingLeftFormShape, TrainingRightFormShape] => {
  const initValuesForLeftPartForm = {
    status: TrainingStatus.Active,
    name: training?.name || "",
    held_by: training?.held_by || "",
    description: training?.description || "",
    location: training?.location?.address || "",
    location_place_id: training?.location?.place_id || "",
    members: training ? training.members : [],
    is_expiration_or_scheduled_date: !(training ? Boolean(training && training.is_expiration_or_scheduled_date) : true),
    expiration_date: training && training.expiration_date ? convertDateToEstTz(training.expiration_date) : null,
    scheduled_start_date:
      training && training.scheduled_start_date ? convertDateToEstTz(training.scheduled_start_date) : null,
    scheduled_start_time:
      training && training.scheduled_start_date ? convertDateToEstTz(training.scheduled_start_date) : null,
    scheduled_end_date:
      training && training.scheduled_end_date ? convertDateToEstTz(training.scheduled_end_date) : null,
    scheduled_end_time:
      training && training.scheduled_end_date ? convertDateToEstTz(training.scheduled_end_date) : null,
    is_recurring_training: Boolean(training && training.is_recurring_training),
    repeat_every: training && training.repeat_every ? String(training.repeat_every) : "",
    repeat_every_period: training && training.repeat_every_period ? training.repeat_every_period : null,

    is_auto_create_certificate: Boolean(training && training.is_auto_create_certificate),
    certificate_name: training && training.certificate_name ? String(training.certificate_name) : "",
    certificate_issued_by: training && training.certificate_issued_by ? String(training.certificate_issued_by) : "",
    certificate_valid: training && training.certificate_valid ? String(training.certificate_valid) : "",
    certificate_valid_period:
      training && training.certificate_valid_period ? String(training.certificate_valid_period) : null,
    is_public: Boolean(training?.is_public),
  };

  const initValuesForRightPartForm = {
    activities: training ? training.activities.map(prepareOptionFunction) : [],
    projects: training ? training.projects.map(prepareOptionFunction) : [],
  };

  return [initValuesForLeftPartForm, initValuesForRightPartForm];
};

export const handlers = (companyCode?: string | null) => ({
  location: {
    getData: sharedActions.getAddress.request,
    clearData: sharedActions.getAddress.success,
    selectData: sharedSelectors.getAddress,
    prepareOptionFunction: (item: { address: string; place_id: string }) => {
      return { label: item.address, value: item.place_id };
    },
  },
  activities: {
    getData: (search: string, page?: number) =>
      activityActions.getActivities.request({
        search,
        page: page || 0,
        limit: 10,
        company_code: companyCode,
        sort_order: ORDER_TYPE.ASC,
        sort_by: ["name"],
        status: ActivityStatus.Active,
      }),
    selectData: activitySelectors.getActivities,
    selectTotalCount: activitySelectors.getActivitiesTotal,
    prepareOptionFunction: (item: Activity) => {
      return { label: item.name, value: item.id || null };
    },
  },
  projects: {
    getData: (search: string, page?: number) =>
      projectActions.getProjects.request({
        page: page || 0,
        limit: 10,
        search,
        company_code: companyCode,
        status: ProjectStatus.Active,
      }),
    selectData: projectSelectors.getProjects,
    selectTotalCount: projectSelectors.getProjectsTotal,
    prepareOptionFunction: prepareProjectOptionFunction,
  },
  certificate_issued_by: {
    getData: (search: { search: string }, page?: number) =>
      certificateActions.getCertificatesIssuedBy.request({
        page: page || 0,
        limit: 10,
        search: search.search,
        company_code: companyCode,
      }),
    selectData: certificateSelectors.getCertificatesIssuedBy,
    selectTotalCount: certificateSelectors.getCertificatesIssuedByTotal,
    prepareOptionFunction: prepareCertificateIssuedByFunction,
  },
  certificate_name: {
    getData: (search: { search: string }, page?: number) =>
      certificateActions.getCertificatesTemplates.request({
        page: page || 0,
        limit: 10,
        search: search.search,
        company_code: companyCode,
      }),
    selectData: certificateSelectors.getCertificatesTemplates,
    selectTotalCount: certificateSelectors.getCertificatesIssuedByTotal,
    prepareOptionFunction: prepareCertificateNameFunction,
  },
});

export const convertFormData = (
  data: TrainingLeftFormShape & TrainingRightFormShape,
  companyCode?: string,
): TrainingFormShapeToRequest => {
  const isExpirationOrScheduledDate = !data.is_expiration_or_scheduled_date;
  const isRecurringTraining = data.is_recurring_training;
  const isAutoCreateCertificate = data.is_auto_create_certificate;

  const startDateString = data.scheduled_start_date ? moment(data.scheduled_start_date).format("YYYY-MM-DD") : null;
  const startTimeString = data.scheduled_start_time ? moment(data.scheduled_start_time).format("HH:mm") : null;
  const endDateString = data.scheduled_end_date ? moment(data.scheduled_end_date).format("YYYY-MM-DD") : null;
  const endTimeString = data.scheduled_end_time ? moment(data.scheduled_end_time).format("HH:mm") : null;
  const expirationDateString = data.expiration_date ? moment(data.expiration_date).format("YYYY-MM-DD") : null;

  return {
    company_code: companyCode,
    status: data.status,
    name: data.name,
    held_by: data.held_by,
    description: data.description,
    location_place_id: data.location_place_id || null,
    members: data.members.map((m) => m.id),
    activities: data?.activities?.map((a) => Number(a.value)) || [],
    projects: data?.projects?.map((p) => Number(p.value)) || [],
    is_expiration_or_scheduled_date: isExpirationOrScheduledDate,
    expiration_date:
      data.expiration_date && isExpirationOrScheduledDate
        ? getDateWithTimezone(`${expirationDateString} 23:59`).toISOString()
        : null,
    scheduled_start_date:
      data.scheduled_start_date && isExpirationOrScheduledDate
        ? getDateWithTimezone(`${startDateString} ${startTimeString}`).toISOString()
        : null,
    scheduled_end_date:
      data.scheduled_start_date && isExpirationOrScheduledDate
        ? getDateWithTimezone(`${endDateString} ${endTimeString}`).toISOString()
        : null,
    is_recurring_training: isRecurringTraining,
    repeat_every: isRecurringTraining && data.repeat_every ? Number(data.repeat_every) : null,
    repeat_every_period: isRecurringTraining && data.repeat_every_period ? data.repeat_every_period : null,
    is_auto_create_certificate: isAutoCreateCertificate,

    certificate_name: isAutoCreateCertificate && data.certificate_name ? data.certificate_name : null,
    certificate_issued_by: isAutoCreateCertificate && data.certificate_issued_by ? data.certificate_issued_by : null,
    certificate_valid: isAutoCreateCertificate && data.certificate_valid ? Number(data.certificate_valid) : null,
    certificate_valid_period:
      isAutoCreateCertificate && data.certificate_valid_period ? data.certificate_valid_period : null,
    is_public: data.is_public,
  };
};
