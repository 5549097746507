import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";

export interface FormDividerProps {
  className?: string;
}

const FormDivider: FC<FormDividerProps> = (props) => <div className={classnames("form-divider", props.className)} />;

export default FormDivider;
