import React, { FC, useMemo } from "react";
import classnames from "classnames";
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";

import "./index.scss";

export interface DatePickerProps {
  className?: string;
  label?: string;
  onChange: (data: Array<moment.Moment | null>) => void;
  value: [moment.Moment | null, moment.Moment | null];
  disablePast?: boolean;
  disabled?: boolean;
  showReset?: boolean;
  showCancel?: boolean;
  resetValues?: [Date | null, Date | null];
  cleanable: boolean;
}

const CustomDateRangePicker: FC<DatePickerProps> = (props) => {
  const { className, label, onChange, value, disabled, showReset = false, showCancel, resetValues, cleanable } = props;

  const currentValue = useMemo<[(Date | undefined)?, (Date | undefined)?]>(() => {
    const [start, end] = value;
    return [start ? start.toDate() : undefined, end ? end.toDate() : undefined];
  }, [value]);

  const ranges = useMemo(() => {
    // eslint-disable-next-line
    const result: any[] = [];
    if (showCancel) {
      result.push({
        label: "Cancel",
        value: currentValue,
        closeOverlay: true,
      });
    }
    if (showReset) {
      result.push({
        label: "Reset",
        value: resetValues,
      });
    }
    return result;
  }, [showCancel, showReset, resetValues, currentValue]);
  return (
    <div className={classnames("custom-date-range-picker input-wrapper", className)}>
      {label && <label className="custom-input-label">{label}</label>}
      <DateRangePicker
        value={currentValue as [Date, Date]}
        onChange={(value) => {
          const [start, end] = (value ? value : [null, null]) as [Date, Date];
          onChange([start ? moment(start) : null, end ? moment(end) : null]);
        }}
        disabled={disabled}
        cleanable={cleanable}
        ranges={ranges}
        character=" - "
        placement="bottom"
        className="custom-date-range-picker-wrapper"
        format="MM/dd/yyyy"
        placeholder="mm/dd/yyyy - mm/dd/yyyy"
      />
    </div>
  );
};
export default CustomDateRangePicker;
