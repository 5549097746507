import { PaginatedResponse, ActionWithPayload, CodeWithId, Filter, BaseEntity } from "@shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "@shared/store";
import { Team } from "@shared/models";

import api from "../api";
import { CreateUpdateTeamSuccess, DeleteTeamResponse, TeamFormShapeToRequest } from "../interface";
import { createTeam, deleteTeam, getTeam, getTeams, updateTeam } from "./actions";

function* getTeamsSaga({ payload }: ActionWithPayload<Partial<Filter>>) {
  try {
    const response: PaginatedResponse<BaseEntity> = yield call(api.getTeams, payload);
    yield put(getTeams.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getTeams.failure(error as Error));
  }
}

function* getTeamSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(actions.startLoading());
    const response: Team = yield call(api.getTeam, payload);
    yield put(actions.stopLoading());
    yield put(getTeam.success(response));
  } catch (error) {
    yield put(getTeam.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* createTeamSaga({ payload }: ActionWithPayload<CodeWithId<TeamFormShapeToRequest>>) {
  try {
    yield put(actions.startLoading());
    const response: CreateUpdateTeamSuccess = yield call(api.createTeam, payload);
    yield put(actions.stopLoading());
    yield put(createTeam.success(response));
    yield put(actions.hideOverlay());
  } catch (error) {
    yield put(createTeam.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* updateTeamSaga({ payload }: ActionWithPayload<Required<CodeWithId<TeamFormShapeToRequest>>>) {
  try {
    yield put(actions.startLoading());
    const response: CreateUpdateTeamSuccess = yield call(api.updateTeam, payload);
    yield put(actions.stopLoading());
    yield put(updateTeam.success(response));
    yield put(actions.hideOverlay());
  } catch (error) {
    yield put(updateTeam.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* deleteTeamSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(actions.startLoading());
    const response: DeleteTeamResponse = yield call(api.deleteTeam, payload);
    yield put(actions.stopLoading());
    yield put(deleteTeam.success(response));
    yield put(actions.hideOverlay());
  } catch (error) {
    yield put(deleteTeam.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* teamSagas() {
  yield takeLatest(getTeams.request, getTeamsSaga);
  yield takeLatest(getTeam.request, getTeamSaga);
  yield takeLatest(createTeam.request, createTeamSaga);
  yield takeLatest(updateTeam.request, updateTeamSaga);
  yield takeLatest(deleteTeam.request, deleteTeamSaga);
}

export default teamSagas;
