import { BaseEntity, BaseModel, BaseCompany, BaseEntityWithVersion } from "@shared/interfaces";
import { CertificateTemplate } from "@shared/models";

import { Creator } from "./";

export enum ActivityStatus {
  Archived = "Archived",
  Active = "Active",
}

interface BaseActivity extends BaseModel {
  name: string;
  status: ActivityStatus;
  creator: Creator;
  parent_activity: BaseEntity | null;
  companies_count: string;
  version: number;
  updated_at: string | null;
}

export interface ActivityListItem extends BaseActivity {
  jsa_count: string;
  trainings_count: string;
  industries_count: string;
  current_company_count?: string;
  jsa?: BaseEntity[];
  certificate_templates?: CertificateTemplate[];
}

export interface Activity extends BaseActivity {
  industries: BaseEntity[];
  jsa: BaseEntityWithVersion[];
  trainings: BaseEntity[];
  available_jsa: { id: number; parent_jsa_id: number }[];
  company: BaseCompany | null;
  current_company_count: string;
  assignments_count: string;
  last_assignment_date: string | null;
  certificate_templates?: CertificateTemplate[];
}
