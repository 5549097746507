import React, { useState } from "react";
import { Button, FormGenerator } from "@shared/components";
import { Formik } from "formik";

import { NoteFormShape } from "../../interfaces";
import { validationSchema, fields } from "./formHelpers";
import "./index.scss";

export interface NoteFormProps {
  onCancel: () => void;
  onSubmit: (value: NoteFormShape) => void;
}

const NoteForm = (props: NoteFormProps) => {
  const { onCancel, onSubmit } = props;
  const [formValues] = useState<NoteFormShape>({ description: "" });

  return (
    <div className="note-form">
      <Formik
        validationSchema={validationSchema}
        initialValues={formValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit(values);
        }}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}>
        {(formikProps) => (
          <FormGenerator {...formikProps} fields={fields} formikProps={formikProps}>
            <div className="submit-wrapper">
              <Button buttonStyle="bordered" type="button" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </FormGenerator>
        )}
      </Formik>
    </div>
  );
};

export default NoteForm;
