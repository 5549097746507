import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { Filter, PayloadWithId, CreateFileShape } from "@shared/interfaces";
import {
  DeleteJsaFileShape,
  JsaFormShapeToRequest,
  UpdateJsaStatusShape,
  JsaImportShape,
} from "@containers/Jsa/interface";

export default {
  getJsa: (payload: Partial<Filter>) => request(METHODS.GET, API.JSA.GET_JSA)({ params: payload }),
  getJsaItem: (payload: Required<{ id: string | number }>) => {
    return request(METHODS.GET, API.JSA.GET_JSA_ITEM(payload.id))();
  },
  createJsa: (payload: JsaFormShapeToRequest) => request(METHODS.POST, API.JSA.CREATE_JSA)(payload),
  updateJsa: (payload: PayloadWithId<JsaFormShapeToRequest>) => {
    return request(METHODS.PUT, API.JSA.UPDATE_JSA(payload.id))(payload.payload);
  },
  updateJsaStatus: (payload: Required<PayloadWithId<UpdateJsaStatusShape>>) => {
    return request(METHODS.PUT, API.JSA.UPDATE_JSA_STATUS(payload.id))(payload.payload);
  },
  getJsaFiles: (payload: Required<{ id: string | number }>) => {
    return request(METHODS.GET, API.JSA.GET_JSA_FILES(payload.id))();
  },
  addJsaFile: (payload: Required<PayloadWithId<CreateFileShape>>) => {
    return request(METHODS.POST, API.JSA.ADD_JSA_FILE(payload.id))(payload.payload);
  },
  deleteJsaFile: (payload: DeleteJsaFileShape) => {
    return request(METHODS.DELETE, API.JSA.DELETE_JSA_FILE(payload.jsa_id, payload.id))();
  },
  importJsa: (payload: JsaImportShape) => request(METHODS.POST, API.JSA.IMPORT_JSA)(payload),
};
