import React, { FC, useMemo } from "react";
import { FormCard, LocationLabel, LabelArray, DateTimeHeader, Icon, Button } from "@shared/components";
import { Training, TrainingPass, MemberTrainingStatus } from "@shared/models";
import { TrainingViewCertificate } from "@containers/TrainingAndCertificate/components";
import { useTrainingExtraInfo } from "@containers/TrainingAndCertificate/hooks";
import classNames from "classnames";

import "./index.scss";

export interface TrainingViewMemberProps {
  training: Training;
  trainingPass: TrainingPass[];
  memberId?: number;
  onCompleteClick?: () => void;
  onConfirmClick?: () => void;
  isTeamMember?: boolean;
}

const TrainingViewMember: FC<TrainingViewMemberProps> = (props) => {
  const { training, trainingPass, memberId, onConfirmClick, onCompleteClick, isTeamMember } = props;

  const { updateInfo, recurringInfo, status, statusText, confirmedText } = useTrainingExtraInfo(
    training,
    trainingPass[0],
  );

  const trainingDescription = useMemo(() => {
    if (!training.description) return null;

    return (
      <div
        className="form-card-row training-view-description"
        dangerouslySetInnerHTML={{ __html: training.description }}
      />
    );
  }, [training]);

  const trainingRecurring = useMemo(() => {
    if (!recurringInfo) {
      return null;
    }
    return <div className="training-recurring">{recurringInfo}</div>;
  }, [recurringInfo]);

  const activitiesList = useMemo(() => {
    return training.activities.length ? (
      <LabelArray className="training-label-array" items={training.activities} title="Activities" />
    ) : null;
  }, [training]);

  const projectsList = useMemo(() => {
    return training.projects.length ? (
      <LabelArray className="training-label-array" items={training.projects} title="Projects" />
    ) : null;
  }, [training]);

  const trainingActivitiesProjects = useMemo(() => {
    return (
      <div className="form-card-row">
        {activitiesList}
        {projectsList}
      </div>
    );
  }, [activitiesList, projectsList]);

  const trainingStatusRow = useMemo(() => {
    const trainingStatusHint = (
      <div className="training-status-hint">Please use mobile application to complete the training</div>
    );

    if (isTeamMember) {
      return (
        <div className="training-status-wrapper">
          <div className={classNames("training-status-value", status.toLowerCase())}>{statusText}</div>
          {trainingStatusHint}
        </div>
      );
    }

    switch (status) {
      case MemberTrainingStatus.SCHEDULED:
        return (
          <div className="training-status-wrapper">
            <div className={classNames("training-status-value", status.toLowerCase())}>{statusText}</div>
            {!memberId && trainingStatusHint}
          </div>
        );
      case MemberTrainingStatus.MISSED:
        return (
          <div className="training-status-wrapper">
            <div className={classNames("training-status-value", status.toLowerCase())}>
              <Icon type="warning" />
              {statusText}
            </div>
            {trainingStatusHint}
          </div>
        );
      case MemberTrainingStatus.COMPLITED:
        return (
          <div className="training-status-wrapper">
            <div className={classNames("training-status-value", status.toLowerCase())}>{statusText}</div>
            {Boolean(memberId) && <div className="training-status-hint">{confirmedText}</div>}
          </div>
        );
    }
  }, [status, memberId, confirmedText, statusText, isTeamMember]);

  const trainingTitleRow = useMemo(() => {
    if (!memberId) {
      return null;
    }
    return <div className="form-card-row training-title">{training.name}</div>;
  }, [memberId, training]);

  const trainingButtonRow = useMemo(() => {
    if (isTeamMember || !memberId || trainingPass[0]?.confirm_user) {
      return null;
    }
    const button =
      status === MemberTrainingStatus.COMPLITED ? (
        <Button onClick={onConfirmClick} className="training-member-button">
          <Icon className="check-icon" type="check-white" />
          Mark As Passed
        </Button>
      ) : (
        <Button onClick={onCompleteClick} buttonStyle="accent-green" className="training-member-button">
          <Icon className="check-icon" type="check-white" />
          Complete
        </Button>
      );
    return <div className="form-card-row training-member-buttons">{button}</div>;
  }, [memberId, status, onConfirmClick, onCompleteClick, trainingPass, isTeamMember]);

  const trainingUpdate = useMemo(() => {
    return <div className="form-card-row training-view-update-info">{updateInfo}</div>;
  }, [updateInfo]);

  const startDate = useMemo(() => {
    if (!trainingPass.length) {
      return training.scheduled_start_date || training.expiration_date;
    }
    return trainingPass[0].created_at;
  }, [trainingPass, training]);

  return (
    <div className="training-view-member-wrapper">
      <FormCard className="training-view-card training-view-card-general">
        {trainingTitleRow}
        <div className="form-card-row training-date-info">
          {trainingStatusRow}
          <div className="training-pass-date-wrapper">
            <DateTimeHeader
              startDate={startDate}
              endDate={trainingPass.length ? undefined : training.scheduled_end_date}
              onlyDate={!training.scheduled_start_date && !trainingPass.length}
            />
            {trainingPass[0]?.signature && (
              <div className="training-pass-signature">
                <div className="training-pass-signature-title">Signature:</div>
                <div className="training-pass-signature-image">
                  <img src={trainingPass[0].signature.file.url} />
                </div>
              </div>
            )}
          </div>
          {trainingRecurring}
        </div>
        {trainingButtonRow}
        {training.location && <LocationLabel className="form-card-row" location={training.location} />}
        <TrainingViewCertificate training={training} trainingPass={trainingPass[0]} isTeamMember={true} />
        {trainingActivitiesProjects}
        {memberId && trainingUpdate}
      </FormCard>
      {trainingDescription && <FormCard className="training-view-card">{trainingDescription}</FormCard>}
    </div>
  );
};

export default TrainingViewMember;
