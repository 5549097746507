import Ajv from "ajv";
import authSchema from "@containers/Auth/api/schemas";
import userSchema from "@containers/User/api/schemas";
import companySchema from "@containers/Company/api/schemas";
import memberSchema from "@containers/Member/api/schemas";
import activitySchema from "@containers/Activity/api/schemas";
import taskSchema from "@containers/Task/api/schemas";
import jsaSchema from "@containers/Jsa/api/schemas";
import trainingAndCertificateSchema from "@containers/TrainingAndCertificate/api/schemas";
import assignmentSchema from "@containers/Assignment/api/schemas";
import documentSchema from "@containers/Documents/api/schemas";
import teamSchema from "@containers/Team/api/schemas";
import projectSchema from "@containers/Project/api/schemas";
import sharedSchema from "@shared/api/schemas";

const ajv = new Ajv({ schemaId: "auto" });

const allSchemas = {
  ...authSchema,
  ...userSchema,
  ...companySchema,
  ...memberSchema,
  ...activitySchema,
  ...taskSchema,
  ...jsaSchema,
  ...trainingAndCertificateSchema,
  ...assignmentSchema,
  ...documentSchema,
  ...teamSchema,
  ...projectSchema,
  ...sharedSchema,
};

const compiledSchemas = Object.entries(allSchemas).reduce((acc, [key, value]) => {
  acc[key] = ajv.compile(value);
  return acc;
}, {} as { [key: string]: Ajv.ValidateFunction });

export default compiledSchemas;
