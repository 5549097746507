import { AnyType } from "@shared/interfaces";

export enum TIMEZONE {
  PST = "America/Los_Angeles",
  EST = "America/New_York",
  UTC = "UTC",
}

export const LAST_COMPANY_KEY = "last-company";
export const MAX_AVATAR_SIZE = 5000000;
export const MAX_INPUT_FILE_SIZE = 5000000;
export const MAX_IMAGE_SIZE = 1000000;

export enum ROLE_CODE {
  SUPER_ADMIN = "Super Admin",
  COMPANY_ADMIN = "Company Admin",
  SUPERVISOR = "Supervisor",
  TEAM_MEMBER = "Team Member",
}

export enum PERIOD {
  WEEK = "Week",
  MONTH = "Month",
  YEAR = "Year",
}

export const PERIOD_OPTIONS = [
  { value: PERIOD.WEEK, label: PERIOD.WEEK },
  { value: PERIOD.MONTH, label: PERIOD.MONTH },
  { value: PERIOD.YEAR, label: PERIOD.YEAR },
];

export const EMPTY_ARRAY: AnyType[] = [];

export const ACCEPT_FILE_TYPES = ".doc, .docx, .jpg, .jpeg, .psd, .pdf, .txt, .xls, .xlsx, .png";
