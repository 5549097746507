import { BaseEntity, BaseModel } from "@shared/interfaces";

import { Task, Creator } from "./";

export enum JsaStatus {
  Archived = "Archived",
  Active = "Active",
  Draft = "Draft",
}

interface BaseJsa extends BaseModel {
  name: string;
  status: JsaStatus;
}

export interface JsaListItem extends BaseJsa {
  id: number;
  creator: Creator | null;
  activities: BaseEntity[];
}

export interface Jsa extends BaseJsa {
  company_id: number | null;
  is_deleted: boolean;
  updated_at: string | null;
  activities: BaseEntity[];
  tasks: Task[];
}

export interface JsaCreateRequest {
  name: string;
  company_code: string | null;
  activities: number[];
  tasks: number[];
  status: JsaStatus.Active | JsaStatus.Draft;
}
