import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import {
  UserFormShape,
  UserFormUpdateShape,
  UpdateStatusShape,
  CheckUserExistsShape,
  GetUserShape,
} from "@containers/User/interface";
import { Filter } from "@shared/interfaces";

export default {
  getUsers: (payload: Filter) => request(METHODS.GET, API.USER.GET_USERS)({ params: payload }),
  getUser: (payload: GetUserShape) =>
    request(METHODS.GET, API.USER.GET_USER(payload.id))({ params: { company_code: payload.company_code } }),
  createUser: (payload: UserFormShape) => request(METHODS.POST, API.USER.CREATE_USER)(payload),
  updateUser: (payload: UserFormUpdateShape) => {
    const { id, ...restPayload } = payload;
    return request(METHODS.PUT, API.USER.UPDATE_USER(id))(restPayload);
  },
  updateUserStatus: (payload: UpdateStatusShape) => {
    const { id, ...restPayload } = payload;
    return request(METHODS.PUT, API.USER.UPDATE_USER_STATUS(id))(restPayload);
  },
  checkUserExists: (payload: CheckUserExistsShape) =>
    request(
      METHODS.GET,
      API.USER.CHECK_USER_EXISTS,
    )({ params: { email: payload.email, company_code: payload.company_code } }),
};
