import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";

const selectTeam = (state: AppState) => state.team;

export const getTeams = () => createSelector(selectTeam, (state) => state.teams);
export const getTeamsTotal = () => createSelector(selectTeam, (state) => state.teamsTotal);
export const getCurrentTeam = () => createSelector(selectTeam, (state) => state.currentTeam);

export const getFilter = () => createSelector(selectTeam, (state) => state.filter);
