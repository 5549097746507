import React, { FC } from "react";

import "./index.scss";

export interface AddSelectOptionProps {
  onClick?: () => void;
  buttonText?: string | React.ReactNode;
  hintText?: React.ReactNode | string;
}

const AddSelectOption: FC<AddSelectOptionProps> = (props) => {
  const { onClick, buttonText = "+ Add", hintText } = props;

  return (
    <div className="add-select-option" onClick={onClick}>
      <div className="add-select-option-hint">{hintText}</div>
      <div className="add-select-option-button">{buttonText}</div>
    </div>
  );
};

export default AddSelectOption;
