import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectors } from "@shared/store";
import { ROLE_CODE } from "@shared/constants";

function useTeamMemberHook(companyCode?: string | null) {
  const user = useSelector(selectors.getUserDetails());

  const isTeamMember = useMemo(() => {
    return user?.user_roles.some((r) => r.role.code === ROLE_CODE.TEAM_MEMBER && r.company?.code === companyCode);
  }, [user, companyCode]);

  return { isTeamMember };
}

export default useTeamMemberHook;
