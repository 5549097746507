export const createSupportMessageRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["text"],
  properties: {
    subject: { type: ["string", "null"] },
    text: { type: "string" },
    version: { type: "string" },
  },
};

export const createSupportMessageResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message"],
  properties: {
    message: { type: "string" },
  },
};
