import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { generateDynamicSchemaKeyString, mapToSchema } from "@shared/utils";
import {
  getMembersResponseSchema,
  getMembersAssignmentsResponseSchema,
  membersAssignmentsFilterSchema,
  filterSchema,
  createMemberRequestSchema,
  updateMemberRequestSchema,
  createMemberResponseSchema,
  fullMemberSchema,
  updateMemberStatusRequestSchema,
  membersTrainingAndCertificateFilterSchema,
  getMembersTrainingsResponseSchema,
  getMembersCertificatesResponseSchema,
  getMembersJsaResponseSchema,
  setMemberSignatureRequestSchema,
  setMemberSignatureResponseSchema,
  getMemberJsaResponseSchema,
  deleteMemberSignatureResponseSchema,
  filesListSchema,
  memberNoteListSchema,
  createFileRequest,
  createFileResponse,
  createMemberNoteRequestSchema,
  createMemberNoteResponseSchema,
} from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.MEMBER> = {
  [API_ENUM.GET_MEMBERS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getMembersResponseSchema,
    },
  },
  [API_ENUM.GET_MEMBERS_ASSIGNMENTS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_ASSIGNMENTS("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: membersAssignmentsFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_ASSIGNMENTS("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getMembersAssignmentsResponseSchema,
    },
  },
  [API_ENUM.GET_MEMBERS_TRAININGS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_TRAININGS("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: membersTrainingAndCertificateFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_TRAININGS("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getMembersTrainingsResponseSchema,
    },
  },
  [API_ENUM.GET_MEMBERS_CERTIFICATES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_CERTIFICATES("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: membersTrainingAndCertificateFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_CERTIFICATES("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getMembersCertificatesResponseSchema,
    },
  },
  [API_ENUM.GET_MEMBERS_JSA]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_JSA("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBERS_JSA("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getMembersJsaResponseSchema,
    },
  },
  [API_ENUM.CREATE_MEMBER]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.CREATE_MEMBER("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createMemberRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.CREATE_MEMBER("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createMemberResponseSchema,
    },
  },
  [API_ENUM.UPDATE_MEMBER]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.UPDATE_MEMBER("$id", "_id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: updateMemberRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.UPDATE_MEMBER("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: createMemberResponseSchema,
    },
  },
  [API_ENUM.UPDATE_MEMBER_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.UPDATE_MEMBER_STATUS("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: updateMemberStatusRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.UPDATE_MEMBER_STATUS("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: createMemberResponseSchema,
    },
  },
  [API_ENUM.GET_MEMBER]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER("$id", "_id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER("$id", "_id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: fullMemberSchema,
    },
  },

  [API_ENUM.GET_MEMBER_FILES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER_FILES("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER_FILES("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: filesListSchema,
    },
  },
  [API_ENUM.ADD_MEMBER_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.ADD_MEMBER_FILE("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createFileRequest,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.ADD_MEMBER_FILE("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: createFileResponse,
    },
  },

  [API_ENUM.DELETE_MEMBER_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.DELETE_MEMBER_FILE("$id", "_id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.DELETE_MEMBER_FILE("$id", "_id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: {},
    },
  },

  [API_ENUM.GET_MEMBER_NOTES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER_NOTES("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER_NOTES("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: memberNoteListSchema,
    },
  },
  [API_ENUM.ADD_MEMBER_NOTE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.ADD_MEMBER_NOTE("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createMemberNoteRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.ADD_MEMBER_NOTE("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: createMemberNoteResponseSchema,
    },
  },

  [API_ENUM.DELETE_MEMBER_NOTE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.DELETE_MEMBER_NOTE("$id", "$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.DELETE_MEMBER_NOTE("$id", "$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: {},
    },
  },
  [API_ENUM.SET_MEMBER_SIGNATURE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.SET_MEMBER_SIGNATURE("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: setMemberSignatureRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.SET_MEMBER_SIGNATURE("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: setMemberSignatureResponseSchema,
    },
  },
  [API_ENUM.DELETE_MEMBER_SIGNATURE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.DELETE_MEMBER_SIGNATURE("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.DELETE_MEMBER_SIGNATURE("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: deleteMemberSignatureResponseSchema,
    },
  },
  [API_ENUM.GET_MEMBER_PASSED_JSA]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER_PASSED_JSA("$id", "_id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.MEMBER.GET_MEMBER_PASSED_JSA("$id", "_id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getMemberJsaResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
