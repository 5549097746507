import React, { FC } from "react";
import { TrainingListItem } from "@shared/models";
import { Table } from "@shared/components";
import { SortTable } from "@shared/interfaces";

import { trainingTableProperties } from "./tableHelpers";

export interface TrainingTableProps extends SortTable {
  trainings: TrainingListItem[];
  onLoadNextPage: () => void;
  onClickTraining: (training: TrainingListItem) => void;
}

const TrainingTable: FC<TrainingTableProps> = (props) => {
  const { trainings, onLoadNextPage, onClickTraining, sortBy, sortOrder, onChangeSorting } = props;

  return (
    <div className="trainings-table">
      <Table<TrainingListItem>
        onRowClick={onClickTraining}
        onFinishScroll={onLoadNextPage}
        items={trainings}
        properties={trainingTableProperties}
        sortOrder={sortOrder}
        sortBy={sortBy}
        onChangeSorting={onChangeSorting}
      />
    </div>
  );
};

export default TrainingTable;
