import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from "@shared/store/selectors";
import { useParams } from "react-router";

import { RegistrationForm, AuthModal } from "../../components";
import { RegistrationShape } from "../../interface";
import { actions, selectors } from "../../store";

const RegistrationContainer: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading());
  const activateUserInfo = useSelector(selectors.getActivateUserInfo());

  const { hash } = useParams();

  useEffect(() => {
    if (hash) {
      dispatch(actions.activateAccount.request({ hash }));
    }
  }, [dispatch, hash]);

  useEffect(() => {
    return () => {
      dispatch(actions.activateAccount.success(null));
    };
  }, [dispatch]);

  const handleSubmit = useCallback(
    (values: RegistrationShape) => {
      if (hash) {
        dispatch(
          actions.registration.request({
            hash,
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
          }),
        );
      }
    },
    [dispatch, hash],
  );

  return activateUserInfo ? (
    <AuthModal title="Create Account">
      <RegistrationForm loading={loading} submitHandler={handleSubmit} user={activateUserInfo} />
    </AuthModal>
  ) : null;
};

export default RegistrationContainer;
