import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "@shared/constants";
import { AlertListItem, AlertStatus } from "@shared/models";

import { AlertStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: AlertStateType = {
  alerts: [],
  alertsTotal: 0,
  currentAlert: null,
  filter: defaultFilter,
};

const reducer = createReducer<AlertStateType, Action>(initialState)
  .handleAction(actions.getAlerts.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.alerts = clear ? rows : [...nextState.alerts, ...rows];
      nextState.alertsTotal = count;
    }),
  )
  .handleAction(actions.getAlert.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentAlert = action.payload;
    }),
  )
  .handleAction(actions.createAlert.success, (state, action) =>
    produce(state, (nextState) => {
      const { alert } = action.payload;
      const alertListItem: AlertListItem = {
        ...alert,
        members_count: alert.members.length.toString(),
      };
      nextState.alerts = [alertListItem, ...nextState.alerts];
      nextState.alertsTotal = nextState.alertsTotal + 1;
    }),
  )
  .handleAction(actions.updateAlert.success, (state, action) =>
    produce(state, (nextState) => {
      const { alert } = action.payload;
      const alertListItem: AlertListItem = {
        ...alert,
        members_count: alert.members.length.toString(),
      };
      const index = nextState.alerts.findIndex((u) => u.id === alertListItem.id);
      if (index >= 0) {
        nextState.alerts[index] = alertListItem;
      }
      if (state.currentAlert && state.currentAlert.id === alert.id) {
        nextState.currentAlert = alert;
      }
    }),
  )
  .handleAction(actions.deleteAlert.success, (state, action) =>
    produce(state, (nextState) => {
      const { alert_id } = action.payload;
      const index = nextState.alerts.findIndex((u) => u.id === alert_id);
      if (index >= 0) {
        nextState.alerts[index] = {
          ...nextState.alerts[index],
          status: AlertStatus.Archived,
        };
      }
      if (nextState.currentAlert && nextState.currentAlert.id === alert_id) {
        nextState.currentAlert = {
          ...nextState.currentAlert,
          status: AlertStatus.Archived,
        };
      }
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  );

export { reducer as AlertReducer };
