import { TableProperty } from "@shared/components";
import { getUSFormattedDate } from "@shared/utils";
import { TrainingListItem } from "@shared/models";
import { TIMEZONE } from "@shared/constants";

export const trainingTableProperties: TableProperty<TrainingListItem>[] = [
  {
    title: "Name",
    key: "name",
    get: (item) => item.name,
    sort: ["name"],
  },
  {
    title: "Activities",
    key: "name",
    get: (item) => item.activities?.map((a) => a.name).join(", "),
  },
  {
    title: "Passed/Assigned",
    key: "id",
    get: (item) => `${item.passed_members_count}/${item.assigned_members_count}`,
    sort: ["passed_assigned"],
  },
  {
    title: "Scheduled Date",
    key: "scheduled_start_date",
    get: (item) => getUSFormattedDate(item.scheduled_start_date, TIMEZONE.EST),
    sort: ["scheduled_start_date"],
  },
  {
    title: "Expiration Date",
    key: "expiration_date",
    get: (item) => getUSFormattedDate(item.expiration_date, TIMEZONE.EST),
    sort: ["expiration_date"],
  },
  {
    title: "Recurring Every",
    key: "repeat_every_period",
    get: (item) =>
      item.repeat_every_period && item.repeat_every ? `${item.repeat_every} ${item.repeat_every_period}` : "-",
    sort: ["recurring_every"],
  },
  {
    title: "Certification",
    key: "is_auto_create_certificate",
    get: (item) => item.is_auto_create_certificate !== undefined && (item.is_auto_create_certificate ? "Yes" : "No"),
    sort: ["certification"],
  },
];
