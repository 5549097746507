import { File, Task, TaskListItem } from "@shared/models";
import {
  PaginatedResponse,
  ActionWithPayload,
  Filter,
  PayloadWithId,
  IdWithOptionalCode,
  CreateFileShape,
  CreateFileSuccess,
  DeleteFileSuccess,
  BaseResponse,
} from "@shared/interfaces";
import { hideOverlay, navigate, startLoading, stopLoading } from "@shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createMember } from "@containers/Member/store/actions";
import {
  CreateTaskSuccess,
  DeleteTaskFileShape,
  TaskFormShapeToRequest,
  UpdateTaskStatusShape,
  TaskImportShape,
} from "@containers/Task/interface";
import { NameOfChildRoutes, NameOfRoutes } from "@shared/constants";
import { generatePath } from "react-router";

import api from "../api";
import {
  addTaskFile,
  createTask,
  deleteTaskFile,
  getTask,
  getTaskFiles,
  getTasks,
  importTask,
  updateTask,
  updateTaskStatus,
  setClearTaskForm,
} from "./actions";

function* getTasksSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<TaskListItem> = yield call(api.getTasks, payload);
    yield put(stopLoading());
    yield put(getTasks.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getTasks.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTaskSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: Task = yield call(api.getTask, payload);
    yield put(stopLoading());
    yield put(getTask.success(response));
  } catch (error) {
    yield put(getTask.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createTaskSaga({ payload }: ActionWithPayload<TaskFormShapeToRequest>) {
  try {
    yield put(startLoading());
    const { company_code } = payload;
    const { needRedirect, needReload, callback, ...restPayload } = payload;
    const response: CreateTaskSuccess = yield call(api.createTask, restPayload);
    yield put(stopLoading());
    yield put(createTask.success(response));
    if (needRedirect && !needReload) {
      if (company_code) {
        yield put(
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TASKS_EDIT}`, {
              code: company_code,
              id: String(response.task.id),
            }),
          ),
        );
      } else {
        yield put(navigate(`${NameOfRoutes.TASKS}/${response.task.id}`));
      }
    }
    if (needReload) {
      yield put(setClearTaskForm(true));
    }
    callback && callback();
  } catch (error) {
    yield put(createMember.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateTaskSaga({ payload }: ActionWithPayload<Required<PayloadWithId<TaskFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateTaskSuccess = yield call(api.updateTask, payload);
    yield put(stopLoading());
    yield put(updateTask.success(response));
  } catch (error) {
    yield put(updateTask.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateTaskStatusSaga({ payload }: ActionWithPayload<IdWithOptionalCode<UpdateTaskStatusShape>>) {
  try {
    yield put(startLoading());
    const { code } = payload;
    const response: CreateTaskSuccess = yield call(api.updateTaskStatus, payload);
    yield put(stopLoading());
    yield put(updateTaskStatus.success(response));
    if (code) {
      yield put(navigate(generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TASKS}`, { code })));
    } else {
      yield put(navigate(NameOfRoutes.TASKS));
    }
  } catch (error) {
    yield put(updateTaskStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTaskFilesSaga({ payload }: ActionWithPayload<Required<{ id: string | number }>>) {
  try {
    yield put(startLoading());
    const response: File[] = yield call(api.getTaskFiles, payload);
    yield put(stopLoading());
    yield put(getTaskFiles.success(response));
  } catch (error) {
    yield put(getTaskFiles.failure(error as Error));
    yield put(stopLoading());
  }
}

function* addTaskFileSaga({ payload }: ActionWithPayload<Required<PayloadWithId<CreateFileShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateFileSuccess = yield call(api.addTaskFile, payload);
    yield put(stopLoading());
    yield put(addTaskFile.success(response));
  } catch (error) {
    yield put(addTaskFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteTaskFileSaga({ payload }: ActionWithPayload<DeleteTaskFileShape>) {
  try {
    yield put(startLoading());
    const response: DeleteFileSuccess = yield call(api.deleteTaskFile, payload);
    yield put(stopLoading());
    yield put(deleteTaskFile.success(response));
  } catch (error) {
    yield put(deleteTaskFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* importTaskSaga({ payload }: ActionWithPayload<TaskImportShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.importTask, payload);
    yield put(stopLoading());
    yield put(importTask.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(importTask.failure(error as Error));
    yield put(stopLoading());
  }
}

function* taskSagas() {
  yield takeLatest(getTasks.request, getTasksSaga);
  yield takeLatest(getTask.request, getTaskSaga);
  yield takeLatest(createTask.request, createTaskSaga);
  yield takeLatest(updateTask.request, updateTaskSaga);
  yield takeLatest(updateTaskStatus.request, updateTaskStatusSaga);
  yield takeLatest(getTaskFiles.request, getTaskFilesSaga);
  yield takeLatest(addTaskFile.request, addTaskFileSaga);
  yield takeLatest(deleteTaskFile.request, deleteTaskFileSaga);
  yield takeLatest(importTask.request, importTaskSaga);
}

export default taskSagas;
