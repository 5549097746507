import {
  shortUserSchema,
  getPaginatedResponseSchema,
  getRequestSchema,
  baseFilterProperties,
} from "@shared/validators/shared.schema";

export const documentFilterSchema = getRequestSchema({
  ...baseFilterProperties,
  type: { type: ["string", "null"] },
});

export const documentSchema = {
  type: "object",
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    created_at: { type: "string" },
    link_url: { type: ["string", "null"] },
    file: {
      type: ["object", "null"],
      additionalProperties: false,
      properties: {
        id: { type: "number" },
        name: { type: "string" },
        url: { type: "string" },
        created_at: { type: "string" },
      },
    },
    creator: shortUserSchema,
  },
  additionalProperties: false,
};

export const getDocumentListResponseSchema = getPaginatedResponseSchema(documentSchema);

export const documentResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "document"],
  properties: {
    message: { type: "string" },
    document: documentSchema,
  },
};

export const documentDeleteResponseSchema = {
  type: "object",
  additionalProperties: true,
  required: ["message", "document_id"],
  properties: {
    message: { type: "string" },
    document_id: { type: "number" },
  },
};

const generalDocumentRequestSchemaProperties = [
  {
    required: ["base64", "base64_file_name"],
    properties: {
      base64: { type: "string" },
      base64_file_name: { type: "string" },
    },
  },
  {
    required: ["link_url"],
    properties: {
      link_url: { type: "string" },
    },
  },
];

export const createDocumentRequestSchema = {
  type: "object",
  required: ["name"],
  properties: {
    name: { type: "string" },
  },
  anyOf: [...generalDocumentRequestSchemaProperties],
  additionalProperties: true,
};

export const updateDocumentRequestSchema = {
  type: "object",
  required: ["name"],
  properties: {
    name: { type: "string" },
  },
  anyOf: [
    ...generalDocumentRequestSchemaProperties,
    {
      required: ["file_id"],
      properties: {
        file_id: { type: "number" },
      },
    },
  ],
  additionalProperties: true,
};
