import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { PayloadWithCode } from "@shared/interfaces";

import { ContactUsFormShape } from "../interface";

export default {
  createSupportMessage: ({ code, payload }: Required<PayloadWithCode<ContactUsFormShape>>) => {
    const supportMessage: ContactUsFormShape = {
      ...payload,
      subject: payload.subject || null,
    };
    return request(METHODS.POST, API.SUPPORT_MESSAGE.CREATE_SUPPORT_MESSAGE(code))(supportMessage);
  },
};
