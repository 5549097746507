import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { AutocompleteSelect, Button, Table, NoMatchesFound, InputSearch, Icon } from "@shared/components";
import { prepareBaseOptions } from "@shared/utils";
import { CertificateTemplate, MemberListItem, MemberStatus, TeamStatus } from "@shared/models";
import { ExtraKeyType, Filter, ORDER_TYPE } from "@shared/interfaces";
import { selectors as memberSelectors, actions as memberActions } from "@containers/Member/store";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";
import { EMPTY_ARRAY } from "@shared/constants";

import { getMemberTableProperties } from "./tableHelpers";

import "./index.scss";

export interface TeamMemberModalProps {
  selectedMembers: MemberListItem[];
  companyCode?: string | null;
  onAssign: (newMembers: MemberListItem[]) => void;
  onCancel: () => void;
  certificateTemplates?: (CertificateTemplate | null)[];
  certificateExpireDate?: Date | null;
}

const TeamMemberModal: FC<TeamMemberModalProps> = (props) => {
  const dispatch = useDispatch();
  const { companyCode, onAssign, onCancel, selectedMembers, certificateTemplates, certificateExpireDate } = props;
  const members = useSelector(memberSelectors.getMembers());
  const membersTotal = useSelector(memberSelectors.getMembersTotal());
  const filter = useSelector(memberSelectors.getFilter());

  const [selectedMembersObject] = useState<ExtraKeyType<MemberListItem>>(
    selectedMembers.reduce((acc: ExtraKeyType<MemberListItem>, item) => {
      acc[item.id] = item;
      return acc;
    }, {}),
  );

  const [showSearch, setShowSearch] = useState(false);

  const [newMembersObject, setNewMembersObject] = useState<ExtraKeyType<MemberListItem>>({});

  const filteredMembers = useMemo(() => {
    return members.filter((member) => !selectedMembersObject[member.id]);
  }, [members, selectedMembersObject]);

  const memberTableProperties = useMemo(() => {
    return getMemberTableProperties(newMembersObject, certificateTemplates);
  }, [newMembersObject, certificateTemplates]);

  const onChangeShowSearch = useCallback((value: boolean) => {
    setShowSearch(value);
  }, []);

  const getTeams = useCallback(
    (search: string, page?: number) => {
      return sharedActions.getTeams.request({
        search,
        page: page || 0,
        limit: 10,
        status: TeamStatus.Active,
        company_code: companyCode,
      });
    },
    [companyCode],
  );

  useEffect(() => {
    dispatch(
      memberActions.getMembers.request({
        ...filter,
        limit: 50,
        status: MemberStatus.Active,
        company_code: companyCode,
        sort_by: ["name"],
        sort_order: ORDER_TYPE.ASC,
        extend: Boolean(certificateTemplates),
        certificate_expire_date: certificateExpireDate,
      }),
    );
  }, [dispatch, filter, companyCode, certificateTemplates, certificateExpireDate]);

  useEffect(() => {
    return () => {
      dispatch(memberActions.setFilter(null));
      dispatch(memberActions.getMembers.success({ count: 0, rows: [], clear: true }));
    };
  }, [dispatch]);

  const onLoadNextPage = useCallback(() => {
    if (members.length < membersTotal) {
      dispatch(
        memberActions.setFilter({
          ...filter,
          page: filter.page + 1,
        }),
      );
    }
  }, [dispatch, filter, members, membersTotal]);

  const onChangeFilter = useCallback(
    (field: keyof Filter, value: unknown) => {
      dispatch(
        memberActions.setFilter({
          ...filter,
          [field]: value,
          page: 0,
        }),
      );
    },
    [dispatch, filter],
  );

  const onSelectAll = useCallback(
    (unselect?: boolean) => {
      const tempSelectedMembers = { ...newMembersObject };
      for (const member of filteredMembers) {
        if (unselect) {
          delete tempSelectedMembers[member.id];
        } else {
          tempSelectedMembers[member.id] = member;
        }
      }
      setNewMembersObject(tempSelectedMembers);
    },
    [newMembersObject, filteredMembers],
  );

  const onToggleSelection = useCallback(
    (item: MemberListItem) => {
      const tempSelectedMembers = { ...newMembersObject };
      if (tempSelectedMembers[item.id]) {
        delete tempSelectedMembers[item.id];
      } else {
        tempSelectedMembers[item.id] = item;
      }
      setNewMembersObject(tempSelectedMembers);
    },
    [newMembersObject],
  );

  const newMembersCount = useMemo(() => {
    return Object.keys(newMembersObject).length;
  }, [newMembersObject]);

  const isDisabledAssign = useMemo(() => {
    return !newMembersCount;
  }, [newMembersCount]);

  const noMembers = useMemo(() => {
    return !filteredMembers.length;
  }, [filteredMembers]);

  const renderTitle = useMemo(() => {
    return (
      <div className="team-member-modal-title-wrapper">
        {!showSearch && <div className="team-member-modal-title">Team Members</div>}
        <InputSearch
          onChangeSearch={(search) => onChangeFilter("search", search)}
          placeholder="Search"
          showClearText={true}
          showClose={true}
          value={filter.search || ""}
          onChangeShowSearch={onChangeShowSearch}
        />
      </div>
    );
  }, [showSearch, filter.search, onChangeFilter, onChangeShowSearch]);

  const onChangeTeam = useCallback(
    (value: unknown) => {
      onChangeFilter("teams", [value]);
    },
    [onChangeFilter],
  );

  const unselectAll = useCallback(() => {
    !isDisabledAssign && onSelectAll(true);
  }, [isDisabledAssign, onSelectAll]);

  const selectAll = useCallback(() => {
    !noMembers && onSelectAll();
  }, [noMembers, onSelectAll]);

  const assign = useCallback(() => {
    !isDisabledAssign && onAssign(Object.values(newMembersObject));
  }, [isDisabledAssign, onAssign, newMembersObject]);

  return (
    <div className="team-member-modal-wrapper">
      <div className="team-member-modal-header">
        {renderTitle}
        <div className="team-member-modal-filter">
          <AutocompleteSelect
            options={EMPTY_ARRAY}
            name="team_id"
            placeholder="Select Team"
            getData={getTeams}
            selectData={sharedSelectors.getTeams}
            selectTotalCount={sharedSelectors.getTeamsTotal}
            onChange={onChangeTeam}
            value={filter.team_id}
            prepareOptionFunction={prepareBaseOptions}
            isClearable={true}
            noOptionsMessage="No teams available"
          />
          <Icon type="clear" onClick={onCancel} className="team-member-modal-cancel-icon" />
        </div>
      </div>
      <div className="team-member-modal-content">
        {noMembers ? (
          <NoMatchesFound
            label={filter.search ? "No Matches Found" : "No Team Members Yet"}
            iconClass="team-member-modal-content__no-matches-icon"
          />
        ) : (
          <Table<MemberListItem>
            onRowClick={onToggleSelection}
            onFinishScroll={onLoadNextPage}
            items={filteredMembers}
            properties={memberTableProperties}
          />
        )}
      </div>
      <div className="team-member-modal-footer">
        <div className="team-member-modal-footer-selected">{newMembersCount} selected</div>
        <div className="team-member-modal-footer-buttons">
          <div
            className={classnames("team-member-plain-button unselect-all", { disabled: isDisabledAssign })}
            onClick={unselectAll}>
            Unselect All
          </div>
          <div
            className={classnames("team-member-plain-button select-all", { disabled: noMembers })}
            onClick={selectAll}>
            Select All
          </div>
          <Button type="button" className="team-member-modal-assign" disabled={isDisabledAssign} onClick={assign}>
            Assign
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberModal;
