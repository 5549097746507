import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "@shared/store";
import { OverlayProps } from "@shared/interfaces";
import { useShowModal, useLeaveModalData } from "@shared/hooks";

export const useOverlay = () => {
  return {
    overlay: useSelector(selectors.getOverlay()),
  };
};

export const useShowOverlay = () => {
  const dispatch = useDispatch();
  return useCallback(
    (params: OverlayProps = {}) => {
      dispatch(actions.showOverlay(params));
    },
    [dispatch],
  );
};

export const useHideOverlay = () => {
  const dispatch = useDispatch();
  const { overlay } = useOverlay();
  const showModal = useShowModal();
  const leaveModalData = useLeaveModalData(overlay.leaveText);

  const handleOnClose = useCallback(() => {
    if (overlay.leaveConfirm) {
      return showModal({
        ...leaveModalData,
        onConfirm: () => dispatch(actions.hideOverlay()),
      });
    }
    overlay.onLeave?.();
    dispatch(actions.hideOverlay());
  }, [dispatch, showModal, overlay, leaveModalData]);

  return {
    handleOnClose,
  };
};
