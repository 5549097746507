import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "@shared/utils";
import { createSupportMessageRequestSchema, createSupportMessageResponseSchema } from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.SUPPORT_MESSAGE> = {
  [API_ENUM.CREATE_SUPPORT_MESSAGE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.SUPPORT_MESSAGE.CREATE_SUPPORT_MESSAGE("$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createSupportMessageRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.SUPPORT_MESSAGE.CREATE_SUPPORT_MESSAGE("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: createSupportMessageResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
