export enum TeamActionTypes {
  GET_TEAMS = "@@TEAM/GET_TEAMS",
  GET_TEAMS_SUCCESS = "@@TEAM/GET_TEAMS_SUCCESS",
  GET_TEAMS_FAILURE = "@@TEAM/GET_TEAMS_FAILURE",

  GET_TEAM = "@@TEAM/GET_TEAM",
  GET_TEAM_SUCCESS = "@@TEAM/GET_TEAM_SUCCESS",
  GET_TEAM_FAILURE = "@@TEAM/GET_TEAM_FAILURE",

  CREATE_TEAM = "@@TEAM/CREATE_TEAM",
  CREATE_TEAM_SUCCESS = "@@TEAM/CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE = "@@TEAM/CREATE_TEAM_FAILURE",

  UPDATE_TEAM = "@@TEAM/UPDATE_TEAM",
  UPDATE_TEAM_SUCCESS = "@@TEAM/UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAILURE = "@@TEAM/UPDATE_TEAM_FAILURE",

  DELETE_TEAM = "@@TEAM/DELETE_TEAM",
  DELETE_TEAM_SUCCESS = "@@TEAM/DELETE_TEAM_SUCCESS",
  DELETE_TEAM_FAILURE = "@@TEAM/DELETE_TEAM_FAILURE",

  SET_FILTER = "@@TEAM/SET_FILTER",
}
