export const locationProjectSchema = {
  type: "object",
  required: ["id", "place_id", "address"],
  properties: {
    id: { type: "number" },
    place_id: { type: "string" },
    address: { type: "string" },
  },
  additionalProperties: false,
};

export const locationCommonProperties = {
  id: { type: "number" },
  address: { type: ["string", "null"] },
  country: { type: ["string", "null"] },
  lat: { type: ["string", "null"] },
  lng: { type: ["string", "null"] },
  place_id: { type: ["string", "null"] },
  state: { type: ["string", "null"] },
  zip: { type: ["string", "null"] },
};
