import React, { FC, useMemo } from "react";
import { FormHeader, DateTimeHeader } from "@shared/components";
import { Training, TrainingStatus } from "@shared/models";
import { PERMISSION } from "@shared/constants";
import { checkPermission } from "@shared/utils";

export interface TrainingViewHeaderProps {
  training: Training;
  onBack: () => void;
  onEdit: () => void;
  company_code?: string | null;
  isTeamMember?: boolean;
}

const TrainingViewHeader: FC<TrainingViewHeaderProps> = (props) => {
  const { training, onBack, onEdit, company_code, isTeamMember } = props;

  const canEditTraining = useMemo(() => {
    return checkPermission(PERMISSION.CREATE_EDIT_ARCHIVE_COMPANY_TRAININGS, { companyCode: company_code });
  }, [company_code]);

  const submitText = useMemo(() => {
    if (!training) return null;

    const isArchived = training.status === TrainingStatus.Archived;
    return canEditTraining && !isArchived ? "Edit" : null;
  }, [training, canEditTraining]);

  return (
    <div className="form-header-wrapper">
      <FormHeader
        title={training.name}
        submitText={submitText}
        onSubmit={onEdit}
        onBack={onBack}
        companyCode={company_code}
        leftComplexFormHeader={
          !isTeamMember ? (
            <DateTimeHeader
              isDraft={training.status === TrainingStatus.Draft}
              startDate={training.scheduled_start_date || training.expiration_date}
              endDate={training.scheduled_end_date}
              onlyDate={!training.scheduled_start_date}
            />
          ) : undefined
        }
      />
    </div>
  );
};

export default TrainingViewHeader;
