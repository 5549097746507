import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@shared/store";

function useRoleIdByCodeHook(roleCode: string, isAppAdmin = false) {
  const dispatch = useDispatch();
  const roles = useSelector(selectors.getRoles());

  useEffect(() => {
    dispatch(
      actions.getRoles.request({
        is_app_admin: isAppAdmin,
      }),
    );
  }, [dispatch, isAppAdmin]);

  const roleId = useMemo(() => {
    const role = roles.find((r) => r.code === roleCode);
    return role?.id || 0;
  }, [roles, roleCode]);

  return roleId;
}

export default useRoleIdByCodeHook;
