import React, { useCallback } from "react";
import classnames from "classnames";
import { ErrorMessage, AutocompleteCheckboxMultiSelect } from "@shared/components/Common/";

import { AutocompleteSelectField, GenerateRenderField, Handlers } from "../../interfaces";

export const GenerateCheckboxSelectSearchMulti: GenerateRenderField<AutocompleteSelectField & Handlers> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    handlers,
    disabled,
    label,
    name,
    options,
    placeholder,
    menuWrapper,
    isLockedLogic,
    availableOptions,
    tooltipText,
    onLockedOptionClick,
  } = props;

  const { getData, selectData, prepareOptionFunction } = (handlers || {})[name];

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any) => {
      setFieldTouched(name, true);
      setFieldValue && setFieldValue(name, option);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const handleRemoveValue = useCallback(
    (id: string) => {
      setFieldValue &&
        setFieldValue(
          name,
          (values[name] || []).filter(({ value }: { label: string; value: string }) => value !== id),
        );
      setFieldTouched(name, true);
    },
    [setFieldTouched, name, values, setFieldValue],
  );

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}>
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <AutocompleteCheckboxMultiSelect
          options={options.map((o) => ({ label: o.label, value: String(o.value || "") })) || []}
          onLockedOptionClick={onLockedOptionClick}
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          getData={getData}
          selectData={selectData}
          onChange={handleChange}
          value={values[name]}
          handleRemoveValue={handleRemoveValue}
          menuWrapper={menuWrapper}
          prepareOptionFunction={prepareOptionFunction}
          isLockedLogic={isLockedLogic}
          availableOptions={availableOptions}
          tooltipText={tooltipText}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
