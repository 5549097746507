import React, { FC } from "react";

import "./index.scss";

export interface TextButtonProps {
  onClick: () => void;
  type: "archive" | "cancel";
  text: string;
}

const TextButton: FC<TextButtonProps> = (props) => {
  const { onClick, type, text } = props;

  return (
    <div className={`text-button ${type}`} onClick={onClick}>
      {text}
    </div>
  );
};

export default TextButton;
