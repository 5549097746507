export enum ProjectActionTypes {
  GET_PROJECTS = "@@PROJECT/GET_PROJECTS",
  GET_PROJECTS_SUCCESS = "@@PROJECT/GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE = "@@PROJECT/GET_PROJECTS_FAILURE",

  CLEAR_PROJECTS = "@@PROJECT/CLEAR_PROJECTS",

  GET_PROJECT = "@@PROJECT/GET_PROJECT",
  GET_PROJECT_SUCCESS = "@@PROJECT/GET_PROJECT_SUCCESS",
  GET_PROJECT_FAILURE = "@@PROJECT/GET_PROJECT_FAILURE",

  CREATE_PROJECT = "@@PROJECT/CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS = "@@PROJECT/CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE = "@@PROJECT/CREATE_PROJECT_FAILURE",

  UPDATE_PROJECT = "@@PROJECT/UPDATE_PROJECT",
  UPDATE_PROJECT_SUCCESS = "@@PROJECT/UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE = "@@PROJECT/UPDATE_PROJECT_FAILURE",

  DELETE_PROJECT = "@@PROJECT/DELETE_PROJECT",
  DELETE_PROJECT_SUCCESS = "@@PROJECT/DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILURE = "@@PROJECT/DELETE_PROJECT_FAILURE",

  SET_FILTER = "@@PROJECT/SET_FILTER",

  CLEAR_CURRENT_PROJECT = "@@SHARED/CLEAR_CURRENT_PROJECT",
}
