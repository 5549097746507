import { Role } from "./Role";

export enum UserStatus {
  Inactive = "Inactive",
  Active = "Active",
  Invited = "Invited",
}

export interface UserRole {
  role_id: number;
  company: {
    code: string;
    name: string;
  } | null;
  role: Role;
  status: UserStatus;
}
