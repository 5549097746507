import React, { FC, useMemo } from "react";
import { CertificateTemplate, MemberListItem } from "@shared/models";
import { Icon, Tooltip } from "@shared/components";
import { useMemberHasCertificates } from "@containers/Member/hooks";
import classNames from "classnames";

import "./index.scss";

export interface TeamMemberLabelProps {
  member: MemberListItem;
  onDelete?: () => void;
  certificateTemplates?: (CertificateTemplate | null)[];
}

const TeamMemberLabel: FC<TeamMemberLabelProps> = (props) => {
  const { member, onDelete, certificateTemplates } = props;

  const { hasCertificates } = useMemberHasCertificates(member, certificateTemplates);

  const labelContent = useMemo(() => {
    return (
      <div className={classNames("team-member-label", { "no-certificates": !hasCertificates })}>
        <Icon type="warning" className="team-member-warning" />
        <div className="team-member-label-info">
          <div className="team-member-label-name" title={`${member.first_name} ${member.last_name}`}>
            {member.first_name} {member.last_name}
          </div>
          {member.job && <div className="team-member-label-position">{member.job.name}</div>}
        </div>
        {onDelete && <Icon type="close" onClick={onDelete} />}
      </div>
    );
  }, [onDelete, member, hasCertificates]);

  return !hasCertificates ? <Tooltip content={"Certificate is missing"}>{labelContent}</Tooltip> : labelContent;
};

export default TeamMemberLabel;
