import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";

export interface ApplySelectOptionProps {
  onApply: () => void;
  onReset?: () => void;
  applyText?: string;
  resetText?: string;
  disabled?: boolean;
}

const ApplySelectOption: FC<ApplySelectOptionProps> = (props) => {
  const { onApply, onReset, applyText = "Apply", resetText = "Reset All", disabled } = props;

  return (
    <div className="apply-select-option">
      {onReset && (
        <div className={classnames("apply-reset-option", { disabled })} onClick={onReset}>
          {resetText}
        </div>
      )}
      <div className={classnames("apply-option", { disabled })} onClick={onApply}>
        {applyText}
      </div>
    </div>
  );
};

export default ApplySelectOption;
