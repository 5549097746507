import React from "react";
import { useOverlay, useHideOverlay } from "@shared/hooks";

import "./index.scss";

const OverlayWrapper = () => {
  const { overlay } = useOverlay();
  const { handleOnClose } = useHideOverlay();

  return overlay.show ? (
    <>
      <div className="overlay" onClick={handleOnClose} />
      <div className="overlay-wrapper">
        <div className="overlay-body">{overlay.body}</div>
      </div>
    </>
  ) : null;
};

export default OverlayWrapper;
