import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyNumeric, generateDynamicSchemaKeyString } from "@shared/utils";
import {
  filterSchema,
  getCompaniesResponseSchema,
  createCompanyRequestSchema,
  updateCompanyRequestSchema,
  commonCompanyResponseSchema,
  updateCompanyStatusRequestSchema,
  fullCompanySchema,
} from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.COMPANY> = {
  [API_ENUM.GET_COMPANIES]: {
    request: {
      name: `${API.COMPANY.GET_COMPANIES}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${API.COMPANY.GET_COMPANIES}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getCompaniesResponseSchema,
    },
  },
  [API_ENUM.CREATE_COMPANY]: {
    request: {
      name: `${API.COMPANY.CREATE_COMPANY}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createCompanyRequestSchema,
    },
    response: {
      name: `${API.COMPANY.CREATE_COMPANY}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: commonCompanyResponseSchema,
    },
  },
  [API_ENUM.GET_COMPANY]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.COMPANY.GET_COMPANY("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.COMPANY.GET_COMPANY("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: fullCompanySchema,
    },
  },
  [API_ENUM.UPDATE_COMPANY]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.COMPANY.UPDATE_COMPANY("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: updateCompanyRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.COMPANY.UPDATE_COMPANY("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: commonCompanyResponseSchema,
    },
  },
  [API_ENUM.UPDATE_COMPANY_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.COMPANY.UPDATE_COMPANY_STATUS("$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: updateCompanyStatusRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.COMPANY.UPDATE_COMPANY_STATUS("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: commonCompanyResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
