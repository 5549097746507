export enum AssignmentActionTypes {
  GET_ASSIGNMENTS = "@@ASSIGNMENT/GET_ASSIGNMENTS",
  GET_ASSIGNMENTS_SUCCESS = "@@ASSIGNMENT/GET_ASSIGNMENTS_SUCCESS",
  GET_ASSIGNMENTS_FAILURE = "@@ASSIGNMENT/GET_ASSIGNMENTS_FAILURE",

  CLEAR_ASSIGNMENTS = "@@ASSIGNMENT/CLEAR_ASSIGNMENTS",

  GET_MEMBERS_ASSIGNMENTS = "@@ASSIGNMENT/GET_MEMBERS_ASSIGNMENTS",
  GET_MEMBERS_ASSIGNMENTS_SUCCESS = "@@ASSIGNMENT/GET_MEMBERS_ASSIGNMENTS_SUCCESS",
  GET_MEMBERS_ASSIGNMENTS_FAILURE = "@@ASSIGNMENT/GET_MEMBERS_ASSIGNMENTS_FAILURE",

  CLEAR_MEMBERS_ASSIGNMENTS = "@@ASSIGNMENT/CLEAR_MEMBERS_ASSIGNMENTS",

  SET_FILTER = "@@ASSIGNMENT/SET_FILTER",
  SET_PARTIAL_FILTER = "@@ASSIGNMENT/SET_PARTIAL_FILTER",

  CREATE_ASSIGNMENT = "@@ASSIGNMENT/CREATE_ASSIGNMENT",
  CREATE_ASSIGNMENT_SUCCESS = "@@ASSIGNMENT/CREATE_ASSIGNMENT_SUCCESS",
  CREATE_ASSIGNMENT_FAILURE = "@@ASSIGNMENT/CREATE_ASSIGNMENT_FAILURE",

  GET_ASSIGNMENT = "@@ASSIGNMENT/GET_ASSIGNMENT",
  GET_ASSIGNMENT_SUCCESS = "@@ASSIGNMENT/GET_ASSIGNMENT_SUCCESS",
  GET_ASSIGNMENT_FAILURE = "@@ASSIGNMENT/GET_ASSIGNMENT_FAILURE",

  GET_ASSIGNMENT_CHECK_IN_OUT = "@@ASSIGNMENT/GET_ASSIGNMENT_CHECK_IN_OUT",
  GET_ASSIGNMENT_CHECK_IN_OUT_SUCCESS = "@@ASSIGNMENT/GET_ASSIGNMENT_CHECK_IN_OUT_SUCCESS",
  GET_ASSIGNMENT_CHECK_IN_OUT_FAILURE = "@@ASSIGNMENT/GET_ASSIGNMENT_CHECK_IN_OUT_FAILURE",

  GET_ASSIGNMENT_PASSED_JSA = "@@ASSIGNMENT/GET_ASSIGNMENT_PASSED_JSA",
  GET_ASSIGNMENT_PASSED_JSA_SUCCESS = "@@ASSIGNMENT/GET_ASSIGNMENT_PASSED_JSA_SUCCESS",
  GET_ASSIGNMENT_PASSED_JSA_FAILURE = "@@ASSIGNMENT/GET_ASSIGNMENT_PASSED_JSA_FAILURE",

  GET_ASSIGNMENT_JSA = "@@ASSIGNMENT/GET_ASSIGNMENT_JSA",
  GET_ASSIGNMENT_JSA_SUCCESS = "@@ASSIGNMENT/GET_ASSIGNMENT_JSA_SUCCESS",
  GET_ASSIGNMENT_JSA_FAILURE = "@@ASSIGNMENT/GET_ASSIGNMENT_JSA_FAILURE",

  CREATE_ASSIGNMENT_CHECK_IN = "@@ASSIGNMENT/CREATE_ASSIGNMENT_CHECK_IN",
  CREATE_ASSIGNMENT_CHECK_IN_SUCCESS = "@@ASSIGNMENT/CREATE_ASSIGNMENT_CHECK_IN_SUCCESS",
  CREATE_ASSIGNMENT_CHECK_IN_FAILURE = "@@ASSIGNMENT/CREATE_ASSIGNMENT_CHECK_IN_FAILURE",

  CREATE_ASSIGNMENT_CHECK_OUT = "@@ASSIGNMENT/CREATE_ASSIGNMENT_CHECK_OUT",
  CREATE_ASSIGNMENT_CHECK_OUT_SUCCESS = "@@ASSIGNMENT/CREATE_ASSIGNMENT_CHECK_OUT_SUCCESS",
  CREATE_ASSIGNMENT_CHECK_OUT_FAILURE = "@@ASSIGNMENT/CREATE_ASSIGNMENT_CHECK_OUT_FAILURE",

  CLEAR_ASSIGNMENT_CHECK_IN_OUT = "@@ASSIGNMENT/CLEAR_ASSIGNMENT_CHECK_IN_OUT",

  UPDATE_ASSIGNMENT = "@@ASSIGNMENT/UPDATE_ASSIGNMENT",
  UPDATE_ASSIGNMENT_SUCCESS = "@@ASSIGNMENT/UPDATE_ASSIGNMENT_SUCCESS",
  UPDATE_ASSIGNMENT_FAILURE = "@@ASSIGNMENT/UPDATE_ASSIGNMENT_FAILURE",

  UPDATE_ASSIGNMENT_STATUS = "@@JSA/UPDATE_ASSIGNMENT_STATUS",
  UPDATE_ASSIGNMENT_STATUS_SUCCESS = "@@JSA/UPDATE_ASSIGNMENT_STATUS_SUCCESS",
  UPDATE_ASSIGNMENT_STATUS_FAILURE = "@@JSA/UPDATE_ASSIGNMENT_STATUS_FAILURE",

  SET_LAST_ASSIGNMENT_VIEW = "@@ASSIGNMENT/SET_LAST_ASSIGNMENT_VIEW",
  SET_CLICKED_CALENDAR_DAY = "@@ASSIGNMENT/SET_CLICKED_CALENDAR_DAY",
  SET_CLEAR_ASSIGNMENT_FORM = "@@ASSIGNMENT/SET_CLEAR_ASSIGNMENT_FORM",
}
