import React, { lazy, FC } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { Loadable } from "@shared/components";
import { NameOfChildRoutes, NameOfRoutes } from "@shared/constants";
import "./index.scss";
import { GuestGuard } from "@shared/guards";

const LoginContainer = Loadable(lazy(() => import("../LoginContainer/LoginContainer")));
const RestoreContainer = Loadable(lazy(() => import("../RestoreContainer/RestoreContainer")));
const ChangePasswordContainer = Loadable(lazy(() => import("../ChangePasswordContainer/ChangePasswordContainer")));
const RegistrationContainer = Loadable(lazy(() => import("../RegistrationContainer/RegistrationContainer")));

const routes = [
  {
    path: `${NameOfChildRoutes.AUTH.LOGIN}`,
    element: (
      <GuestGuard>
        <LoginContainer />,
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.FORGOT_PASSWORD}`,
    element: (
      <GuestGuard>
        <RestoreContainer />,
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.CHANGE_PASSWORD}/:hash`,
    element: (
      <GuestGuard>
        <ChangePasswordContainer />,
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.REGISTRATION}/:hash`,
    element: <RegistrationContainer />,
  },
  {
    path: `${NameOfChildRoutes.DEFAULT_REDIRECT}`,
    element: <Navigate to={`${NameOfRoutes.AUTH_LOGIN}`} />,
  },
];

const AuthContainer: FC = () => {
  const content = useRoutes(routes);
  return <div className="auth-container">{content}</div>;
};

export default AuthContainer;
