import { Icon } from "@shared/components";
import React, { useMemo } from "react";
import { useToasts } from "react-toast-notifications";

import { APPEARANCE } from "../../constants";
import { IToastProps } from "../../interfaces";
import "./index.scss";

const Toast: React.FC<IToastProps> = (props) => {
  const { appearance, children } = props;
  const { removeAllToasts } = useToasts();

  const isSuccess = useMemo(() => {
    return appearance === APPEARANCE.SUCCESS;
  }, [appearance]);

  return (
    <div className={`notification ${appearance}`}>
      <div className="notification-content">
        <div className="notification-content-left">
          <div className={`notification-content-left-icon ${appearance}`}>
            <Icon type={isSuccess ? "notification-success" : "notification-error"} />
          </div>
          <div className="notification-content-left-info">
            <div className="notification-title">{isSuccess ? "Success!" : "Fail"} </div>
            <div className="notification-message">{children}</div>
          </div>
        </div>
        <div className="notification-content-right">
          <div onClick={removeAllToasts}>Close</div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
