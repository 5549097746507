import { baseEntitySchema, getRequestSchema, baseFilterProperties, getPaginatedResponseSchema } from ".";

const commonCertificateProperties = {
  name: { type: "string" },
};

const generalCertificateProperties = {
  issued_on: { type: ["string", "object"] },
  status: { type: "string" },
  valid: { type: "number" },
  valid_period: { type: "string" },
};

export const certificateFromListSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "created_at"],
  properties: {
    id: { type: "number" },
    issued_by: { type: ["string", "null"] },
    issued_on: { type: ["string", "null"] },
    valid: { type: ["number", "null"] },
    valid_period: { type: ["string", "null"] },
    created_at: { type: "string" },
    member_id: { type: "number" },
    member_first_name: { type: "string" },
    member_last_name: { type: "string" },
    expire_date: { type: ["string", "null"] },
    ...commonCertificateProperties,
  },
};

export const certificateTemplateSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "created_at"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    issued_by: { type: ["string", "null"] },
    created_at: { type: "string" },
    projects: {
      type: ["array", "null"],
      items: [baseEntitySchema],
    },
    activities: {
      type: ["array", "null"],
      items: [baseEntitySchema],
    },
  },
};

export const commonCertificateTemplateSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    issued_by: { type: "string" },
    created_at: { type: "string" },
  },
};

export const certificateFilterSchema = getRequestSchema({
  page: { type: "number" },
  limit: { type: "number" },
  search: { type: ["string", "null"] },
  member_id: { type: ["number", "null"] },
  activities: { type: "array" },
  code: { type: "string" },
});

export const certificateTemplatesFilterSchema = getRequestSchema({
  page: { type: "number" },
  limit: { type: "number" },
  search: { type: ["string", "null"] },
  issued_by: { type: ["string", "null"] },
  name: { type: ["string", "null"] },
  activities: { type: ["array", "null"] },
  projects: { type: ["array", "null"] },
});

export const certificateFromTrainingPass = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "created_at"],
  properties: {
    id: { type: "number" },
    expire_date: { type: ["string", "null"] },
    ...commonCertificateProperties,
  },
};

export const certificateSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "issued_on", "valid", "created_at"],
  properties: {
    id: { type: "number" },
    issued_by: { type: ["string", "null"] },
    expire_date: { type: ["object", "string", "null"] },
    created_at: { type: "string" },
    certificate_template_id: { type: "number" },
    activities: {
      type: "array",
      items: baseEntitySchema,
    },
    projects: {
      type: "array",
      items: baseEntitySchema,
    },
    member: {
      type: ["object"],
      additionalProperties: false,
      properties: {
        id: { type: "number" },
        first_name: { type: "string" },
        last_name: { type: "string" },
        job: {
          type: ["object", "null"],
          additionalProperties: false,
          properties: {
            ...baseEntitySchema.properties,
          },
        },
        avatar: {
          type: ["object", "null"],
          additionalProperties: false,
          properties: {
            id: { type: "number" },
            url: { type: "string" },
          },
        },
      },
    },
    ...commonCertificateProperties,
    ...generalCertificateProperties,
  },
};

export const updateCertificateRequestSchema = {
  type: "object",
  required: ["name", "issued_by", "issued_on", "valid", "status", "activities", "projects"],
  additionalProperties: false,
  properties: {
    issued_by: { type: "string" },
    activities: {
      type: "array",
      items: {
        type: ["number", "string"],
      },
    },
    projects: {
      type: "array",
      items: {
        type: ["number", "string"],
      },
    },
    ...commonCertificateProperties,
    ...generalCertificateProperties,
  },
};

export const createCertificateRequestSchema = {
  type: "object",
  required: ["member_id", "name", "issued_by", "issued_on", "valid", "status", "activities", "projects"],
  additionalProperties: false,
  properties: {
    member_id: { type: "number" },
    ...updateCertificateRequestSchema.properties,
  },
};

export const updateCertificateResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "certificate"],
  properties: {
    message: { type: "string" },
    certificate: certificateSchema,
  },
};

export const updateCertificateStatusSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    status: { type: "string" },
  },
};

export const getCertificatesIssuedByRequestSchema = getRequestSchema(baseFilterProperties);

export const getCertificatesIssuedByResponseSchema = getPaginatedResponseSchema({
  type: "object",
  additionalProperties: false,
  properties: {
    issued_by: { type: "string" },
  },
});
