import { createAsyncAction, createAction } from "typesafe-actions";
import { User } from "@shared/models";
import { PaginatedResponse, Filter } from "@shared/interfaces";
import {
  UserFormShape,
  CreateUserSuccess,
  UserFormUpdateShape,
  UpdateStatusShape,
  CheckUserExistsShape,
  GetUserShape,
} from "@containers/User/interface";

import { UserActionTypes } from "./constants";

export const getUsers = createAsyncAction(
  UserActionTypes.GET_USERS,
  UserActionTypes.GET_USERS_SUCCESS,
  UserActionTypes.GET_USERS_FAILURE,
)<Filter, PaginatedResponse<User>, Error>();

export const getUser = createAsyncAction(
  UserActionTypes.GET_USER,
  UserActionTypes.GET_USER_SUCCESS,
  UserActionTypes.GET_USER_FAILURE,
)<GetUserShape, User | null, Error>();

export const createUser = createAsyncAction(
  UserActionTypes.CREATE_USER,
  UserActionTypes.CREATE_USER_SUCCESS,
  UserActionTypes.CREATE_USER_FAILURE,
)<UserFormShape, CreateUserSuccess, Error>();

export const updateUser = createAsyncAction(
  UserActionTypes.UPDATE_USER,
  UserActionTypes.UPDATE_USER_SUCCESS,
  UserActionTypes.UPDATE_USER_FAILURE,
)<UserFormUpdateShape, CreateUserSuccess, Error>();

export const updateUserStatus = createAsyncAction(
  UserActionTypes.UPDATE_USER_STATUS,
  UserActionTypes.UPDATE_USER_STATUS_SUCCESS,
  UserActionTypes.UPDATE_USER_STATUS_FAILURE,
)<UpdateStatusShape, CreateUserSuccess, Error>();

export const checkUserExists = createAsyncAction(
  UserActionTypes.CHECK_USER_EXISTS,
  UserActionTypes.CHECK_USER_EXISTS_SUCCESS,
  UserActionTypes.CHECK_USER_EXISTS_FAILURE,
)<CheckUserExistsShape, undefined, Error>();

export const clearUsers = createAction(UserActionTypes.CLEAR_USERS)();

export const setFilter = createAction(UserActionTypes.SET_FILTER)<Filter | null>();
