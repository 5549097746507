import React from "react";
import { ErrorMessage, RichTextArea } from "@shared/components/Common/";
import { FieldItemType } from "@shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface RichTextAreaFieldItem extends RenderField {
  type: FieldItemType.RICH_TEXT;
  label: string;
  pathToUploadFile: string;
  disabled?: boolean;
  inputClass?: string;
  // eslint-disable-next-line
  onChangeField?: (field: string, value: any, values: any, setFieldValue?: (field: string, value: any) => void) => void;
  // eslint-disable-next-line
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const GenerateRichTextarea: GenerateRenderField<RichTextAreaFieldItem> = (props) => {
  const {
    name,
    inputClass,
    label,
    onChangeField,
    pathToUploadFile,
    disabled,
    formikProps: { values, touched, errors, setFieldValue, setFieldTouched },
  } = props;

  return (
    <div key={name} id={name}>
      <RichTextArea
        name={name}
        label={label}
        className={inputClass}
        value={values[name]}
        disabled={disabled}
        pathToUploadFile={pathToUploadFile}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldValue={(field: string, value: any, shouldValidate?: boolean | undefined) => {
          setFieldValue(field, value, shouldValidate);
          setFieldTouched(field, true);
          onChangeField && onChangeField(field, value, setFieldValue);
        }}
        isTouched={!!touched[name]}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
