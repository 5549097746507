import { useState, useEffect } from "react";

import { useQuery } from "./";

enum TAB {
  LIBRARY = "Library",
}

function useQueryTab(initTab: number) {
  const query = useQuery();

  const [isLibrary, setIsLibrary] = useState(false);
  const [activeTab, setActiveTab] = useState(initTab);
  const [openFromLink, setOpenFromLink] = useState(false);
  const [isQuery, setIsQuery] = useState(false);

  useEffect(() => {
    const tab = query.get("tab");
    if (tab && !openFromLink) {
      setActiveTab(tab === TAB.LIBRARY ? 1 : 0);
      setIsLibrary(tab === TAB.LIBRARY);
      setIsQuery(true);
      setOpenFromLink(true);
    }
  }, [query, openFromLink]);

  return { isLibrary, setIsLibrary, activeTab, setActiveTab, isQuery, setIsQuery };
}

export default useQueryTab;
