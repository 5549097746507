export enum UserNotificationAction {
  OPEN_ASSIGNMENT = "OPEN_ASSIGNMENT",
  OPEN_ASSIGNMENT_MEMBER = "OPEN_ASSIGNMENT_MEMBER",
  OPEN_TRAINING = "OPEN_TRAINING",
  OPEN_CERTIFICATE = "OPEN_CERTIFICATE",

  OPEN_ASSIGNMENTS = "OPEN_ASSIGNMENTS",
  OPEN_TRAININGS = "OPEN_TRAININGS",
  OPEN_CERTIFICATES = "OPEN_CERTIFICATES",
}
export interface UserNotification {
  id: number;
  text: string;
  is_read: boolean;
  created_at: string;
  assignment_id: number | null;
  training_id: number | null;
  certificate_id: number | null;
  member_id: number | null;
  action: UserNotificationAction | null;
}
