import { useMemo } from "react";
import { MemberListItem, CertificateTemplate } from "@shared/models";

export default function useMemberHasCertificates(
  member: MemberListItem,
  certificateTemplates?: (CertificateTemplate | null)[],
) {
  const hasCertificates = useMemo(() => {
    if (!certificateTemplates) {
      return true;
    }
    for (const template of certificateTemplates.filter((t) => t)) {
      if (member.certificate_templates && !member.certificate_templates.find((t) => t.id === template?.id)) {
        return false;
      }
    }
    return true;
  }, [certificateTemplates, member]);

  return { hasCertificates };
}
