export enum TaskActionTypes {
  GET_TASKS = "@@TASK/GET_TASKS",
  GET_TASKS_SUCCESS = "@@TASK/GET_TASKS_SUCCESS",
  GET_TASKS_FAILURE = "@@TASK/GET_TASKS_FAILURE",

  GET_TASK = "@@TASK/GET_TASK",
  GET_TASK_SUCCESS = "@@TASK/GET_TASK_SUCCESS",
  GET_TASK_FAILURE = "@@TASK/GET_TASK_FAILURE",

  CREATE_TASK = "@@TASK/CREATE_TASK",
  CREATE_TASK_SUCCESS = "@@TASK/CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE = "@@TASK/CREATE_TASK_FAILURE",

  UPDATE_TASK = "@@TASK/UPDATE_TASK",
  UPDATE_TASK_SUCCESS = "@@TASK/UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAILURE = "@@TASK/UPDATE_TASK_FAILURE",

  UPDATE_TASK_STATUS = "@@TASK/UPDATE_TASK_STATUS",
  UPDATE_TASK_STATUS_SUCCESS = "@@TASK/UPDATE_TASK_STATUS_SUCCESS",
  UPDATE_TASK_STATUS_FAILURE = "@@TASK/UPDATE_TASK_STATUS_FAILURE",

  CLEAR_TASKS = "@@TASK/CLEAR_TASKS",

  GET_TASK_FILES = "@@TASK/GET_TASK_FILES",
  GET_TASK_FILES_SUCCESS = "@@TASK/GET_TASK_FILES_SUCCESS",
  GET_TASK_FILES_FAILURE = "@@TASK/GET_TASK_FILES_FAILURE",

  ADD_TASK_FILE = "@@TASK/ADD_TASK_FILE",
  ADD_TASK_FILE_SUCCESS = "@@TASK/ADD_TASK_FILE_SUCCESS",
  ADD_TASK_FILE_FAILURE = "@@TASK/ADD_TASK_FILE_FAILURE",

  DELETE_TASK_FILE = "@@TASK/DELETE_TASK_FILE",
  DELETE_TASK_FILE_SUCCESS = "@@TASK/DELETE_TASK_FILE_SUCCESS",
  DELETE_TASK_FILE_FAILURE = "@@TASK/DELETE_TASK_FILE_FAILURE",

  IMPORT_TASK = "@@TASK/IMPORT_TASK",
  IMPORT_TASK_SUCCESS = "@@TASK/IMPORT_TASK_SUCCESS",
  IMPORT_TASK_FAILURE = "@@TASK/IMPORT_TASK_FAILURE",

  SET_FILTER = "@@TASK/SET_FILTER",
  SET_CLEAR_TASK_FORM = "@@TASK/SET_CLEAR_TASK_FORM",
}
