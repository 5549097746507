import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { generateDynamicSchemaKeyString, mapToSchema } from "@shared/utils";
import {
  alertDeleteResponseSchema,
  alertResponseSchema,
  alertSchema,
  alertCreateUpdateRequestSchema,
  filterSchema,
  getAlertsResponseSchema,
} from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.ALERT> = {
  [API_ENUM.GET_ALERTS]: {
    request: {
      name: `${API.ALERT.GET_ALERTS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${API.ALERT.GET_ALERTS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getAlertsResponseSchema,
    },
  },
  [API_ENUM.GET_ALERT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ALERT.GET_ALERT("$id", "$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ALERT.GET_ALERT("$id", "$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: alertSchema,
    },
  },
  [API_ENUM.CREATE_ALERT]: {
    request: {
      name: `${API.ALERT.CREATE_ALERT}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: alertCreateUpdateRequestSchema,
    },
    response: {
      name: `${API.ALERT.CREATE_ALERT}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: alertResponseSchema,
    },
  },
  [API_ENUM.UPDATE_ALERT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ALERT.UPDATE_ALERT("$id", "$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: alertCreateUpdateRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ALERT.UPDATE_ALERT("$id", "$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: alertResponseSchema,
    },
  },
  [API_ENUM.DELETE_ALERT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ALERT.DELETE_ALERT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ALERT.DELETE_ALERT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: alertDeleteResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
