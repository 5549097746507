import React from "react";
import styled, { css } from "styled-components";

export type ButtonType = "button" | "submit";
export type ButtonStyle = "accent" | "accent-green" | "bordered" | "draft" | "clear";

interface ButtonProps {
  type?: ButtonType;
  buttonStyle?: ButtonStyle;
  className?: string;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  block?: boolean;
}

const disabledStyle = css`
  &:disabled {
    background: #9e9e9e;
    color: #fff;
    cursor: not-allowed;
  }
`;

//TODO: make import background from css module
const accentStyle = css`
  background: #e4611e;
  color: #fff;
  ${disabledStyle}
`;

const accentGeenStyle = css`
  background: #7cbc72;
  color: #fff;
  ${disabledStyle}
`;

const borderedStyle = css`
  background: white;
  color: #292929;
  border: 1px solid #292929;
  ${disabledStyle}
`;

const draftStyle = css`
  background: transparent;
  color: #e4611e;
  border: 2px solid #e4611e;
  font-weight: 600;
  &:disabled {
    border-color: #bbb;
    color: #bbb;
    cursor: not-allowed;
  }
`;

const clearStyle = css`
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    text-decoration: none;
  }
`;

function getButtonStyle(props: ButtonProps) {
  switch (props.buttonStyle) {
    case "bordered":
      return borderedStyle;
    case "draft":
      return draftStyle;
    case "clear":
      return clearStyle;
    case "accent-green":
      return accentGeenStyle;
    default:
      return accentStyle;
  }
}

const ButtonComponent = styled.button`
  padding: 16px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  ${(props: ButtonProps) => getButtonStyle(props)}
  ${({ block }: ButtonProps) => (block ? "display: block; width: 100%;" : "")}
`;

const Button: React.FC<ButtonProps> = (props) => {
  return <ButtonComponent {...props}>{props.children}</ButtonComponent>;
};

export default Button;
