import { getRequestSchema, getPaginatedResponseSchema } from "./shared.schema";

const baseActivityProperties = {
  id: { type: "number" },
  name: { type: "string" },
  status: { type: "string" },
  created_at: { type: "string" },
  updated_at: { type: ["string", "null"] },
  version: { type: "number" },
  creator: {
    type: "object",
    additionalProperties: false,
    required: ["id", "first_name", "last_name"],
    properties: {
      id: { type: "number" },
      first_name: { type: "string" },
      last_name: { type: "string" },
    },
  },
  parent_activity: {
    type: ["object", "null"],
    additionalProperties: false,
    required: ["id", "name"],
    properties: {
      id: { type: "number" },
      name: { type: "string" },
    },
  },
  companies_count: { type: "string" },
  company: {
    type: ["object", "null"],
    properties: {
      code: { type: "string" },
      name: { type: "string" },
    },
  },
};

const activityFromListSchema = {
  type: "object",
  additionalProperties: false,
  required: [
    "id",
    "name",
    "status",
    "created_at",
    "jsa_count",
    "trainings_count",
    "companies_count",
    "industries_count",
    "creator",
    "parent_activity",
  ],
  properties: {
    ...baseActivityProperties,
    jsa_count: { type: "string" },
    trainings_count: { type: "string" },
    industries_count: { type: "string" },
    current_company_count: { type: "string" },
    jsa: { type: ["array", "null"] },
    certificate_templates: { type: ["array", "null"] },
  },
};

export const activitySchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "status", "created_at", "creator", "parent_activity", "companies_count"],
  properties: {
    ...baseActivityProperties,
    industries: { type: "array" },
    jsa: { type: "array" },
    trainings: { type: "array" },
    available_jsa: { type: "array" },
    current_company_count: { type: "string" },
    assignments_count: { type: ["string", "null"] },
    last_assignment_date: { type: ["string", "null"] },
    certificate_templates: { type: ["array", "null"] },
  },
};

export const getActivitiesResponseSchema = getPaginatedResponseSchema(activityFromListSchema);

export const createActivityRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["name"],
  properties: {
    name: { type: "string" },
    industries: { type: "array" },
    jsa: { type: "array" },
    company_code: { type: ["string", "null"] },
    certificate_templates: { type: ["array", "null"] },
  },
};

export const updateActivityStatusRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["status"],
  properties: {
    status: { type: "string" },
  },
};

export const createActivityResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["activity", "message"],
  properties: {
    message: { type: "string" },
    activity: activitySchema,
  },
};

export const importActivityRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["company_code", "activities"],
  properties: {
    company_code: { type: "string" },
    activities: { type: "array" },
    is_archive_current: { type: "boolean" },
  },
};

export const importActivityResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message"],
  properties: {
    message: { type: "string" },
  },
};

export const getActivityRequestSchema = getRequestSchema({
  company_code: { type: ["string", "null"] },
});
