import React from "react";
import classnames from "classnames";

import "./index.scss";

export interface NoMatchesFoundProps {
  label?: string;
  iconClass?: string;
}

const NoMatchesFound = (props: NoMatchesFoundProps) => {
  const { label = "No matches found" } = props;
  return (
    <div className="no-matches-found">
      <div className={classnames("no-matches-icon", props.iconClass)} />
      <div className="no-matches-label">{label}</div>
    </div>
  );
};

export default NoMatchesFound;
