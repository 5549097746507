import moment from "moment";

import { Job } from "./Job";
import { BaseFile } from "./File";
import { Signature } from "./Signature";
import { Task } from "./Task";

import { AssignmentCheckInOutState, Certificate, Company, ShortUser } from ".";

export enum MemberStatus {
  Inactive = "Inactive",
  Active = "Active",
}

export enum MemberTrainingStatus {
  SCHEDULED = "Scheduled",
  MISSED = "Missed",
  COMPLITED = "Completed",
}

export enum MemberCertificateStatus {
  Valid = "Valid",
  Expired = "Expired",
}

interface CommonMemberProperties {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  job: Job | null;
  teams: {
    id: number;
    name: string;
  }[];
  status: MemberStatus;
  user_id: number | null;
  created_at: string;
  checkInOutState?: AssignmentCheckInOutState;
}

export interface Member extends CommonMemberProperties {
  phone: string;
  supervisor: ShortUser | null;
  is_supervisor: boolean;
  avatar: BaseFile | null;
  company?: Company;
  signature: Signature | null;
}

export interface MemberListItem extends CommonMemberProperties {
  avatar: BaseFile | null;
  supervisor: ShortUser | null;
  certificate_templates?: { id: number }[];
  is_deleted?: boolean;
}

export interface MemberWithAssignmentsInfoListItem extends CommonMemberProperties {
  assignments_count: string;
  last_assignment_start_date?: Date;
}

export interface MemberWithTrainingInfoListItem {
  id: number;
  first_name: string;
  last_name: string;
  assigned_by: string;
  training_id: number;
  training_name: string;
  training_is_auto_create_certificate?: boolean;
  training_repeat_every?: number | null;
  training_repeat_every_period?: string | null;
  training_expiration_date?: string | null;
  training_scheduled_start_date?: string | null;
  training_scheduled_end_date?: string | null;
  status?: MemberTrainingStatus;
}

export interface MemberWithCertificateInfoListItem {
  id: number;
  first_name: string;
  last_name: string;
  assigned_by?: string;
  certificate_id: number;
  certificate_name: string;
  certificate_expire_date: string;
  certificate_issued_by: string | null;
  certificate_issued_on: string | null;
  certificate_valid: number | null;
  certificate_valid_period: string | null;
  status?: MemberCertificateStatus | null;
}

export interface ShortMember extends ShortUser {
  job: Job | null;
}

export interface MemberJsaListItem {
  passed_jsa_id: number;
  jsa_id: number;
  name: string;
  project_name: string | null;
  assignment_id: number | null;
  assignment_date: string | null;
  sign_date: string;
}

export interface MemberPassedJsa {
  jsa_id: number;
  name: string;
  member_first_name: string;
  member_last_name: string;
  signature_url: string | null;
  signature_date: string | null;
  assignment_date: string | null;
  project_name: string | null;
  project_number: string | null;
  location_address: string | null;
  tasks: Task[];
}

export const getMemberWithCertificateInfoFromCertificate = (
  certificate: Certificate,
): MemberWithCertificateInfoListItem => {
  return {
    id: certificate.member.id,
    first_name: certificate.member.first_name,
    last_name: certificate.member.last_name,
    certificate_id: certificate.id,
    certificate_name: certificate.name,
    certificate_valid: certificate.valid,
    certificate_valid_period: certificate.valid_period,
    certificate_issued_by: certificate.issued_by,
    certificate_issued_on: certificate.issued_on,
    certificate_expire_date: certificate.expire_date,
    status: moment(certificate.expire_date).isAfter(moment())
      ? MemberCertificateStatus.Valid
      : MemberCertificateStatus.Expired,
  };
};
