import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./index.scss";
import classNames from "classnames";
import { Button, Icon } from "@shared/components";
import { useOutsideClick } from "@shared/hooks";

import { ButtonStyle } from "..";

export interface DropDownButtonProps {
  className?: string;
  mainButtonName?: string;
  optionNames?: string[];
  optionClickHandlers?: (() => void)[];
  buttonStyle?: ButtonStyle;
}

const DropDownButton: FC<DropDownButtonProps> = (props) => {
  const { className, mainButtonName, optionNames, optionClickHandlers, buttonStyle } = props;
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { isOutside } = useOutsideClick(wrapperRef);

  const switchIsOpen = useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault();
      setIsOpen((prev) => !prev);
    },
    [setIsOpen],
  );

  useEffect(() => {
    setIsOpen(false);
  }, [isOutside]);

  const handlerClick = useCallback(
    (index: number) => {
      if (optionClickHandlers) {
        optionClickHandlers[index]();
        setIsOpen(false);
      }
    },
    [optionClickHandlers],
  );

  const classes = classNames("drop-down-wrapper", className, isOpen ? "open" : "");

  return (
    <div className={classes} ref={wrapperRef}>
      <Button buttonStyle={buttonStyle} className={`drop-down-main-button ${buttonStyle || ""}`} onClick={switchIsOpen}>
        <b>{mainButtonName}</b>
        <Icon type={buttonStyle === "draft" ? "chevron-orange" : "back-white"} className="arrow-icon" />
      </Button>
      <div className="drop-down-elements-wrapper">
        {optionNames &&
          optionClickHandlers &&
          optionNames?.map((optionName, index) => (
            <div className="drop-down-element" key={optionName} onClick={handlerClick.bind(null, index)}>
              <b>{optionName}</b>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DropDownButton;
