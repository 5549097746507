import { useMemo } from "react";
import { Training, TrainingPass, MemberTrainingStatus } from "@shared/models";
import { getUSFormattedDate, getFullUserName, isDateInThePast, getUSFormattedDateShort } from "@shared/utils";

function useTrainingExtraInfoHook(training: Training | null, trainingPass?: TrainingPass | null) {
  const updateInfo = useMemo(() => {
    if (training?.updated_by) {
      return `Last updated on ${getUSFormattedDate(training?.updated_at)} by ${training?.updated_by}`;
    }

    return `Created on ${getUSFormattedDate(training?.created_at)} by ${
      training ? getFullUserName(training.creator) : ""
    }`;
  }, [training]);

  const recurringInfo = useMemo(() => {
    if (!training?.is_recurring_training) {
      return null;
    }
    return `Recurring every ${training.repeat_every} ${String(training.repeat_every_period).toLowerCase()}s `;
  }, [training]);

  const status = useMemo<MemberTrainingStatus>(() => {
    if (!trainingPass) {
      const checkDate = training?.expiration_date || training?.scheduled_end_date;
      return isDateInThePast(checkDate) ? MemberTrainingStatus.MISSED : MemberTrainingStatus.SCHEDULED;
    }
    return MemberTrainingStatus.COMPLITED;
  }, [trainingPass, training]);

  const statusText = useMemo(() => {
    switch (status) {
      case MemberTrainingStatus.MISSED:
      case MemberTrainingStatus.SCHEDULED:
        return status;
      case MemberTrainingStatus.COMPLITED:
        return trainingPass && trainingPass?.confirm_user?.id === trainingPass?.creator_user.id
          ? `Completed by ${getFullUserName(trainingPass.creator_user)}`
          : "Completed";
    }
  }, [status, trainingPass]);

  const confirmedText = useMemo(() => {
    if (!trainingPass || !trainingPass.confirm_user || !trainingPass.confirmed_at) {
      return null;
    }
    return `Marked as passed by ${getFullUserName(trainingPass.confirm_user)},
    ${getUSFormattedDateShort(trainingPass.confirmed_at)}`;
  }, [trainingPass]);

  return {
    status,
    statusText,
    updateInfo,
    recurringInfo,
    confirmedText,
  };
}

export default useTrainingExtraInfoHook;
