import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FileLoader } from "@ckeditor/ckeditor5-upload/src/filerepository";
import { MAX_IMAGE_SIZE, METHODS } from "@shared/constants";
import { actions } from "@shared/store";
import { getBase64, request, validateFile } from "@shared/utils";
import { Dispatch } from "redux";

export class UploadAdapter {
  public loader: FileLoader;
  public url: string;
  public dispatch: Dispatch;

  constructor(loader: FileLoader, url: string, dispatch: Dispatch) {
    this.loader = loader;
    this.url = url;
    this.dispatch = dispatch;
  }

  async upload() {
    return this.loader.file.then((file: File | null) => {
      if (!file) return this.abort();

      const fileValidationError = validateFile(file, { maxSize: MAX_IMAGE_SIZE });
      if (fileValidationError) {
        this.dispatch(
          actions.showNotification({
            message: fileValidationError,
            appearance: "error",
          }),
        );

        return this.abort();
      }

      return getBase64(file)
        .then((base64File) => {
          return request(
            METHODS.POST,
            this.url,
          )({
            base64: base64File,
            name: file.name,
          });
        })
        .then((data) => {
          return { default: data.url };
        })
        .catch((e: { message: string }) => {
          if (e.message) {
            this.dispatch(
              actions.showNotification({
                message: e.message,
                appearance: "error",
              }),
            );
          }

          return this.abort();
        });
    });
  }

  abort() {
    return Promise.reject();
  }
}

// CKEditor FileRepository
export function uploadAdapterPlugin(editor: ClassicEditor, url: string, dispatch: Dispatch) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => new UploadAdapter(loader, url, dispatch);
}
