import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";

const selectCompany = (state: AppState) => state.company;

export const getCompanies = () => createSelector(selectCompany, (state) => state.companies);
export const getCurrentCompany = () => createSelector(selectCompany, (state) => state.currentCompany);
export const getEditedCompany = () => createSelector(selectCompany, (state) => state.editedCompany);
export const getCompaniesTotal = () => createSelector(selectCompany, (state) => state.companiesTotal);
export const getFilter = () => createSelector(selectCompany, (state) => state.filter);
