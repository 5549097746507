import * as Yup from "yup";
import { FieldItem } from "@shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { FieldItemType } from "@shared/interfaces";
import { ContactUsFormShape } from "@containers/ContactUs/interface";

const MESSAGE_TEXT_LIMIT = 500;

export const validationSchema: Yup.SchemaOf<ContactUsFormShape> = Yup.object().shape({
  subject: Yup.string().trim().max(50, `Subject text ${FORM_ERROR_MESSAGES.LONG}`).optional(),
  text: Yup.string()
    .trim()
    .max(MESSAGE_TEXT_LIMIT, `Text ${FORM_ERROR_MESSAGES.LONG}`)
    .required(FORM_ERROR_MESSAGES.REQUIRED),
  version: Yup.string(),
});

export const getFields = () => {
  const fields: FieldItem[] = [
    {
      type: FieldItemType.TEXT,
      name: "subject",
      label: "Subject",
      placeholder: "Type something",
      wrapperClass: "input-line",
    },
    {
      type: FieldItemType.TEXTAREA,
      name: "text",
      label: "Message *",
      placeholder: "Type something",
      wrapperClass: "message-field",
      rows: 8,
      showCharacterCounter: true,
      characterCounterLimit: MESSAGE_TEXT_LIMIT,
    },
  ];

  return fields;
};

export const getInitValues = (): ContactUsFormShape => ({
  subject: "",
  text: "",
});
