import React, { useCallback } from "react";
import { MenuListProps } from "react-select";
import { BaseEntity, FieldItemType } from "@shared/interfaces";
import { ErrorMessage, OptionsSelector } from "@shared/components/Common/";

import { RenderField, GenerateRenderField, Handlers } from "../../interfaces";

export interface OptionsSelectorField extends RenderField {
  type: FieldItemType.OPTIONS_SELECTOR;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  menuWrapper?: (props: MenuListProps) => JSX.Element;
  unique: boolean;
  title: string;
  addText: string;
  noItemsAvailable: string;
  uniqueError: string;
  optionPrefix?: string;
  onChangeField?: () => void;
}

export const GenerateOptionsSelector: GenerateRenderField<OptionsSelectorField & Handlers> = (props) => {
  const {
    formikProps,
    handlers,
    disabled,
    name,
    placeholder,
    menuWrapper,
    unique,
    title,
    addText,
    noItemsAvailable,
    uniqueError,
    optionPrefix,
    onChangeField,
  } = props;

  const { getData, selectData, prepareOptionFunction, selectTotalCount } = (handlers || {})[name];

  const onChange = useCallback(
    (newOptions: Array<BaseEntity | null>) => {
      formikProps.setFieldValue(name, newOptions);
      onChangeField && onChangeField();
    },
    [formikProps, name, onChangeField],
  );

  return (
    <>
      <OptionsSelector
        selectData={selectData}
        getData={getData}
        selectTotalCount={selectTotalCount}
        name={name}
        prepareOptionFunction={prepareOptionFunction}
        unique={unique}
        title={title}
        addText={addText}
        noItemsAvailable={noItemsAvailable}
        uniqueError={uniqueError}
        placeholder={placeholder}
        disabled={disabled}
        menuWrapper={menuWrapper}
        onChange={onChange}
        optionPrefix={optionPrefix}
        selectedOptions={formikProps.values[name] as BaseEntity[]}
      />
      <ErrorMessage isTouched={!!formikProps.touched[name]} error={formikProps.errors[name]?.toString()} />
    </>
  );
};
