import React, { useCallback } from "react";
import moment from "moment";
import { ErrorMessage, DatePicker } from "@shared/components/Common/";
import { FieldItemType } from "@shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface TimePickerFieldItem extends RenderField {
  type: FieldItemType.TIMEPICKER;
  label?: string;
  format?: string;
  disabled?: boolean;
  disablePast?: boolean;
  disabledDate?: (date?: Date) => boolean;
  onChange?: (date: Date | null) => void;
  showMeridian?: boolean;
  hideErrorHelper?: boolean;
  calendarDefaultDate?: Date;
}

export const GenerateTimepicker: GenerateRenderField<TimePickerFieldItem> = (props) => {
  const {
    name,
    wrapperClass,
    label,
    disabled,
    format,
    onChange,
    showMeridian,
    formikProps: { setFieldValue, values, touched, errors },
    hideErrorHelper,
    disablePast,
    disabledDate,
    calendarDefaultDate,
  } = props;

  const handleChange = useCallback(
    (value: moment.Moment | null) => {
      const newValue = value ? value.toDate() : null;
      setFieldValue(name, newValue);
      onChange && onChange(newValue);
    },
    [setFieldValue, name, onChange],
  );

  return (
    <div key={name} id={name}>
      <DatePicker
        label={label}
        className={wrapperClass}
        onChange={handleChange}
        disabled={disabled}
        format={format}
        value={values[name] ? moment(values[name]) : null}
        view="time"
        showMeridian={showMeridian}
        disablePast={disablePast}
        disabledDate={disabledDate}
        calendarDefaultDate={calendarDefaultDate}
      />
      {!hideErrorHelper && <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />}
    </div>
  );
};
