import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "@shared/constants";
import { ORDER_TYPE } from "@shared/interfaces";

import { TaskStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const taskDefaultFilter = {
  ...defaultFilter,
  sort_by: ["created_at"],
  sort_order: ORDER_TYPE.DESC,
};

export const initialState: TaskStateType = {
  tasks: [],
  currentTask: null,
  tasksTotal: 0,
  currentTaskFiles: [],
  filter: { ...taskDefaultFilter },
  clearTaskForm: false,
};

const reducer = createReducer<TaskStateType, Action>(initialState)
  .handleAction(actions.getTasks.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.tasks = clear ? rows : [...nextState.tasks, ...rows];
      nextState.tasksTotal = count;
    }),
  )
  .handleAction(actions.getTask.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentTask = payload;
    }),
  )
  .handleAction(actions.updateTask.success, (state, action) =>
    produce(state, (nextState) => {
      const { task } = action.payload;
      nextState.currentTask = task;
    }),
  )
  .handleAction(actions.getTaskFiles.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentTaskFiles = action.payload;
    }),
  )
  .handleAction(actions.addTaskFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentTaskFiles = [action.payload.file, ...state.currentTaskFiles];
    }),
  )
  .handleAction(actions.deleteTaskFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentTaskFiles = state.currentTaskFiles.filter((f) => f.id !== action.payload.file_id);
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...taskDefaultFilter };
    }),
  )
  .handleAction(actions.setClearTaskForm, (state, action) =>
    produce(state, (nextState) => {
      nextState.clearTaskForm = action.payload;
    }),
  );
export { reducer as TaskReducer };
