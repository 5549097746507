import React, { FC, useCallback, useMemo } from "react";
import { Training, TrainingPass, TrainingPassMember, MemberTrainingStatus } from "@shared/models";
import { Button, Icon } from "@shared/components";
import { getFullUserName, getUSFormattedDateShort } from "@shared/utils";
import classNames from "classnames";
import { useTrainingExtraInfo } from "@containers/TrainingAndCertificate/hooks";

import "./index.scss";

export interface TrainingTeamMemberCardProps {
  member: TrainingPassMember;
  training: Training;
  onClick?: (member: TrainingPassMember) => void;
  onPassClick?: (member: TrainingPassMember) => void;
  onConfirmClick?: (member: TrainingPassMember) => void;
  trainingPassItem?: TrainingPass;
}

const TrainingTeamMemberCard: FC<TrainingTeamMemberCardProps> = (props) => {
  const { member, onClick, onPassClick, trainingPassItem, onConfirmClick, training } = props;

  const { status, statusText, confirmedText } = useTrainingExtraInfo(training, trainingPassItem);

  const memberFullName = useMemo(() => {
    return getFullUserName(member);
  }, [member]);

  const onMemberClick = useCallback(() => {
    onClick?.(member);
  }, [onClick, member]);

  const localOnPassClick = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation();
      onPassClick && onPassClick(member);
    },
    [onPassClick, member],
  );

  const localOnConfirmClick = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation();
      onConfirmClick && onConfirmClick(member);
    },
    [onConfirmClick, member],
  );

  const footerButton = useMemo(() => {
    switch (status) {
      case MemberTrainingStatus.SCHEDULED:
      case MemberTrainingStatus.MISSED:
        return (
          <Button buttonStyle="clear" className="mark-complete" onClick={localOnPassClick}>
            <Icon className="check-icon" type="check-green" />
            Complete
          </Button>
        );
      case MemberTrainingStatus.COMPLITED:
        if (!trainingPassItem?.confirm_user) {
          return (
            <Button buttonStyle="clear" className="mark-confirmed" onClick={localOnConfirmClick}>
              <Icon className="check-icon" type="check-orange" />
              Mark As Passed
            </Button>
          );
        }
        if (trainingPassItem.confirm_user.id !== trainingPassItem.creator_user.id) {
          return <div className="training-pass-confirmed-hint">{confirmedText}</div>;
        }
    }
  }, [status, localOnPassClick, localOnConfirmClick, trainingPassItem, confirmedText]);

  return (
    <div className="training-team-member-card" onClick={onMemberClick}>
      <div className="training-team-member-card-member-info">
        <div className="member">
          <p>Team Member</p>
          {memberFullName}
        </div>
        <div className="position">
          <p>Position</p>
          {member.job?.name}
        </div>
        <div className="status-container">
          <div className={classNames("training-member-status", String(status).toLowerCase())}>{statusText}</div>
          {status === MemberTrainingStatus.COMPLITED && (
            <div className="training-member-status-hint">{getUSFormattedDateShort(trainingPassItem?.created_at)}</div>
          )}
        </div>
        {trainingPassItem?.signature && (
          <div className="training-signature">
            <img src={trainingPassItem.signature.file.url} />
          </div>
        )}
      </div>
      {footerButton && <div className="training-member-button">{footerButton}</div>}
    </div>
  );
};

export default TrainingTeamMemberCard;
