import { AnyAction, combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import { AuthReducer } from "@containers/Auth/store/reducer";
import { UserReducer } from "@containers/User/store/reducer";
import { CompanyReducer } from "@containers/Company/store/reducer";
import { AppState } from "@shared/interfaces";
import { SharedReducer } from "@shared/store/reducer";
import { MemberReducer } from "@containers/Member/store/reducer";
import { ActivityReducer } from "@containers/Activity/store/reducer";
import { TaskReducer } from "@containers/Task/store/reducer";
import { JsaReducer } from "@containers/Jsa/store/reducer";
import { AssignmentReducer } from "@containers/Assignment/store/reducer";
import { DocumentReducer } from "@containers/Documents/store/reducer";
import { TrainingAndCertificateReducer } from "@containers/TrainingAndCertificate/store/reducer";
import { AlertReducer } from "@containers/Alert/store/reducer";
import { UserNotificationReducer } from "@containers/UserNotification/store/reducer";
import { ProjectReducer } from "@containers/Project/store/reducer";
import { TeamReducer } from "@containers/Team/store/reducer";

export default (history: History) => {
  const appReducer = combineReducers({
    shared: SharedReducer,
    auth: AuthReducer,
    user: UserReducer,
    company: CompanyReducer,
    member: MemberReducer,
    activity: ActivityReducer,
    task: TaskReducer,
    jsa: JsaReducer,
    assignment: AssignmentReducer,
    document: DocumentReducer,
    trainingAndCertificate: TrainingAndCertificateReducer,
    alert: AlertReducer,
    userNotification: UserNotificationReducer,
    project: ProjectReducer,
    team: TeamReducer,
    router: connectRouter(history),
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
