export enum UserNotificationActionTypes {
  GET_USER_NOTIFICATIONS = "@@USER_NOTIFICATION/GET_USER_NOTIFICATIONS",
  GET_USER_NOTIFICATIONS_SUCCESS = "@@USER_NOTIFICATION/GET_USER_NOTIFICATIONS_SUCCESS",
  GET_USER_NOTIFICATIONS_FAILURE = "@@USER_NOTIFICATION/GET_USER_NOTIFICATIONS_FAILURE",

  GET_USER_NOTIFICATIONS_UNREAD_COUNT = "@@USER_NOTIFICATION/GET_USER_NOTIFICATIONS_UNREAD_COUNT",
  GET_USER_NOTIFICATIONS_UNREAD_COUNT_SUCCESS = "@@USER_NOTIFICATION/GET_USER_NOTIFICATIONS_UNREAD_COUNT_SUCCESS",
  GET_USER_NOTIFICATIONS_UNREAD_COUNT_FAILURE = "@@USER_NOTIFICATION/GET_USER_NOTIFICATIONS_UNREAD_COUNT_FAILURE",

  ADD_USER_NOTIFICATION = "@@USER_NOTIFICATION/ADD_USER_NOTIFICATION",

  READ_USER_NOTIFICATIONS = "@@USER_NOTIFICATION/READ_USER_NOTIFICATIONS",
  READ_USER_NOTIFICATIONS_SUCCESS = "@@USER_NOTIFICATION/READ_USER_NOTIFICATIONS_SUCCESS",
  READ_USER_NOTIFICATIONS_FAILURE = "@@USER_NOTIFICATION/READ_USER_NOTIFICATIONS_FAILURE",

  READ_USER_NOTIFICATION = "@@USER_NOTIFICATION/READ_USER_NOTIFICATION",
  READ_USER_NOTIFICATION_SUCCESS = "@@USER_NOTIFICATION/READ_USER_NOTIFICATION_SUCCESS",
  READ_USER_NOTIFICATION_FAILURE = "@@USER_NOTIFICATION/READ_USER_NOTIFICATION_FAILURE",

  CLEAR_USER_NOTIFICATIONS = "@@USER_NOTIFICATION/CLEAR_USER_NOTIFICATIONS",

  SET_FILTER = "@@USER_NOTIFICATION/SET_FILTER",
}
