import React, { FC } from "react";
import "./index.scss";

export interface ErrorMessageProps {
  isTouched?: boolean;
  error?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { isTouched, error } = props;

  return isTouched && error ? <div className="error-message">{error}</div> : null;
};
