import React, { FC } from "react";
import { MemberWithTrainingInfoListItem } from "@shared/models";
import { Table } from "@shared/components";
import { SortTable } from "@shared/interfaces";

import { memberTableProperties } from "./tableHelpers";

export interface MembersTrainingTableProps extends SortTable {
  members: MemberWithTrainingInfoListItem[];
  onLoadNextPage: () => void;
  onEditMember: (member: MemberWithTrainingInfoListItem) => void;
}

const MembersTrainingTable: FC<MembersTrainingTableProps> = (props) => {
  const { members, onLoadNextPage, onEditMember, sortOrder, sortBy, onChangeSorting } = props;

  return (
    <div className="members-table">
      <Table<MemberWithTrainingInfoListItem>
        onRowClick={onEditMember}
        onFinishScroll={onLoadNextPage}
        items={members}
        properties={memberTableProperties}
        sortOrder={sortOrder}
        sortBy={sortBy}
        onChangeSorting={onChangeSorting}
      />
    </div>
  );
};

export default MembersTrainingTable;
