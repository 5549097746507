export const loginRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["email", "password"],
  properties: {
    email: { type: "string" },
    password: { type: "string" },
  },
};

export const loginResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    token: { type: "string" },
  },
};

export const restorePasswordRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["email"],
  properties: {
    email: { type: "string" },
  },
};

export const restorePasswordResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
  },
};

export const setPasswordRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["password", "hash"],
  properties: {
    password: { type: "string" },
    hash: { type: "string" },
  },
};

export const setPasswordResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    token: { type: "string" },
    message: { type: "string" },
  },
};

export const registrationRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["hash", "first_name", "last_name", "password"],
  properties: {
    hash: { type: "string" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    password: { type: "string" },
  },
};

export const registrationResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["token", "message"],
  properties: {
    token: { type: "string" },
    message: { type: "string" },
  },
};

export const activateRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["hash"],
  properties: {
    hash: { type: "string" },
  },
};

export const activateResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    token: { type: "string" },
    user: {
      type: "object",
      additionalProperties: false,
      required: ["first_name", "last_name", "email"],
      properties: {
        first_name: { type: "string" },
        last_name: { type: "string" },
        email: { type: "string" },
      },
    },
    message: { type: ["string", "null"] },
  },
};
