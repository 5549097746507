import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";

const selectJsa = (state: AppState) => state.jsa;

export const getJsa = () => createSelector(selectJsa, (state) => state.jsa);
export const getJsaTotal = () => createSelector(selectJsa, (state) => state.jsaTotal);
export const getJsaFiles = () => createSelector(selectJsa, (state) => state.currentJsaFiles);
export const getCurrentJsa = () => createSelector(selectJsa, (state) => state.currentJsa);
export const getFilter = () => createSelector(selectJsa, (state) => state.filter);
export const getClearJsaForm = () => createSelector(selectJsa, (state) => state.clearJsaForm);
