import React from "react";
import { Icon } from "@shared/components";

import "./index.scss";

export interface AuthModalProps {
  title: string;
  backClick?: () => void;
}

const AuthModal: React.FC<AuthModalProps> = (props) => {
  const { title, children, backClick } = props;

  return (
    <div className="auth-modal">
      {backClick && (
        <div className="auth-back-button" onClick={backClick}>
          <Icon type="back" />
          Back
        </div>
      )}
      <div className="auth-modal-title">{title}</div>
      <div className="auth-modal-content">{children}</div>
    </div>
  );
};

export default AuthModal;
