import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";

const selectUserNotification = (state: AppState) => state.userNotification;

export const getUserNotifications = () => createSelector(selectUserNotification, (state) => state.userNotifications);
export const getUserNotificationsTotal = () =>
  createSelector(selectUserNotification, (state) => state.userNotificationsTotal);
export const getUserNotificationsUnreadCount = () =>
  createSelector(selectUserNotification, (state) => state.userNotificationsUnreadCount);
export const getFilter = () => createSelector(selectUserNotification, (state) => state.filter);
