export enum JsaActionTypes {
  GET_JSA = "@@JSA/GET_JSA",
  GET_JSA_SUCCESS = "@@JSA/GET_JSA_SUCCESS",
  GET_JSA_FAILURE = "@@JSA/GET_JSA_FAILURE",

  CLEAR_JSA = "@@JSA/CLEAR_JSA",

  GET_JSA_ITEM = "@@JSA/GET_JSA_ITEM",
  GET_JSA_ITEM_SUCCESS = "@@JSA/GET_JSA_ITEM_SUCCESS",
  GET_JSA_ITEM_FAILURE = "@@JSA/GET_JSA_ITEM_FAILURE",

  CREATE_JSA = "@@JSA/CREATE_JSA",
  CREATE_JSA_SUCCESS = "@@JSA/CREATE_JSA_SUCCESS",
  CREATE_JSA_FAILURE = "@@JSA/CREATE_JSA_FAILURE",

  UPDATE_JSA = "@@JSA/UPDATE_JSA",
  UPDATE_JSA_SUCCESS = "@@JSA/UPDATE_JSA_SUCCESS",
  UPDATE_JSA_FAILURE = "@@JSA/UPDATE_JSA_FAILURE",

  UPDATE_JSA_STATUS = "@@JSA/UPDATE_JSA_STATUS",
  UPDATE_JSA_STATUS_SUCCESS = "@@JSA/UPDATE_JSA_STATUS_SUCCESS",
  UPDATE_JSA_STATUS_FAILURE = "@@JSA/UPDATE_JSA_STATUS_FAILURE",

  GET_JSA_FILES = "@@JSA/GET_JSA_FILES",
  GET_JSA_FILES_SUCCESS = "@@JSA/GET_JSA_FILES_SUCCESS",
  GET_JSA_FILES_FAILURE = "@@JSA/GET_JSA_FILES_FAILURE",

  ADD_JSA_FILE = "@@JSA/ADD_JSA_FILE",
  ADD_JSA_FILE_SUCCESS = "@@JSA/ADD_JSA_FILE_SUCCESS",
  ADD_JSA_FILE_FAILURE = "@@JSA/ADD_JSA_FILE_FAILURE",

  DELETE_JSA_FILE = "@@JSA/DELETE_JSA_FILE",
  DELETE_JSA_FILE_SUCCESS = "@@JSA/DELETE_JSA_FILE_SUCCESS",
  DELETE_JSA_FILE_FAILURE = "@@JSA/DELETE_JSA_FILE_FAILURE",

  IMPORT_JSA = "@@JSA/IMPORT_JSA",
  IMPORT_JSA_SUCCESS = "@@JSA/IMPORT_JSA_SUCCESS",
  IMPORT_JSA_FAILURE = "@@JSA/IMPORT_JSA_FAILURE",

  SET_FILTER = "@@JSA/SET_FILTER",
  SET_CLEAR_JSA_FORM = "@@JSA/SET_CLEAR_JSA_FORM",
}
