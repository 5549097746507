import { PERMISSION, ROLE_CODE } from "@shared/constants";
import { User } from "@shared/models/User";
import { UserRole } from "@shared/models/UserRole";

import {
  APP_ROUTES_TO_PERMISSIONS,
  APP_LEVEL_PERMISSIONS,
  COMPANY_ROUTES_TO_PERMISSIONS,
  COMPANY_LEVEL_PERMISSIONS,
} from "./constants";
import { tokenHandler } from "../";

export const hasAdminRole = (user: User | null) => {
  if (!user) {
    return false;
  }

  return user.user_roles.find((r) => !!r.role.is_app_admin);
};

export const hasRole = (role: ROLE_CODE, user: User | null, companyCode?: string) => {
  if (!user) {
    return false;
  }

  let filterFunction = (userRole: UserRole): boolean => userRole.role.code === role;

  if (companyCode) {
    filterFunction = (userRole: UserRole): boolean =>
      userRole.role.code === role && userRole.company?.code === companyCode;
  }

  return user.user_roles.some(filterFunction);
};

export const getAuthorizedMemberByCompany = (user: User, companyCode?: string) => {
  if (!user || !companyCode) return null;

  const members = user?.members || [];
  return members.find((m) => m.company && m.company.code === companyCode);
};

export const getUserRoles = () => {
  const user: User = tokenHandler.getUser();
  return user?.user_roles || [];
};

export const getUserCompanies = () => {
  const userRoles = getUserRoles();
  const userRolesWithCompany = userRoles.filter((ur) => !!ur.company);
  return userRolesWithCompany.map((ur) => ur.company?.code);
};

export const checkCompanyAccess = (companyCode: string): UserRole | undefined => {
  const userRoles = getUserRoles();
  return userRoles.find((ur) => ur.company && ur.company.code === companyCode);
};

export const getDefaultUrlForAppLevel = (user: User) => {
  const userRoles = user.user_roles || [];
  const appRole = userRoles.find((ur) => !ur.company);
  if (!appRole) {
    return APP_ROUTES_TO_PERMISSIONS[PERMISSION.VIEW_USERS];
  }

  const route = APP_LEVEL_PERMISSIONS.filter((p) =>
    appRole.role.permissions.find((permission) => permission.name === p),
  ).reduce((r: string, i: string) => {
    return r ? r : APP_ROUTES_TO_PERMISSIONS[i];
  }, "");

  return route ? route : APP_ROUTES_TO_PERMISSIONS[PERMISSION.VIEW_USERS];
};

export const getDefaultUrlForCompanyLevel = (user: User, companyCode: string) => {
  const userRoles = user.user_roles || [];
  const appRole = userRoles.find((ur) => (ur.company && ur.company.code === companyCode) || !ur.company);
  if (!appRole) {
    return "";
  }

  const route = COMPANY_LEVEL_PERMISSIONS.filter((p) =>
    appRole.role.permissions.find((permission) => permission.name === p),
  ).reduce((r: string, i: string) => {
    return r ? r : COMPANY_ROUTES_TO_PERMISSIONS[i];
  }, "");

  return route ? route : "";
};

type CheckPermissionOption = { companyCode?: string | null; onlyAppAdmin?: boolean };

export const checkPermission = (permission?: string, option?: CheckPermissionOption) => {
  if (process.env.REACT_APP_ENV === "test") {
    return true;
  }
  const user: User = tokenHandler.getUser();
  const token = tokenHandler.get();
  if (token && !user) {
    return true;
  }

  if (token && user && !permission) {
    return true;
  }

  if (!user || !user.user_roles) {
    return false;
  }

  const adminUserRole = hasAdminRole(user);
  if (option && option.onlyAppAdmin && !adminUserRole) {
    return false;
  }

  const userRole = adminUserRole
    ? adminUserRole
    : option && option.companyCode
    ? checkCompanyAccess(option.companyCode)
    : null;
  if (!userRole) {
    return false;
  }

  if (permission) {
    const rolePermissions = userRole?.role?.permissions || [];
    const hasPermission = rolePermissions.find((p) => p.name === permission);
    if (!hasPermission) {
      return false;
    }
  }

  return true;
};

export const checkPermissions = (permissions: string[], option?: CheckPermissionOption) => {
  return permissions.every((permissionString) => checkPermission(permissionString, option));
};

export function stringIsUrl(value: string) {
  const res = value.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );
  return res !== null;
}
