import React, { FC } from "react";
import { CertificateTemplate, MemberListItem } from "@shared/models";
import { Icon } from "@shared/components";
import { useMemberHasCertificates } from "@containers/Member/hooks";

import "./index.scss";

export interface TeamMemberSelectorRowProps {
  member: MemberListItem;
  isSelected: boolean;
  certificateTemplates?: (CertificateTemplate | null)[];
}

const TeamMemberSelectorRow: FC<TeamMemberSelectorRowProps> = (props) => {
  const { member, isSelected, certificateTemplates } = props;

  const { hasCertificates } = useMemberHasCertificates(member, certificateTemplates);

  return (
    <div className="team-member-selector-row">
      <div className="team-member-main-cell">
        <div className="team-member-avatar">{member.avatar && <img src={member.avatar.url} />}</div>
        <div className="team-member-info">
          <div className="team-member-name" title={`${member.first_name} ${member.last_name}`}>
            {member.first_name} {member.last_name}
          </div>
          {member.job && <div className="team-member-position">{member.job.name}</div>}
        </div>
      </div>
      <div className="team-member-teams-cell">{member.teams.map((t) => t.name).join(", ")}</div>
      <div className="team-member-certificate">{!hasCertificates ? "Certificate is missing" : ""}</div>
      <div className="team-member-selected-marker">{isSelected && <Icon type="option-selected-green" />}</div>
    </div>
  );
};

export default TeamMemberSelectorRow;
