import { membersForAssignmentItemSchema } from "./member.schema";
import { baseEntitySchema, shortUserSchema, shortMemberSchema } from "./shared.schema";
import { locationCommonProperties } from "./location.schema";
import { certificateTemplateSchema } from "./certificate.schema";

import { commonTaskProperties, formTaskProperties } from ".";

const commonAssignmentProperties = {
  id: { type: "number" },
  status: { type: "string" },
  start_date: { type: "string" },
  end_date: { type: "string" },
  description: { type: ["string", "null"] },
  activities: {
    type: "array",
    items: baseEntitySchema,
  },
  location: {
    type: "object",
    properties: {
      ...locationCommonProperties,
    },
    additionalProperties: false,
  },
};

export const assignmentForListSchema = {
  type: "object",
  required: ["id", "start_date", "activities", "location"],
  properties: {
    ...commonAssignmentProperties,
    project: {
      type: ["object", "null"],
      properties: {
        id: { type: "number" },
        name: { type: "string" },
      },
    },
  },
  additionalProperties: false,
};

export const assignmentSchema = {
  type: "object",
  required: ["id", "start_date", "location", "end_date", "version", "creator", "members", "assignment_activities"],
  properties: {
    ...commonAssignmentProperties,
    project: {
      type: ["object", "null"],
      properties: {
        id: { type: "number" },
        name: { type: "string" },
        number: { type: ["string", "null"] },
      },
    },
    created_at: { type: "string" },
    updated_at: { type: ["string", "null"] },
    updated_by: { type: ["string", "null"] },
    check_in_count: { type: "number" },
    version: { type: ["number", "null"] },
    creator: shortUserSchema,
    members: {
      type: "array",
      items: membersForAssignmentItemSchema,
    },
    assignment_activities: {
      type: "array",
      items: {
        type: "object",
        additionalProperties: false,
        properties: {
          id: { type: "number" },
          activity: baseEntitySchema,
          jsa: {
            type: "array",
            items: baseEntitySchema,
          },
        },
      },
    },
    certificate_templates: {
      type: ["array", "null"],
      items: [certificateTemplateSchema],
    },
  },
  additionalProperties: false,
};

export const createAssignmentSchema = {
  type: "object",
  required: ["status", "location_place_id", "start_date", "end_date", "members", "activities"],
  additionalProperties: false,
  properties: {
    project_id: { type: ["number", "null"] },
    status: { type: "string" },
    location_place_id: { type: "string" },
    start_date: { type: ["string", "object"] },
    end_date: { type: ["string", "object"] },
    company_code: { type: "string" },
    description: { type: ["string", "null"] },
    members: {
      type: "array",
      items: {
        type: "number",
      },
    },
    activities: {
      type: "array",
      items: {
        type: "object",
        additionalProperties: false,
        properties: {
          activity_id: { type: "number" },
          jsa: {
            type: "array",
            items: { type: "number" },
          },
        },
      },
    },
    certificate_templates: {
      type: "array",
      items: [{ type: "number" }],
    },
  },
};

export const updateAssignmentStatusSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    status: { type: "string" },
  },
};

export const updateAssignmentResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "assignment"],
  properties: {
    message: { type: "string" },
    assignment: assignmentSchema,
  },
};

const creatorSchema = (type: string | string[] = "object") => ({
  type,
  required: ["id", "first_name", "last_name"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
  },
});

const locationSchema = (type: string | string[] = "object") => ({
  type,
  required: ["id", "address", "place_id"],
  properties: {
    id: { type: "number" },
    address: { type: "string" },
    place_id: { type: "string" },
    lat: { type: ["string", "null"] },
    lng: { type: ["string", "null"] },
    state: { type: ["string", "null"] },
    zip: { type: ["string", "null"] },
    country: { type: ["string", "null"] },
  },
});

export const assignmentCheckInOutSchema = {
  type: "object",
  required: ["member_id"],
  properties: {
    id: { type: ["number", "null"] },
    member_id: { type: "number" },
    check_in_date: { type: ["string", "null"] },
    check_in_creator: creatorSchema(["object", "null"]),
    check_in_location: locationSchema(["object", "null"]),

    check_out_date: { type: ["string", "null"] },
    check_out_creator: creatorSchema(["object", "null"]),
    check_out_location: locationSchema(["object", "null"]),

    is_check_in_outside: { type: ["boolean", "null"] },
    is_check_out_outside: { type: ["boolean", "null"] },
    is_passed_all_jsa: { type: ["boolean", "null"] },
    member: {
      type: ["object", "null"],
      properties: {
        ...shortMemberSchema.properties,
      },
    },
  },
  additionalProperties: false,
};

export const updateAssignmentCheckInOutSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "assignment_check_in_out"],
  properties: {
    message: { type: "string" },
    assignment_check_in_out: assignmentCheckInOutSchema,
  },
};

export const assignmentCheckInOutListSchema = {
  type: "array",
  items: [assignmentCheckInOutSchema],
};

export const createAssignmentCheckInOutSchema = {
  type: "object",
  required: ["location_place_id", "member_id", "date"],
  additionalProperties: false,
  properties: {
    location_place_id: { type: "string" },
    member_id: { type: "number" },
    date: { type: "string" },
  },
};

export const assignmentPassedJsaSchema = {
  type: "object",
  additionalProperties: false,
  required: ["jsa_id", "activity_id", "created_at"],
  properties: {
    jsa_id: { type: "number" },
    activity_id: { type: "number" },
    created_at: { type: "string" },
  },
};

export const assignmentPassedJsaListSchema = {
  type: "array",
  items: [assignmentPassedJsaSchema],
};

const taskVariation1 = {
  type: "object",
  required: ["id", "index", "status", "created_at", "name", "version"],
  properties: {
    ...commonTaskProperties,
    ...formTaskProperties,
  },
  additionalProperties: false,
};

const taskVariation2 = {
  type: "object",
  required: [...taskVariation1.required, "task_id"],
  properties: {
    ...taskVariation1.properties,
    task_id: { type: ["number", "null"] },
  },
  additionalProperties: false,
};

export const assignmentPassedJsaTaskSchema = {
  type: "object",
  required: ["id", "task_id"],
  properties: {
    id: { type: "number" },
    task_id: { type: "number" },
    task: taskVariation2,
  },
};

export const assignmentJsaForJsaListSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    jsa_id: { type: ["number", "null"] },
    tasks: {
      type: "array",
      items: [taskVariation1],
    },
  },
};

export const assignmentPassedJsaForJsaListSchema = {
  type: "object",
  additionalProperties: false,
  required: [
    "name",
    "signature_url",
    "signature_date",
    "jsa_id",
    "member_first_name",
    "member_last_name",
    "jsa_old_version_id",
  ],
  properties: {
    name: { type: "string" },
    signature_url: { type: "string" },
    signature_date: { type: "string" },
    jsa_id: { type: "number" },
    member_first_name: { type: "string" },
    member_last_name: { type: "string" },
    jsa_old_version_id: { type: "number" },
    passed_tasks: {
      type: "array",
      items: [assignmentPassedJsaTaskSchema],
    },
  },
};

export const assignmentJsaListSchema = {
  type: "object",
  additionalProperties: false,
  required: [],
  properties: {
    jsa: {
      type: ["array", "null"],
      items: [assignmentJsaForJsaListSchema],
    },
    passed_jsa: {
      type: ["array", "null"],
      items: [assignmentPassedJsaForJsaListSchema],
    },
  },
};
