import React from "react";
import "./index.scss";
import classnames from "classnames";

export interface NoMatchesFoundTableProps {
  label: string;
  text?: string;
  iconClass?: string;
}

const NoMatchesFoundTable = (props: NoMatchesFoundTableProps) => {
  return (
    <div className="no-matches-table-wrapper">
      <div className="no-matches-table-header" />
      <div className="no-matches-table-content">
        <div className={classnames("no-matches-table-icon", props.iconClass)} />
        <div className="no-matches-table-label">{props.label}</div>
        {props?.text && <div className="no-matches-table-text">{props?.text}</div>}
      </div>
    </div>
  );
};

export default NoMatchesFoundTable;
