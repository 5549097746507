import React, { useCallback } from "react";
import classnames from "classnames";

import "./index.scss";

export interface InputFileProps {
  className?: string;
  onChange: (data: { base64: string; name: string }) => void;
  children?: React.ReactNode;
  accept?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  maxFileSize?: number;
  onReject: (errorMessage: string) => void;
}

const validateFile = (file: File | null, options: { maxSize?: number }): string => {
  if (file && options.maxSize && file.size > options.maxSize) {
    return `Max size of the file should be ${options.maxSize / 1000000} MB`;
  }

  return "";
};

export default function InputFile(props: InputFileProps) {
  const { className, onChange, children, accept, inputRef, maxFileSize, onReject } = props;

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const rawFile = files && files[0];
      const errorMessage = validateFile(rawFile, { maxSize: maxFileSize });
      if (errorMessage) {
        onReject(errorMessage);
        return;
      }
      const reader = new FileReader();
      const fileName = rawFile ? rawFile.name : undefined;

      if (fileName && rawFile) {
        reader.onload = (e) => {
          const file = e.target ? e.target.result : null;

          if (file) {
            onChange({
              base64: String(file),
              name: fileName,
            });
          }
        };

        reader.readAsDataURL(rawFile);
      }

      e.target.value = "";
    },
    [onChange, onReject, maxFileSize],
  );

  return (
    <label className={classnames("file-input-label", className)}>
      {children}
      <input ref={inputRef} type="file" className="hidden" accept={accept} onChange={handleFileChange} />
    </label>
  );
}
