import React, { FC } from "react";

import "./index.scss";

export interface ContainerHeaderProps {
  title: string;
  subtitle?: string;
}

const ContainerHeader: FC<ContainerHeaderProps> = (props) => {
  const { title, children, subtitle } = props;

  return (
    <div className="container-header">
      <div className="container-header-title">
        <div>{title}</div>
        {subtitle && <div className="container-header-subtitle">{subtitle}</div>}
      </div>
      <div className="container-header-content">{children}</div>
    </div>
  );
};

export default ContainerHeader;
