import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "@shared/utils";
import {
  jsaForListSchema,
  baseFilterSchema,
  getPaginatedResponseSchema,
  getFilesResponseSchema,
  createFileResponseSchema,
  createJsaValidatorRequest,
  updateJsaValidatorRequest,
  getJsaItemResponse,
  updateJsaItemResponse,
  baseUpdateStatusRequestSchema,
  createFileRequestSchema,
  deleteFileResponseSchema,
  importJsaRequestSchema,
  importJsaResponseSchema,
} from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.JSA> = {
  [API_ENUM.GET_JSA]: {
    request: {
      name: `${API.JSA.GET_JSA}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: baseFilterSchema,
    },
    response: {
      name: `${API.JSA.GET_JSA}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(jsaForListSchema),
    },
  },
  [API_ENUM.CREATE_JSA]: {
    request: {
      name: `${API.JSA.CREATE_JSA}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createJsaValidatorRequest,
    },
    response: {
      name: `${API.JSA.CREATE_JSA}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: updateJsaItemResponse,
    },
  },
  [API_ENUM.UPDATE_JSA]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.JSA.UPDATE_JSA("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: updateJsaValidatorRequest,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.JSA.UPDATE_JSA("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: updateJsaItemResponse,
    },
  },
  [API_ENUM.GET_JSA_ITEM]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.JSA.GET_JSA_ITEM("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.JSA.GET_JSA_ITEM("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getJsaItemResponse,
    },
  },
  [API_ENUM.UPDATE_JSA_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.JSA.UPDATE_JSA_STATUS("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: baseUpdateStatusRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.JSA.UPDATE_JSA_STATUS("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: updateJsaItemResponse,
    },
  },
  [API_ENUM.GET_JSA_FILES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.JSA.GET_JSA_FILES("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.JSA.GET_JSA_FILES("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getFilesResponseSchema,
    },
  },
  [API_ENUM.ADD_JSA_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.JSA.ADD_JSA_FILE("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createFileRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.JSA.ADD_JSA_FILE("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createFileResponseSchema,
    },
  },
  [API_ENUM.DELETE_JSA_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.JSA.DELETE_JSA_FILE("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.JSA.DELETE_JSA_FILE("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: deleteFileResponseSchema,
    },
  },
  [API_ENUM.IMPORT_JSA]: {
    request: {
      name: `${API.JSA.IMPORT_JSA}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: importJsaRequestSchema,
    },
    response: {
      name: `${API.JSA.IMPORT_JSA}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: importJsaResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
