import React, { useMemo, useRef, useEffect, useCallback } from "react";
import { User } from "@shared/models";
import { getFullUserName } from "@shared/utils";
import { useOutsideClick } from "@shared/hooks";
import { Icon } from "@shared/components";
import { useContactUsModal } from "@containers/ContactUs/hooks";

import "./index.scss";

export interface ProfileProps {
  user: User;
  companyCode: string | null;
  onLogOut: () => void;
  onOutsideClick: () => void;
}

const Profile: React.FC<ProfileProps> = (props) => {
  const { user, companyCode, onLogOut, onOutsideClick } = props;
  const wrapperRef = useRef(null);

  const { isOutside } = useOutsideClick(wrapperRef);

  useEffect(() => {
    isOutside && onOutsideClick();
  }, [isOutside, onOutsideClick]);

  const userName = useMemo(() => {
    return getFullUserName(user);
  }, [user]);

  const userRoleName = useMemo(() => {
    const roleAppLevel = user.user_roles.find((ur) => !ur.company);
    const roleCompanyLevel = companyCode ? user.user_roles.find((ur) => ur.company?.code === companyCode) : null;

    return roleCompanyLevel ? roleCompanyLevel.role.name : roleAppLevel ? roleAppLevel.role.name : "";
  }, [user, companyCode]);

  const { showContactUsModal } = useContactUsModal(companyCode);

  const handleContactUsClick = useCallback(() => {
    onOutsideClick();
    showContactUsModal();
  }, [showContactUsModal, onOutsideClick]);

  return (
    <div className="profile-wrapper" ref={wrapperRef}>
      <div className="profile-header-wrapper">
        <div className="profile-header">
          <div className="user-name">{userName}</div>
          <div className="user-role">{userRoleName}</div>
        </div>
      </div>
      <div className="profile-content-wrapper">
        <div className="profile-content">
          <div className="user-email">
            <Icon type="letter" />
            <div className="text">{user.email}</div>
          </div>
          <div className="general-links">
            <a
              target="_blank"
              rel="noreferrer"
              className="general-link"
              href="https://www.iubenda.com/privacy-policy/81854027">
              Privacy Policy
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              className="general-link"
              href="https://www.iubenda.com/terms-and-conditions/81854027">
              Terms & Conditions
            </a>
            {companyCode && (
              <div className="general-link" onClick={handleContactUsClick}>
                Contact Us
              </div>
            )}
          </div>
          <div className="user-logout" onClick={onLogOut}>
            <Icon type="logout" />
            <div className="text">Log Out</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
