import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    "0": "#ff792b",
    "1.0": "#ff792b",
  },
  shadowBlur: 5,
});

const LoadingIndicator = () => {
  return <TopBarProgress />;
};

export default LoadingIndicator;
