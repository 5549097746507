import React from "react";
import classnames from "classnames";
import {
  UserStatus,
  CompanyStatus,
  MemberStatus,
  ActivityStatus,
  TaskStatus,
  JsaStatus,
  AssignmentStatus,
  TrainingStatus,
  MemberTrainingStatus,
  MemberCertificateStatus,
  AlertStatus,
  ProjectStatus,
  TeamStatus,
} from "@shared/models";

import "./index.scss";

export interface StatusLabelProps {
  className?: string;
  status?:
    | UserStatus
    | CompanyStatus
    | MemberStatus
    | ActivityStatus
    | TaskStatus
    | JsaStatus
    | AssignmentStatus
    | TrainingStatus
    | MemberTrainingStatus
    | MemberCertificateStatus
    | AlertStatus
    | ProjectStatus
    | TeamStatus
    | null;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status, className }) => (
  <div className={classnames("status-label", className, status?.replace(" ", "-"))}>{status}</div>
);

export default StatusLabel;
