import React, { useCallback, useEffect, useRef, useState } from "react";
import { File as FileModel } from "@shared/models";
import { validateFile } from "@shared/utils";
import classnames from "classnames";
import { CreateFileShape } from "@shared/interfaces";
import { ACCEPT_FILE_TYPES } from "@shared/constants";

import { Button } from "..";
import { FileItem } from "./components";

import "./index.scss";

export interface FileButtonProps {
  file?: FileModel | null;
  className?: string;
  disabled?: boolean;
  onCreateFile: (file: FileModel, baseFile: CreateFileShape) => void;
  onDeleteFile: (file: FileModel) => void;
  onReject?: (errorMessage: string) => void;
  maxFileSize?: number;
  deprecatedClick?: boolean;
  deprecatedText?: string;
  acceptFileTypes?: string;
}

const FilesBlock = (props: FileButtonProps) => {
  const {
    file,
    onDeleteFile,
    onCreateFile,
    onReject,
    maxFileSize,
    deprecatedClick,
    disabled = false,
    acceptFileTypes,
    className,
    deprecatedText,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [internalFile, setInternalFile] = useState<FileModel | null | undefined>();

  useEffect(() => {
    setInternalFile(file);
  }, [file]);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const rawFile = files && files[0];
      const errorMessage = validateFile(rawFile, { maxSize: maxFileSize });
      if (errorMessage) {
        onReject?.(errorMessage);
        return;
      }

      const reader = new FileReader();
      const fileName = rawFile ? rawFile.name : undefined;

      if (fileName && rawFile) {
        reader.onload = (e) => {
          const file = e.target ? e.target.result : null;

          if (file) {
            onCreateFile(
              {
                name: fileName,
                url: "",
                creator: {
                  id: NaN,
                  first_name: "",
                  last_name: "",
                },
                created_at: new Date(),
                id: NaN,
              },
              {
                name: fileName,
                base64: String(file),
              },
            );
          }
        };

        reader.readAsDataURL(rawFile);
      }

      e.target.value = "";
    },
    [onCreateFile, onReject, maxFileSize],
  );

  const handleAddFileClick = useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault();

      if (!deprecatedClick && inputRef && inputRef.current) {
        inputRef.current.click();
      } else if (deprecatedText) {
        onReject?.(deprecatedText);
        return;
      }
    },
    [deprecatedClick, deprecatedText, onReject],
  );

  return (
    <div className={classnames("file-button-wrapper", className)}>
      {!internalFile ? (
        <Button className="file-button__add" block disabled={disabled} buttonStyle="draft" onClick={handleAddFileClick}>
          + Add File
        </Button>
      ) : (
        <FileItem isDisabled={disabled} file={internalFile} onDelete={onDeleteFile} />
      )}
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept={acceptFileTypes ? acceptFileTypes : ACCEPT_FILE_TYPES}
      />
    </div>
  );
};

export default FilesBlock;
