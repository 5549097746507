import { commonTaskProperties, formTaskProperties } from "./task.schema";

const commonJsaProperties = {
  id: { type: "number" },
  name: { type: "string" },
  status: { type: ["string", "null"] },
  created_at: { type: ["string", "null"] },
  created_by: { type: ["string", "null"] },
  parent_jsa_id: { type: ["number", "null"] },
  company_id: { type: ["number", "null"] },
};

export const jsaForListSchema = {
  type: "object",
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    status: { type: ["string", "null"] },
    created_at: { type: ["string", "null"] },
    created_by: { type: ["string", "null"] },
    parent_jsa_id: { type: ["number", "null"] },
    company_id: { type: ["number", "null"] },
    activities: {
      type: "array",
      items: {
        type: "object",
        additionalProperties: false,
        properties: {
          id: { type: "number" },
          name: { type: "string" },
        },
      },
    },
    creator: {
      type: ["object", "null"],
      additionalProperties: false,
      required: ["id", "first_name", "last_name"],
      properties: {
        id: { type: "number" },
        first_name: { type: "string" },
        last_name: { type: "string" },
      },
    },
    passed_jsa: {
      type: ["object", "null"],
      additionalProperties: false,
      required: ["created_at"],
      properties: {
        created_at: { type: "string" },
      },
    },
  },
  additionalProperties: false,
};

const getJsaProperties = {
  ...commonJsaProperties,
  version: { type: "number" },
  tasks: {
    type: "array",
    items: {
      type: "object",
      properties: {
        ...commonTaskProperties,
        ...formTaskProperties,
      },
    },
  },
};

export const getJsaItemResponse = {
  type: "object",
  additionalProperties: false,
  properties: getJsaProperties,
};

export const updateJsaItemResponse = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    jsa: getJsaProperties,
  },
};

const baseUpdateJsaProperties = {
  name: { type: "string" },
  tasks: { type: "array" },
  status: { type: "string" },
  company_code: { type: ["string", "null"] },
};

export const createJsaValidatorRequest = {
  type: "object",
  additionalProperties: false,
  required: ["name", "tasks", "status"],
  properties: {
    ...baseUpdateJsaProperties,
    parent_jsa_id: { type: ["number", "null"] },
  },
};

export const updateJsaValidatorRequest = {
  type: "object",
  additionalProperties: false,
  required: ["name", "tasks", "status"],
  properties: {
    ...baseUpdateJsaProperties,
  },
};

export const importJsaRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["company_code", "jsa"],
  properties: {
    company_code: { type: "string" },
    jsa: { type: "array" },
  },
};

export const importJsaResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message"],
  properties: {
    message: { type: "string" },
  },
};
