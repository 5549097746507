import React, { useState, FC } from "react";
import { Formik } from "formik";
import { ChangePasswordShape } from "@containers/Auth/interface/AuthForm.interface";
import { FormProps } from "@shared/interfaces";
import { FormGenerator, Button } from "@shared/components";

import { validationSchema, fields, initValues } from "./formHelpers";

const ChangePasswordForm: FC<FormProps<ChangePasswordShape>> = (props) => {
  const [formValues] = useState(initValues);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.submitHandler && props.submitHandler(values);
        setSubmitting(false);
      }}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={formValues}>
      {(formikProps) => (
        <>
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Button type="submit" disabled={props.loading || !validationSchema.isValidSync(formikProps.values)}>
              Set New Password
            </Button>
          </FormGenerator>
        </>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
