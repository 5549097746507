import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { DocumentFilter, DocumentStateType } from "../interface";
import * as actions from "./actions";

const DEFAULT_LIMIT = 50;

export const defaultFilter: DocumentFilter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  company_code: null,
};

type Action = ActionType<typeof actions>;

export const initialState: DocumentStateType = {
  filter: defaultFilter,
  documents: [],
  documentsTotal: 0,
  currentDocument: null,
};

const reducer = createReducer<DocumentStateType, Action>(initialState)
  .handleAction(actions.getDocuments.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.documents = clear ? rows : [...nextState.documents, ...rows];
      nextState.documentsTotal = count;
    }),
  )
  .handleAction(actions.getDocument.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentDocument = payload;
    }),
  )
  .handleAction(actions.createDocument.success, (state, action) =>
    produce(state, (nextState) => {
      const { document } = action.payload;
      nextState.documents = [document, ...nextState.documents];
      nextState.documentsTotal = nextState.documentsTotal + 1;
    }),
  )
  .handleAction(actions.updateDocument.success, (state, action) =>
    produce(state, (nextState) => {
      const { document } = action.payload;
      const index = nextState.documents.findIndex((d) => d.id === document.id);
      if (index >= 0) {
        nextState.documents[index] = document;
      }
      nextState.currentDocument = document;
    }),
  )
  .handleAction(actions.deleteDocument.success, (state, action) =>
    produce(state, (nextState) => {
      const { document_id } = action.payload;
      nextState.documents = state.documents.filter((d) => {
        return d.id !== document_id;
      });
      if (state.currentDocument && state.currentDocument.id === document_id) {
        nextState.currentDocument = null;
      }
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.setPartialFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = {
        ...state.filter,
        ...action.payload,
      };
    }),
  );

export { reducer as DocumentReducer };
