import { AnyAction, Dispatch } from "redux";
import * as SocketIOClient from "socket.io-client";
import { tokenHandler } from "@shared/utils";
import { UserNotification } from "@shared/models";

import { addUserNotification } from "../store/actions";
import config from "../../../config";

let socket: SocketIOClient.Socket | null = null;

export const initUserNotificationWSConnection = (companyCode: string, dispatch: Dispatch<AnyAction>) => {
  const token = tokenHandler.get();
  const bearerToken = token ? `Bearer ${token}` : "";

  socket = SocketIOClient.connect(config.baseWSUrl, {
    secure: true,
    transports: ["websocket", "polling"],
  });

  socket.on("connect", () => {
    // eslint-disable-next-line
    console.log("[WS] connect");

    socket?.emit("join", {
      authorizationToken: bearerToken,
      company_code: companyCode,
    });
  });

  socket.on("disconnect", () => {
    // eslint-disable-next-line
    console.log("[WS] disconnect");
  });

  socket.on("new-notification", (payload: UserNotification) => {
    dispatch(addUserNotification(payload));
  });
};

export const closeUserNotificationWSConnection = () => {
  socket?.close();
  socket = null;
};
