import React, { useState, FC } from "react";
import classnames from "classnames";
import { Field } from "formik";
import { InputProps } from "@shared/interfaces";

import "./index.scss";

const Input: FC<InputProps> = (props: InputProps) => {
  const { className, isShowPasswordIcon, isRequiredField, helpText, ...inputProps } = props;
  const [isShowPassword, setShowPassword] = useState(false);
  return (
    <div className={classnames("input-wrapper", className)}>
      <label className={classnames("label", { disabled: props.disabled })}>
        {props.label} {isRequiredField && <span className="required-field">*</span>}
      </label>
      <Field {...{ ...inputProps, type: isShowPassword ? "text" : props.type }} />
      {helpText && <div className="help-text">{helpText}</div>}
      {isShowPasswordIcon && (
        <div
          className={`password-icon-block ${isShowPassword ? "password-hide" : "password-show"}`}
          onClick={() => setShowPassword(!isShowPassword)}>
          {isShowPassword ? "Hide" : "Show"}
        </div>
      )}
    </div>
  );
};

export default Input;
