import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CodeWithId, Filter, PayloadWithCode } from "@shared/interfaces";

export default {
  getUserNotifications: (payload: Filter) => {
    const { company_code, ...params } = payload;
    return request(METHODS.GET, API.USER_NOTIFICATION.GET_USER_NOTIFICATIONS(company_code ?? ""))({ params });
  },
  getUserNotificationsUnreadCount: ({ code }: PayloadWithCode) =>
    request(METHODS.GET, API.USER_NOTIFICATION.GET_USER_NOTIFICATIONS_UNREAD_COUNT(code))(),
  readUserNotifications: ({ code }: PayloadWithCode) =>
    request(METHODS.PUT, API.USER_NOTIFICATION.READ_USER_NOTIFICATIONS(code))(),
  readUserNotification: ({ code, id }: Required<CodeWithId>) =>
    request(METHODS.PUT, API.USER_NOTIFICATION.READ_USER_NOTIFICATION(code, id))(),
};
