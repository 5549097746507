import { BaseEntity } from "@shared/interfaces";

import { MemberListItem, Creator } from ".";

export enum TeamStatus {
  Active = "Active",
  Archived = "Archived",
}

export interface Team extends BaseEntity {
  members_count?: number;
  members?: MemberListItem[];
  created_by?: string;
  created_at?: string;
  status?: TeamStatus;
  creator?: Creator;
}
