import { User, Role, Location, Team } from "@shared/models";
import { actions } from "@shared/store";
import {
  ActionWithPayload,
  GetRolesFilter,
  PaginatedResponse,
  BaseEntity,
  Filter,
  Address,
  CodeWithId,
  AppState,
} from "@shared/interfaces";
import { CreateUpdateTeamSuccess, TeamFormShapeToRequest } from "@containers/Team/interface";
import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { tokenHandler } from "@shared/utils";
import { AnyAction } from "redux";
import teamApi from "@containers/Team/api";

import api from "../api";

function* getUserDetailsSaga() {
  try {
    yield put(actions.startLoading());
    const response: User = yield call(api.getUserDetails);
    yield tokenHandler.setUser(response);
    yield put(actions.getUserDetails.success(response));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.getUserDetails.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* getRolesSaga({ payload }: ActionWithPayload<GetRolesFilter>) {
  try {
    const response: Role[] = yield call(api.getRoles, payload);
    yield put(actions.getRoles.success(response));
  } catch (error) {
    yield put(actions.getRoles.failure(error as Error));
  }
}

function* getJobsSaga({ payload }: ActionWithPayload<Partial<Filter>>) {
  try {
    const response: PaginatedResponse<BaseEntity> = yield call(api.getJobs, payload);
    yield put(actions.getJobs.success(response));
  } catch (error) {
    yield put(actions.getJobs.failure(error as Error));
  }
}

function* getIndustriesSaga({ payload }: ActionWithPayload<Partial<Filter>>) {
  try {
    const response: PaginatedResponse<BaseEntity> = yield call(api.getIndustries, payload);
    yield put(actions.getIndustries.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(actions.getIndustries.failure(error as Error));
  }
}

function* getAddressSaga({ payload }: ActionWithPayload<Partial<Filter>>) {
  try {
    yield put(actions.startLoading());
    const response: Address[] = yield call(api.getAddress, payload);
    yield put(actions.stopLoading());
    yield put(actions.getAddress.success(response));
  } catch (error) {
    yield put(actions.stopLoading());
  }
}

function* getLocationSaga({ payload }: ActionWithPayload<string>) {
  try {
    yield put(actions.startLoading());
    const response: { location: Location } = yield call(api.getLocation, payload);
    yield put(actions.stopLoading());
    yield put(actions.getLocation.success(response));
  } catch (error) {
    yield put(actions.stopLoading());
  }
}

function* getTeamsSaga({ payload }: ActionWithPayload<Partial<Filter>>) {
  try {
    const response: PaginatedResponse<Team> = yield call(teamApi.getTeams, payload);
    yield put(actions.getTeams.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(actions.getTeams.failure(error as Error));
  }
}

function* createTeamSaga({ payload }: ActionWithPayload<CodeWithId<TeamFormShapeToRequest>>) {
  try {
    yield put(actions.startLoading());
    const response: CreateUpdateTeamSuccess = yield call(teamApi.createTeam, payload);
    yield put(actions.stopLoading());
    yield put(actions.createTeam.success(response));
    yield put(actions.setLastCreatedTeam(response.team));
    yield put(actions.hideModal());
  } catch (error) {
    yield put(actions.createTeam.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* getClientsSaga({ payload }: ActionWithPayload<Partial<Filter>>) {
  try {
    const response: PaginatedResponse<BaseEntity> = yield call(api.getClients, payload);
    yield put(actions.getClients.success(response));
  } catch (error) {
    yield put(actions.getClients.failure(error as Error));
  }
}

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) => startActions.includes(payload.type));
  const stoppedSection = loadingTypes.find(({ stopActions }) => stopActions[payload.type]);

  if (startedSection) {
    yield put(actions.addLoadingSection({ loadingSection: startedSection.name, requestName: payload.type }));
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      }),
    );
  }
}

function* sharedSagas() {
  yield takeLatest(actions.getUserDetails.request, getUserDetailsSaga);
  yield takeLatest(actions.getRoles.request, getRolesSaga);
  yield takeLatest(actions.getJobs.request, getJobsSaga);
  yield takeLatest(actions.getIndustries.request, getIndustriesSaga);
  yield takeLatest(actions.getAddress.request, getAddressSaga);
  yield takeLatest(actions.getLocation.request, getLocationSaga);
  yield takeLatest(actions.getTeams.request, getTeamsSaga);
  yield takeLatest(actions.createTeam.request, createTeamSaga);
  yield takeLatest(actions.getClients.request, getClientsSaga);

  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
