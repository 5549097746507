import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { SharedStateType, Filter, LoadingSections } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const DEFAULT_LIMIT = 30;

export const defaultFilter: Filter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  company_code: null,
  role_id: null,
  team_id: null,
  industry_id: null,
  search: null,
  status: null,
};

export const initialState: SharedStateType = {
  loading: false,
  notification: null,
  user: null,
  roles: [],
  modal: {},
  overlay: {},
  navigateURL: "",
  jobs: [],
  industries: [],
  industriesTotal: 0,
  addresses: [],
  jsa: [],
  location: null,
  teams: [],
  teamsTotal: 0,
  clients: [],
  loadingSections: {},
  loadingTypes: [],
  lastCreatedTeam: null,
};

const reducer = createReducer<SharedStateType, Action>(initialState)
  .handleAction(actions.startLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loading = true;
    }),
  )
  .handleAction(actions.stopLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loading = false;
    }),
  )
  .handleAction(actions.getUserDetails.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.user = action.payload;
    }),
  )
  .handleAction(actions.getRoles.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.roles = action.payload;
    }),
  )
  .handleAction(actions.showOverlay, (state, action) =>
    produce(state, (nextState) => {
      nextState.overlay = { show: true, ...action.payload };
    }),
  )
  .handleAction(actions.showModal, (state, action) =>
    produce(state, (nextState) => {
      nextState.modal = { show: true, ...action.payload };
    }),
  )
  .handleAction(actions.hideOverlay, (state) =>
    produce(state, (nextState) => {
      nextState.overlay = {};
    }),
  )
  .handleAction(actions.hideModal, (state) =>
    produce(state, (nextState) => {
      nextState.modal = {};
    }),
  )
  .handleAction(actions.showNotification, (state, action) =>
    produce(state, (nexState) => {
      nexState.notification = action.payload;
    }),
  )
  .handleAction(actions.getJobs.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.jobs = action.payload.rows;
    }),
  )
  .handleAction(actions.getIndustries.success, (state, action) =>
    produce(state, (nextState) => {
      const { count, rows, clear } = action.payload;
      nextState.industries = clear ? rows : [...nextState.industries, ...rows];
      nextState.industriesTotal = count;
    }),
  )
  .handleAction(actions.getAddress.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.addresses = action.payload;
    }),
  )
  .handleAction(actions.getLocation.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.location = action.payload ? action.payload.location : null;
    }),
  )
  .handleAction(actions.navigate, (state, action) =>
    produce(state, (nexState) => {
      nexState.navigateURL = action.payload;
    }),
  )
  .handleAction(actions.createTeam.success, (state, action) =>
    produce(state, (nextState) => {
      const { team } = action.payload;
      nextState.teams = [team, ...nextState.teams];
    }),
  )
  .handleAction(actions.getTeams.success, (state, action) =>
    produce(state, (nextState) => {
      const { count, rows, clear } = action.payload;
      nextState.teams = clear ? rows : [...nextState.teams, ...rows];
      nextState.teamsTotal = count;
    }),
  )
  .handleAction(actions.setLastCreatedTeam, (state, action) =>
    produce(state, (nexState) => {
      nexState.lastCreatedTeam = action.payload;
    }),
  )
  .handleAction(actions.addLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = [...state.loadingTypes, action.payload];
    }),
  )
  .handleAction(actions.removeLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = state.loadingTypes.filter(({ name }) => name !== action.payload);
      nextState.loadingSections = {};
    }),
  )
  .handleAction(actions.addLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections: LoadingSections = { ...state.loadingSections };

      if (!loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = [];
      }

      loadingSections[action.payload.loadingSection] = [
        ...Array.from(new Set([...loadingSections[action.payload.loadingSection], action.payload.requestName])),
      ];

      nextState.loadingSections = loadingSections;
    }),
  )
  .handleAction(actions.removeLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections = { ...state.loadingSections };

      if (loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = loadingSections[action.payload.loadingSection].filter(
          (loadingRequest) => loadingRequest !== action.payload.requestName,
        );

        if (!loadingSections[action.payload.loadingSection].length) {
          delete loadingSections[action.payload.loadingSection];
        }

        nextState.loadingSections = loadingSections;
      }
    }),
  )
  .handleAction(actions.getJsa.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.jsa = action.payload.rows;
    }),
  )
  .handleAction(actions.getClients.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.clients = action.payload.rows;
    }),
  );

export { reducer as SharedReducer };
