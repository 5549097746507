import { API, METHODS, SCHEMAS } from "@shared/constants";
import { API_ENUM } from "@shared/constants/api";
import { SchemasMap } from "@shared/interfaces";
import { generateDynamicSchemaKeyString, mapToSchema } from "@shared/utils";
import {
  createDocumentRequestSchema,
  documentDeleteResponseSchema,
  documentFilterSchema,
  documentResponseSchema,
  documentSchema,
  getDocumentListResponseSchema,
  updateDocumentRequestSchema,
} from "@shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.DOCUMENT> = {
  [API_ENUM.GET_DOCUMENTS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.GET_DOCUMENTS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: documentFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.GET_DOCUMENTS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getDocumentListResponseSchema,
    },
  },
  [API_ENUM.GET_DOCUMENT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.GET_DOCUMENT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.GET_DOCUMENT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: documentSchema,
    },
  },
  [API_ENUM.CREATE_DOCUMENT]: {
    request: {
      name: `${API.DOCUMENT.CREATE_DOCUMENT}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createDocumentRequestSchema,
    },
    response: {
      name: `${API.DOCUMENT.CREATE_DOCUMENT}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: documentResponseSchema,
    },
  },
  [API_ENUM.UPDATE_DOCUMENT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.UPDATE_DOCUMENT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: updateDocumentRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.UPDATE_DOCUMENT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: documentResponseSchema,
    },
  },
  [API_ENUM.DELETE_DOCUMENT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.DELETE_DOCUMENT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.DOCUMENT.DELETE_DOCUMENT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: documentDeleteResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
