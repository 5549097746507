import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CodeWithId } from "@shared/interfaces";

import { ProjectFilter, ProjectFormShapeToRequest } from "../interface";

export default {
  getProjects: (payload: Partial<ProjectFilter>) => {
    const { company_code, ...projectFilter } = payload;
    return request(METHODS.GET, API.PROJECT.GET_PROJECTS(company_code ?? ""))({ params: projectFilter });
  },
  getProject: (payload: Required<CodeWithId>) => {
    const { code, id } = payload;
    return request(METHODS.GET, API.PROJECT.GET_PROJECT(code ?? "", id))();
  },
  createProject: (payload: CodeWithId<ProjectFormShapeToRequest>) => {
    return request(METHODS.POST, API.PROJECT.CREATE_PROJECT(payload.code))(payload.payload);
  },
  updateProject: (payload: Required<CodeWithId<ProjectFormShapeToRequest>>) => {
    const { code, id, payload: rquestPayload } = payload;
    return request(METHODS.PUT, API.PROJECT.UPDATE_PROJECT(code ?? "", id))(rquestPayload);
  },
  deleteProject: (payload: Required<CodeWithId>) => {
    const { code, id } = payload;
    return request(METHODS.DELETE, API.PROJECT.DELETE_PROJECT(code ?? "", id))();
  },
};
