import React, { FC } from "react";
import { Button, FormHeader } from "@shared/components";
import { UserNotification } from "@shared/models";

import { UserNotificationList } from "..";

import "./index.scss";

export interface UserNotificationContentProps {
  onCancel: () => void;
  unreadNotificationsCount: number;
  notifications: UserNotification[];
  onClickReadAllBtn: () => void;
  onLoadNextPage?: () => void;
  onNotificationClick?: (notification: UserNotification) => void;
}

export const UserNotificationContent: FC<UserNotificationContentProps> = (props) => {
  const { onLoadNextPage, onCancel, onClickReadAllBtn, unreadNotificationsCount, notifications, onNotificationClick } =
    props;

  return (
    <div className="user-notifications">
      <FormHeader title="Notifications" onCancel={onCancel} />
      <div className="user-notifications-header">
        {unreadNotificationsCount > 0 && (
          <span className="user-notifications-info">
            <b>{unreadNotificationsCount}</b> Unread
          </span>
        )}
        <Button
          className="user-notifications-clear-btn"
          buttonStyle="clear"
          disabled={!unreadNotificationsCount}
          onClick={onClickReadAllBtn}>
          Mark All As Read
        </Button>
      </div>
      <div className="user-notifications__list-container">
        <UserNotificationList
          onNotificationClick={onNotificationClick}
          onFinishScroll={onLoadNextPage}
          className="user-notifications__list"
          notifications={notifications}
        />
      </div>
    </div>
  );
};

export default UserNotificationContent;
